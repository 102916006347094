import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as ProjectAction from '../../../reducers/form/action';
import { auth } from '../../../helpers';
import { localization } from '../../../localization';
import { useUrlSearchParams } from 'use-url-search-params';
import shouldSkipStep from '../../../helpers/skipStep';

const FormSidebar = () => {
  const dispatch = useDispatch();
  const step = useSelector((state) => state.project.step);
  const { errorForms } = useSelector((state) => state.moderationStatus);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language];
  const [params] = useUrlSearchParams();
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  const stepsToSkip = [5, 6, 9, 10];
  const projectStatusToSkip = projectInfo?.project_status !== 'new';
  const projectStatusToSkipForNew = projectInfo?.project_status === 'new';
  const projectStatusToSkipForStudy = projectInfo.project_status === 'study';

  // Find the next valid step (skip unwanted steps)
  useEffect(() => {
    let adjustedStep = step;
    // for ongoing
    while (shouldSkipStep(adjustedStep, stepsToSkip, projectStatusToSkip)) {
      adjustedStep++; // Move to the next step if it's supposed to be skipped
    }
    // for new
    while (shouldSkipStep(adjustedStep, [11], projectStatusToSkipForNew)) {
      adjustedStep++; // Move to the next step if it's supposed to be skipped
    }
    // for study
    while (shouldSkipStep(adjustedStep, [2,3,4,5,6,7,8,9,10,11,12,13], projectStatusToSkipForStudy)) {
      adjustedStep++; // Move to the next step if it's supposed to be skipped
    }
    dispatch(ProjectAction.projectStep({ step: adjustedStep }));
  }, [projectInfo]);

  const handleStepChange = (step) => {
    dispatch(ProjectAction.projectStep({ step }));
  };

  return (
    <div className="project-detail-dashboard print-hidden">
      <ul>
        <li>
          <p
            className={`${
              errorForms?.indexOf('general_information') > -1
                ? 'validation-error form-section-link'
                : 'form-section-link'
            } ${step === 1 ? 'active' : ''}`}
            onClick={() => handleStepChange(1)}
          >
            {lang.general_information_view.section_name}
          </p>
        </li>
        {
          projectInfo?.project_status !== 'study' &&
          <>
       
        <li>
          <p
            className={`${
              errorForms?.indexOf('project_specific') > -1
                ? 'validation-error form-section-link'
                : 'form-section-link'
            } ${step === 2 ? 'active' : ''}`}
            onClick={() => handleStepChange(2)}
          >
            {lang.project_specific_view.section_name}
          </p>
        </li>
        <li>
          <p
            className={`${
              errorForms?.indexOf('progress') > -1
                ? 'validation-error form-section-link'
                : 'form-section-link'
            } ${step === 3 ? 'active' : ''}`}
            onClick={() => handleStepChange(3)}
          >
            {params.stage && params.stage === 'identification'
              ? lang.progress.form_name_alternate
              : projectInfo?.project_status === 'new' ? lang?.progress?.projected_budget_requirement_2FY : lang.progress.form_name}
          </p>
        </li>
        {/* <li>
          <p
            className={`${
              errorForms?.indexOf('project_readiness') > -1
                ? 'validation-error form-section-link'
                : 'form-section-link'
            } ${step === 4 ? 'active' : ''}`}
            onClick={() => handleStepChange(4)}
          >
            {lang.project_readiness_view.section_name}
          </p>
        </li> */}
        {projectInfo?.project_status === 'new' && (
          <>
            <li>
              <p
                className={`${
                  errorForms?.indexOf('basis_of_project_selection') > -1
                    ? 'validation-error form-section-link'
                    : 'form-section-link'
                } ${step === 5 ? 'active' : ''}`}
                onClick={() => handleStepChange(5)}
              >
                {lang.selection_view.section_name}
              </p>
            </li>
            <li>
              <p
                className={`${
                  errorForms?.indexOf('project_prioritization_score') > -1
                    ? 'validation-error form-section-link'
                    : 'form-section-link'
                } ${step === 6 ? 'active' : ''}`}
                onClick={() => handleStepChange(6)}
              >
                {lang.prioritization_score_view.section_name}
              </p>
            </li>
          </>
        )}
        <li>
          <p
            className={`${
              errorForms?.indexOf('project_implementation_modality') > -1
                ? 'validation-error form-section-link'
                : 'form-section-link'
            } ${step === 7 ? 'active' : ''}`}
            onClick={() => handleStepChange(7)}
          >
            {params.stage && params.stage === 'identification'
              ? lang.modality_view.form_name_alternate
              : lang.modality_view.section_name}
          </p>
        </li>
        <li>
          <p
            className={`${
              errorForms?.indexOf('project_financing_arrangement') > -1
                ? 'validation-error form-section-link'
                : 'form-section-link'
            } ${step === 8 ? 'active' : ''}`}
            onClick={() => handleStepChange(8)}
          >
            {params.stage && params.stage === 'identification'
              ? lang.financing_view.form_name_alternate
              : lang.financing_view.section_name}
          </p>
        </li>
        {projectInfo?.project_status === 'new' && (
          <>
            <li>
              <p
                className={`${
                  errorForms?.indexOf('project_viability') > -1
                    ? 'validation-error form-section-link'
                    : 'form-section-link'
                } ${step === 9 ? 'active' : ''}`}
                onClick={() => handleStepChange(9)}
              >
                {params.stage && params.stage === 'identification'
                  ? lang.viability_view.form_name_alternate
                  : lang.viability_view.section_name}
              </p>
            </li>
            <li>
              <p
                className={`${
                  errorForms?.indexOf('land_acquisition_status') > -1
                    ? 'validation-error form-section-link'
                    : 'form-section-link'
                } ${step === 10 ? 'active' : ''}`}
                onClick={() => handleStepChange(10)}
              >
                {lang.land_acquisition_view.section_name}
              </p>
            </li>
          </>
        )}
        {params.stage === 'appraisal' && projectInfo?.project_status === 'ongoing' && (
          <li>
            <p
              className={`${
                errorForms?.indexOf('justification') > -1
                  ? 'validation-error form-section-link'
                  : 'form-section-link'
              } ${step === 11 ? 'active' : ''}`}
              onClick={() => handleStepChange(11)}
            >
              {lang.justification.form_name}
            </p>
          </li>
        )}
        <li>
          <p
            className={`${
              errorForms?.indexOf('contact') > -1
                ? 'validation-error form-section-link'
                : 'form-section-link'
            } ${step === 12 ? 'active' : ''}`}
            onClick={() => handleStepChange(12)}
          >
            {lang.contact.form_name}
          </p>
        </li>
        <li>
          <p
            className={`${
              errorForms?.indexOf('remarks') > -1
                ? 'validation-error form-section-link'
                : 'form-section-link'
            } ${step === 13 ? 'active' : ''}`}
            onClick={() => handleStepChange(13)}
          >
            {lang.remarks.form_name}
          </p>
        </li>
        </>
        }
        <li className=" ">
          <a onClick={() => handleStepChange(14)} className={step === 14 ? 'active' : ''}>
            {lang.project_status.form_name}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FormSidebar;
