import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

// import FilterMultiSection from './FilterMultiSection';
import FilterSection from './FilterSection';

const Filter = (props) => {
  const dispatch = useDispatch();
  let wrapperRef = useRef();
  const { user, language, filterValues } = props;
  //   const [data, setData] = useState({
  //     provinces: [],
  //   });

  const [provinces, setProvinces] = useState([
    { label: 'All', value: '' },
    { label: 'Koshi', value: 1 },
    { label: 'Madhesh', value: 2 },
    { label: 'Bagmati', value: 3 },
    { label: 'Gandaki', value: 4 },
    { label: 'Lumbini', value: 5 },
    { label: 'Karnali', value: 6 },
    { label: 'Sudurpaschim', value: 7 },
  ]);

  const [showOptions, setShowOptions] = useState({
    showProvinceOption: true,
    collapseAll: false,
  });
  const [showSidebar, setShowSidebar] = useState(false);

  const handleOutsideClick = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setShowSidebar(false);
    }
  };
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setShowSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);
    document.addEventListener('keydown', escFunction, false);
    // returned function will be called on component unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const toggleFilterOption = (filter) => {
    setShowOptions({
      ...showOptions,
      [filter]: !showOptions[filter],
      collapseAll: true,
    });
  };

  const collapseAll = () => {
    setShowOptions({
      showProvinceOption: !showOptions.collapseAll,
      collapseAll: !showOptions.collapseAll,
    });
  };

  const checkForShowOptions = () => {
    return !showOptions.showMinistryOption &&
      !showOptions.showDepartmentOption &&
      !showOptions.showProgramOption &&
      !showOptions.showProjectStatusOption &&
      !showOptions.showProjectStageOption &&
      !showOptions.showProjectTypeOption &&
      !showOptions.showProjectPriorityOption &&
      !showOptions.showProjectRankingOption &&
      !showOptions.showSectorsOption &&
      !showOptions.showSubSectorsOption &&
      !showOptions.showYearOnYearOption &&
      !showOptions.showModerationStatusOption &&
      !showOptions.showProjectDateOption &&
      !showOptions.showAIEOption &&
      !showOptions.showProjectDateOption &&
      !showOptions.showDistrictOption &&
      !showOptions.showProjectReadinessOption
      ? true
      : false;
  };

  return (
    <div ref={(el) => (wrapperRef.current = el)}>
      <div className={`sidebar-filter ${showSidebar ? '' : 'hidden'}`}>
        <div className="close-button" onClick={toggleSidebar}>
          <i className="material-icons close">close</i>
        </div>
        <div className="filter-container">
          <div
            className={`filter-action
                ${
                  ['npcAdmin', 'dataViewer'].includes(user.role) &&
                  filterValues.province.value !== 0
                }
            `}
          >
            {/* <button
              className="btn-rect btn-blue"
              onClick={() => {
                props.searchProject()();
                toggleSidebar();
                props.handleApplyFilterStatus();
              }}
            >
              {language.apply_filter}
            </button>
            <button
              className="btn-text"
              onClick={() => {
                props.clearFilter();
                toggleSidebar();
              }}
            >
              {language.clear_filter}
            </button> */}
          </div>
          <div className="header">
            <p className="title">{language.filter}</p>
            <p className="collapse" onClick={collapseAll}>
              {/* {language.collapse_all_filter} */}
              {checkForShowOptions() ? language.expand_all : language.collapse_all_filter}
              <i className="material-icons ml-2 md-bold md-blue">{`${
                checkForShowOptions() ? 'add' : 'remove'
              }`}</i>
            </p>
          </div>

          {/* Filter Section for Province */}

          <FilterSection
            title={language.province}
            toggleFilterOption={() => toggleFilterOption('showProvinceOption')}
            showOption={showOptions.showProvinceOption}
            placeholder="Type a Province name ..."
            data={provinces}
            handleSelect={(key, option) => {
              props.handleChange(key, option);
              props.searchProject({ [key]: option })();
              toggleSidebar();
            }}
            selectedVal={filterValues.province}
            filterLabel={'province'}
            showSearch={false}
          />
        </div>
      </div>
      <button
        className="btn-normal btn-blue btn-shadow--blue btn-transition btn-filter"
        onClick={toggleSidebar}
      >
        <i className="material-icons md-18 md-bold mr-6">filter_list</i>
        {language.provinicial_filter}
      </button>
    </div>
  );
};

export default Filter;
