import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import { localization } from '../../../../localization';

import {
  handleInputChange,
  handleSaveAndExit,
  addRepeaterField,
  removeRepeaterField,
  handleRepeaterChange,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/FinancingArrangement/action';
import * as ProjectAction from '../../../../reducers/form/action';
import {
  validateNonRequired,
  validateRequired,
  validateNameAmountPairs,
} from '../../../../helpers/validateNumberField';
import NumberToWordsInput from '../../../../components/NumberToWords';

const FinancingArrangement = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage]['arrangement'];

  const dispatch = useDispatch();

  const { financingArrangement } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  const [errors, setError] = useState({
    // Federal
    federal_budgetAmount_error: false,
    federal_budgetAmountInWords_error: false,

    // Province
    province_budgetAmount_error: false,
    province_budgetAmountInWords_error: false,

    // Local Level
    localLevel_budgetAmount_error: false,
    localLevel_budgetAmountInWords_error: false,

    // Internal Loan
    internalLoan_name_error: false,
    internalLoan_budgetAmount_error: false,
    internalLoan_budgetAmountInWords_error: false,

    // External Loan
    externalLoan_name_error: false,
    externalLoan_budgetAmount_error: false,
    externalLoan_budgetAmountInWords_error: false,

    // Foreign Grants
    foreignGrants_name_error: false,
    foreignGrants_budgetAmount_error: false,
    foreignGrants_budgetAmountInWords_error: false,

    // Others
    others_name_error: false,
    others_budgetAmount_error: false,
    others_budgetAmountInWords_error: false,

    // PPP Public Source
    publicSource_name_error: false,
    publicSource_budgetAmount_error: false,
    publicSource_budgetAmountInWords_error: false,

    // PPP Private Source
    privateSource_name_error: false,
    privateSource_budgetAmount_error: false,
    privateSource_budgetAmountInWords_error: false,
  });

  const modalityType = projectInfo?.project_implementation_modality?.projectImplementationModalType;

  useEffect(() => {
    if (projectInfo.project_financing_arrangement) {
      dispatch(
        fetched({
          ...projectInfo.project_financing_arrangement,
        })
      );
    }
    if (!projectInfo.project_financing_arrangement) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = () => {
    let data = cloneDeep(financingArrangement);
    delete data['step'];

    let canSubmit = true;
    const errors = {};

    const projectType =
      projectInfo?.project_implementation_modality?.projectImplementationModalType;

    if (projectType === 'publicGON') {
      const { governmentBudget, loans, foreignGrants, others } = data;
      data = { governmentBudget, loans, foreignGrants, others };

      // Required validations
      canSubmit =
        validateRequired(
          data?.governmentBudget?.federal?.budgetAmount,
          'federal_budgetAmount_error',
          errors
        ) && canSubmit;

      if (!data?.governmentBudget?.federal?.budgetAmountInWords) {
        errors.federal_budgetAmountInWords_error = true;
        canSubmit = false;
      }
      // validate name and amount pairs
      canSubmit = validateNameAmountPairs(data, errors) && canSubmit;

      // Optional validations
      canSubmit =
        validateNonRequired(
          data?.loans?.internalLoan?.budgetAmount,
          'internalLoan_budgetAmount_error',
          errors
        ) && canSubmit;

      canSubmit =
        validateNonRequired(
          data?.loans?.externalLoan?.budgetAmount,
          'externalLoan_budgetAmount_error',
          errors
        ) && canSubmit;

      canSubmit =
        validateNonRequired(
          data?.foreignGrants?.budgetAmount,
          'foreignGrants_budgetAmount_error',
          errors
        ) && canSubmit;

      canSubmit =
        validateNonRequired(data?.others?.budgetAmount, 'others_budgetAmount_error', errors) &&
        canSubmit;
    } else if (projectType === 'ppp') {
      const { publicPrivatePartnership } = data;
      data = { publicPrivatePartnership };

      // validate name and amount pairs
      canSubmit = validateNameAmountPairs(data, errors) && canSubmit;

      canSubmit =
        validateNonRequired(
          data?.publicPrivatePartnership?.publicSource?.budgetAmount,
          'publicSource_budgetAmount_error',
          errors
        ) && canSubmit;

      canSubmit =
        validateNonRequired(
          data?.publicPrivatePartnership?.privateSource?.budgetAmount,
          'privateSource_budgetAmount_error',
          errors
        ) && canSubmit;
    }

    // Set or clear errors
    setError(Object.keys(errors).length > 0 ? errors : {});

    if (canSubmit) {
      handleSaveAndExit(
        { project_financing_arrangement: data },
        'continue',
        dispatch,
        9,
        urlParams
      );
    }
  };

  return (
    <Form className="form-new">
      {modalityType === 'publicGON' && (
        <>
          <h3>{lang.government_budget}</h3>
          <div className="form-group-container">
            {/* Federal */}
            <h5>{lang.federal}</h5>
            <Form.Group>
              <NumberToWordsInput
                amountLabel={lang.budget_amount}
                amountInWordsLabel={lang.budget_amount_words}
                amountPlaceholder={lang.placeholder}
                amountInWordsPlaceholder={lang.placeholder_in_words}
                amountFieldName="budgetAmount"
                amountInWordsFieldName="budgetAmountInWords"
                field="governmentBudget.federal"
                amountValue={financingArrangement?.governmentBudget?.federal?.budgetAmount}
                amountInWordsValue={
                  financingArrangement?.governmentBudget?.federal?.budgetAmountInWords
                }
                error={errors.federal_budgetAmount_error}
                setError={setError}
                data={financingArrangement}
                fetched={fetched}
                required
              />
            </Form.Group>

            {/* Province */}
            {/* <h5>{lang.province}</h5>
            <Form.Group>
              <Form.Field
                control={Input}
                label={lang.budget_amount}
                placeholder={lang.placeholder}
                width={8}
                className={`${errors.budgetAmount_error ? 'error' : 'field-align-bottom'}`}
                name="budgetAmount"
                value={financingArrangement?.governmentBudget?.province?.budgetAmount}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      { event, info: financingArrangement, field: 'governmentBudget.province' },
                      fetched
                    )
                  );
                  setError({ ...errors, budgetAmount_error: false });
                }}
              />

              <Form.Field
                control={Input}
                label={lang.budget_amount_words}
                placeholder={lang.placeholder_in_words}
                width={8}
                className={`${errors.budgetAmountInWords_error ? 'error' : 'field-align-bottom'}`}
                name="budgetAmountInWords"
                value={financingArrangement?.governmentBudget?.province?.budgetAmountInWords}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      { event, info: financingArrangement, field: 'governmentBudget.province' },
                      fetched
                    )
                  );
                  setError({ ...errors, budgetAmountInWords_error: false });
                }}
              />
            </Form.Group> */}

            {/* Local Level */}
            {/* <h5>{lang.local_level}</h5>
            <Form.Group>
              <Form.Field
                control={Input}
                label={lang.budget_amount}
                placeholder={lang.placeholder}
                width={8}
                className={`${errors.budgetAmount_error ? 'error' : 'field-align-bottom'}`}
                name="budgetAmount"
                value={financingArrangement?.governmentBudget?.localLevel?.budgetAmount}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        info: financingArrangement,
                        field: 'governmentBudget.localLevel',
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, budgetAmount_error: false });
                }}
              />

              <Form.Field
                control={Input}
                label={lang.budget_amount_words}
                placeholder={lang.placeholder_in_words}
                width={8}
                className={`${errors.budgetAmountInWords_error ? 'error' : 'field-align-bottom'}`}
                name="budgetAmountInWords"
                value={financingArrangement?.governmentBudget?.localLevel?.budgetAmountInWords}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        info: financingArrangement,
                        field: 'governmentBudget.localLevel',
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, budgetAmountInWords_error: false });
                }}
              />
            </Form.Group> */}
          </div>

          <h3>{lang.loan}</h3>
          <div className="form-group-container">
            {/* Loan */}
            <h5>{lang.internal_loan}</h5>
            {financingArrangement?.loans?.internalLoan?.map((item, index) => {
              return (
                <div
                  className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                  key={`land${index}`}
                >
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      label={lang.name}
                      placeholder={lang.name}
                      width={8}
                      className={`${
                        errors[`internalLoan_${index}_name_error`] ? 'error' : 'field-align-bottom'
                      }`}
                      name="name"
                      value={item.name}
                      onChange={(event) => {
                        dispatch(
                          handleRepeaterChange(
                            {
                              event,
                              info: financingArrangement,
                              field: 'loans',
                              subField: 'internalLoan',
                              index: index,
                            },
                            fetched
                          )
                        );
                        setError((prev) => ({
                          ...prev,
                          [`internalLoan_${index}_name_error`]: false,
                          [`internalLoan_${index}_budgetAmount_error`]: false,
                        }));
                      }}
                    />

                    <NumberToWordsInput
                      identifier="financialArrangement"
                      index={index}
                      amountLabel={lang.budget_amount}
                      amountInWordsLabel={lang.budget_amount_words}
                      amountPlaceholder={lang.placeholder}
                      amountInWordsPlaceholder={lang.placeholder_in_words}
                      amountFieldName="budgetAmount"
                      amountInWordsFieldName="budgetAmountInWords"
                      field={`loans.internalLoan.${index}`}
                      amountValue={item.budgetAmount}
                      amountInWordsValue={item.budgetAmountInWords}
                      error={errors[`internalLoan_${index}_budgetAmount_error`]}
                      setError={setError}
                      data={financingArrangement}
                      fetched={fetched}
                    />
                  </Form.Group>

                  {index > 0 && (
                    <div
                      className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                      onClick={(event) => {
                        dispatch(
                          removeRepeaterField(
                            {
                              event,
                              info: financingArrangement,
                              field: 'loans',
                              subField: 'internalLoan',
                              index: index,
                            },
                            fetched
                          )
                        );
                      }}
                    >
                      <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                      Delete
                    </div>
                  )}
                </div>
              );
            })}
            <Form.Group className="field-repeat-btn">
              <Form.Field>
                <button
                  className="btn-text"
                  onClick={(event) => {
                    dispatch(
                      addRepeaterField(
                        {
                          event,
                          info: financingArrangement,
                          field: 'loans',
                          subField: 'internalLoan',
                        },
                        fetched
                      )
                    );
                  }}
                >
                  + Add more
                </button>
              </Form.Field>
            </Form.Group>

            <h5>{lang.external_loan}</h5>
            {financingArrangement?.loans?.externalLoan?.map((item, index) => {
              return (
                <div
                  className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                  key={`land${index}`}
                >
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      label={lang.name}
                      placeholder={lang.name}
                      width={8}
                      className={`${
                        errors[`externalLoan_${index}_name_error`] ? 'error' : 'field-align-bottom'
                      }`}
                      name="name"
                      value={item.name}
                      onChange={(event) => {
                        dispatch(
                          handleRepeaterChange(
                            {
                              event,
                              info: financingArrangement,
                              field: 'loans',
                              subField: 'externalLoan',
                              index: index,
                            },
                            fetched
                          )
                        );
                        setError((prev) => ({
                          ...prev,
                          [`externalLoan_${index}_name_error`]: false,
                          [`externalLoan_${index}_budgetAmount_error`]: false,
                        }));
                      }}
                    />

                    <NumberToWordsInput
                      identifier="financialArrangement"
                      index={index}
                      amountLabel={lang.budget_amount}
                      amountInWordsLabel={lang.budget_amount_words}
                      amountPlaceholder={lang.placeholder}
                      amountInWordsPlaceholder={lang.placeholder_in_words}
                      amountFieldName="budgetAmount"
                      amountInWordsFieldName="budgetAmountInWords"
                      field={`loans.externalLoan.${index}`}
                      amountValue={item.budgetAmount}
                      amountInWordsValue={item.budgetAmountInWords}
                      error={errors[`externalLoan_${index}_budgetAmount_error`]}
                      setError={setError}
                      data={financingArrangement}
                      fetched={fetched}
                    />
                  </Form.Group>

                  {index > 0 && (
                    <div
                      className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                      onClick={(event) => {
                        dispatch(
                          removeRepeaterField(
                            {
                              event,
                              info: financingArrangement,
                              field: 'loans',
                              subField: 'externalLoan',
                              index: index,
                            },
                            fetched
                          )
                        );
                      }}
                    >
                      <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                      Delete
                    </div>
                  )}
                </div>
              );
            })}
            <Form.Group className="field-repeat-btn">
              <Form.Field>
                <button
                  className="btn-text"
                  onClick={(event) => {
                    dispatch(
                      addRepeaterField(
                        {
                          event,
                          info: financingArrangement,
                          field: 'loans',
                          subField: 'externalLoan',
                        },
                        fetched
                      )
                    );
                  }}
                >
                  + Add more
                </button>
              </Form.Field>
            </Form.Group>
          </div>

          {/* Foreign grants */}
          <h3>{lang.foreign_grant}</h3>
          <div className="form-group-container">
            {financingArrangement?.foreignGrants?.map((item, index) => {
              return (
                <div
                  className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                  key={`land${index}`}
                >
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      label={lang.name}
                      placeholder={lang.name}
                      width={8}
                      className={`${
                        errors[`foreignGrants_${index}_name_error`] ? 'error' : 'field-align-bottom'
                      }`}
                      name="name"
                      value={item.name}
                      onChange={(event) => {
                        dispatch(
                          handleRepeaterChange(
                            {
                              event,
                              info: financingArrangement,
                              field: 'foreignGrants',
                              index: index,
                            },
                            fetched
                          )
                        );
                        setError((prev) => ({
                          ...prev,
                          [`foreignGrants_${index}_name_error`]: false,
                          [`foreignGrants_${index}_budgetAmount_error`]: false,
                        }));
                      }}
                    />

                    <NumberToWordsInput
                      identifier="financialArrangement"
                      index={index}
                      amountLabel={lang.budget_amount}
                      amountInWordsLabel={lang.budget_amount_words}
                      amountPlaceholder={lang.placeholder}
                      amountInWordsPlaceholder={lang.placeholder_in_words}
                      amountFieldName="budgetAmount"
                      amountInWordsFieldName="budgetAmountInWords"
                      field={`foreignGrants.${index}`}
                      amountValue={item.budgetAmount}
                      amountInWordsValue={item.budgetAmountInWords}
                      error={errors[`foreignGrants_${index}_budgetAmount_error`]}
                      setError={setError}
                      data={financingArrangement}
                      fetched={fetched}
                    />
                  </Form.Group>

                  {index > 0 && (
                    <div
                      className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                      onClick={(event) => {
                        dispatch(
                          removeRepeaterField(
                            {
                              event,
                              info: financingArrangement,
                              field: 'foreignGrants',
                              index: index,
                            },
                            fetched
                          )
                        );
                      }}
                    >
                      <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                      Delete
                    </div>
                  )}
                </div>
              );
            })}
            <Form.Group className="field-repeat-btn">
              <Form.Field>
                <button
                  className="btn-text"
                  onClick={(event) => {
                    dispatch(
                      addRepeaterField(
                        {
                          event,
                          info: financingArrangement,
                          field: 'foreignGrants',
                        },
                        fetched
                      )
                    );
                  }}
                >
                  + Add more
                </button>
              </Form.Field>
            </Form.Group>
          </div>
          {/* Others */}
          <h3>{lang.others}</h3>
          <div className="form-group-container">
            {financingArrangement?.others?.map((item, index) => {
              return (
                <div
                  className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                  key={`land${index}`}
                >
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      label={lang.name}
                      placeholder={lang.name}
                      width={8}
                      className={`${
                        errors[`others_${index}_name_error`] ? 'error' : 'field-align-bottom'
                      }`}
                      name="name"
                      value={item.name}
                      onChange={(event) => {
                        dispatch(
                          handleRepeaterChange(
                            {
                              event,
                              info: financingArrangement,
                              field: 'others',
                              index: index,
                            },
                            fetched
                          )
                        );
                        setError((prev) => ({
                          ...prev,
                          [`others_${index}_name_error`]: false,
                          [`others_${index}_budgetAmount_error`]: false,
                        }));
                      }}
                    />

                    <NumberToWordsInput
                      identifier="financialArrangement"
                      index={index}
                      amountLabel={lang.budget_amount}
                      amountInWordsLabel={lang.budget_amount_words}
                      amountPlaceholder={lang.placeholder}
                      amountInWordsPlaceholder={lang.placeholder_in_words}
                      amountFieldName="budgetAmount"
                      amountInWordsFieldName="budgetAmountInWords"
                      field={`others.${index}`}
                      amountValue={item.budgetAmount}
                      amountInWordsValue={item.budgetAmountInWords}
                      error={errors[`others_${index}_budgetAmount_error`]}
                      setError={setError}
                      data={financingArrangement}
                      fetched={fetched}
                    />
                  </Form.Group>

                  {index > 0 && (
                    <div
                      className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                      onClick={(event) => {
                        dispatch(
                          removeRepeaterField(
                            {
                              event,
                              info: financingArrangement,
                              field: 'others',
                              index: index,
                            },
                            fetched
                          )
                        );
                      }}
                    >
                      <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                      Delete
                    </div>
                  )}
                </div>
              );
            })}
            <Form.Group className="field-repeat-btn">
              <Form.Field>
                <button
                  className="btn-text"
                  onClick={(event) => {
                    dispatch(
                      addRepeaterField(
                        {
                          event,
                          info: financingArrangement,
                          field: 'others',
                        },
                        fetched
                      )
                    );
                  }}
                >
                  + Add more
                </button>
              </Form.Field>
            </Form.Group>
          </div>
        </>
      )}

      {modalityType === 'ppp' && (
        <>
          <h3>{lang.ppp_projects}</h3>
          {/* Public source */}
          <div className="form-group-container">
            <h5>{lang.public_source}</h5>
            {financingArrangement?.publicPrivatePartnership?.publicSource?.map((item, index) => {
              return (
                <div
                  className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                  key={`land${index}`}
                >
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      label={lang.name}
                      placeholder={lang.name}
                      width={8}
                      className={`${
                        errors[`publicSource_${index}_name_error`] ? 'error' : 'field-align-bottom'
                      }`}
                      name="name"
                      value={item.name}
                      onChange={(event) => {
                        dispatch(
                          handleRepeaterChange(
                            {
                              event,
                              info: financingArrangement,
                              field: 'publicPrivatePartnership',
                              subField: 'publicSource',
                              index: index,
                            },
                            fetched
                          )
                        );
                        setError((prev) => ({
                          ...prev,
                          [`publicSource_${index}_name_error`]: false,
                          [`publicSource_${index}_budgetAmount_error`]: false,
                        }));
                      }}
                    />

                    <NumberToWordsInput
                      identifier="financialArrangement"
                      index={index}
                      amountLabel={lang.budget_amount}
                      amountInWordsLabel={lang.budget_amount_words}
                      amountPlaceholder={lang.placeholder}
                      amountInWordsPlaceholder={lang.placeholder_in_words}
                      amountFieldName="budgetAmount"
                      amountInWordsFieldName="budgetAmountInWords"
                      field={`publicPrivatePartnership.publicSource.${index}`}
                      amountValue={item.budgetAmount}
                      amountInWordsValue={item.budgetAmountInWords}
                      error={errors[`publicSource_${index}_budgetAmount_error`]}
                      setError={setError}
                      data={financingArrangement}
                      fetched={fetched}
                    />
                  </Form.Group>

                  {index > 0 && (
                    <div
                      className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                      onClick={(event) => {
                        dispatch(
                          removeRepeaterField(
                            {
                              event,
                              info: financingArrangement,
                              field: 'publicPrivatePartnership',
                              subField: 'publicSource',
                              index: index,
                            },
                            fetched
                          )
                        );
                      }}
                    >
                      <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                      Delete
                    </div>
                  )}
                </div>
              );
            })}

            <Form.Group className="field-repeat-btn">
              <Form.Field>
                <button
                  className="btn-text"
                  disabled={
                    financingArrangement?.publicPrivatePartnership?.publicSource?.length === 0
                  }
                  onClick={(event) => {
                    dispatch(
                      addRepeaterField(
                        {
                          event,
                          info: financingArrangement,
                          field: 'publicPrivatePartnership',
                          subField: 'publicSource',
                        },
                        fetched
                      )
                    );
                  }}
                >
                  + Add more
                </button>
              </Form.Field>
            </Form.Group>
          </div>
          {/* Private source */}
          <div className="form-group-container">
            <h5>{lang.private_source}</h5>
            {financingArrangement?.publicPrivatePartnership?.privateSource?.map((item, index) => {
              return (
                <div
                  className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                  key={`land${index}`}
                >
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      label={lang.name}
                      placeholder={lang.name}
                      width={8}
                      className={`${
                        errors[`privateSource_${index}_name_error`] ? 'error' : 'field-align-bottom'
                      }`}
                      name="name"
                      value={item.name}
                      onChange={(event) => {
                        dispatch(
                          handleRepeaterChange(
                            {
                              event,
                              info: financingArrangement,
                              field: 'publicPrivatePartnership',
                              subField: 'privateSource',
                              index: index,
                            },
                            fetched
                          )
                        );
                        setError((prev) => ({
                          ...prev,
                          [`privateSource_${index}_name_error`]: false,
                          [`privateSource_${index}_budgetAmount_error`]: false,
                        }));
                      }}
                    />

                    <NumberToWordsInput
                      identifier="financialArrangement"
                      index={index}
                      amountLabel={lang.budget_amount}
                      amountInWordsLabel={lang.budget_amount_words}
                      amountPlaceholder={lang.placeholder}
                      amountInWordsPlaceholder={lang.placeholder_in_words}
                      amountFieldName="budgetAmount"
                      amountInWordsFieldName="budgetAmountInWords"
                      field={`publicPrivatePartnership.privateSource.${index}`}
                      amountValue={item.budgetAmount}
                      amountInWordsValue={item.budgetAmountInWords}
                      error={errors[`privateSource_${index}_budgetAmount_error`]}
                      setError={setError}
                      data={financingArrangement}
                      fetched={fetched}
                    />
                  </Form.Group>

                  {index > 0 && (
                    <div
                      className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                      onClick={(event) => {
                        dispatch(
                          removeRepeaterField(
                            {
                              event,
                              info: financingArrangement,
                              field: 'publicPrivatePartnership',
                              subField: 'privateSource',
                              index: index,
                            },
                            fetched
                          )
                        );
                      }}
                    >
                      <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                      Delete
                    </div>
                  )}
                </div>
              );
            })}

            <Form.Group className="field-repeat-btn">
              <Form.Field>
                <button
                  className="btn-text"
                  onClick={(event) => {
                    dispatch(
                      addRepeaterField(
                        {
                          event,
                          info: financingArrangement,
                          field: 'publicPrivatePartnership',
                          subField: 'privateSource',
                        },
                        fetched
                      )
                    );
                  }}
                >
                  + Add more
                </button>
              </Form.Field>
            </Form.Group>
          </div>
        </>
      )}

      <div className="form-actions flex">
        <button
          type="submit"
          onClick={handleSaveAndContinue}
          className="btn-rect btn-rect--small btn-blue"
        >
          Save and continue
        </button>
        <button className="btn-rect btn-rect--small btn-dark">Save and exit</button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 7 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default FinancingArrangement;
