import React, { useEffect, useState, useRef } from 'react';
import { Table, Modal, Button, Pagination, Header } from 'semantic-ui-react';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import query from 'query-string';
import { map } from 'lodash';
import moment from 'moment';

import empty from '../../../assets/images/no-data.svg';
// import published from '../../../assets/images/published.png';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';

import LoaderNP from '../../../components/Loader/Loader';
import Can from '../../../components/Can/can';
import PieChart from '../../../components/Chart/PieChart';
import PieChartLegend from '../../../components/Chart/PieChartLegend';

import ProjectMap from '../../ProjectMap';

import ProjectService from '../../../services/Gate/Project';

import * as ProjectAction from '../../../reducers/form/action';

import Filter from './Filter';
import ROLES from '../../../helpers/constant/Roles';
import MODERATION_STATUS from '../../../helpers/constant/ModerationStatus';
import { projectStatus } from '../../../helpers/constant/ProjectStatus';

const ProjectList = ({
  handleParams,
  pageSize,
  history,
  projectYearOnYear,
  projectsStatus,
  totalProjectYearOnYear,
  projectStatusChartInfo,
}) => {
  const child = useRef();
  const dispatch = useDispatch();

  const user = useSelector(auth.getCurrentUser);
  const [filters, setFilters] = useState({
    search: '',
    program_id: [{ label: 'Select a Program', value: 0 }],
    ministry_id: [{ label: 'Select a Ministry', value: 0 }],
    projectStatus: { label: 'Select Project Status', value: 0 },
    projectStage: { label: 'Select Project Stage', value: 0 },
    moderationStatus:
      user.role === 'npcAdmin'
        ? { label: 'Select Moderation Status', value: 'completed' }
        : { label: 'Select Moderation Status', value: 0 },
    projectType: [{ label: 'Select Project Type', value: 0 }],
    priorityProjectOptions: [{ label: 'Select Project Priority', value: 0 }],
    sector: [{ label: 'Select a Sector', value: 0 }],
    subSector: [{ label: 'Select a Sub-Sector', value: 0 }],
    department: [{ label: 'Select a Agency', value: 0 }],
    province: [{ label: 'Select a Province', value: 0 }],
    district: [{ label: 'Select a District', value: 0 }],
    isYearlyFunding: { label: 'Select an year-on-year', value: 0 },
    ranking: [{ label: '', value: 0 }],
    projectCostFrom: '',
    projectCostTo: '',
    projectStartDateFrom: '',
    projectStartDateTo: '',
    feasibilityStudy: '',
    dpr: '',
    eia: '',
    iee: '',
    monitorEvaluation: '',
    riskManagement: '',
    logFrame: '',
    deskStudy: '',
    projectConcept: '',
    projectProposal: '',
    masterProcurement: '',
    annualProcurement: '',
    annualProcurementComing: '',
    implementationPlan: '',
    procurementAward: '',
    landAcquisition: '',
    applied: user.role === 'npcAdmin' ? true : false,
  });

  const [showView, setShowView] = useState('table');
  const [modalData, setModatData] = useState({
    modalOpen: false,
    projectId: '',
  });
  const [chartModal, setChartModal] = useState({
    chartModalOpen: false,
    data: {},
  });
  const yearOnYearLoading = useSelector((state) => state.dashboard.yearOnYearLoading);
  const statusLoading = useSelector((state) => state.dashboard.statusLoading);
  const mapLoading = useSelector((state) => state.project.mapLoading);

  const [appliedFilters, setAppliedFilters] = useState([]);
  const selectedLanguage = useSelector(auth.getSelectedLanguage);
  const lang = localization[selectedLanguage];
  const language = lang.project_filter;
  // const [projectId, setProjectId] = useState('');
  // const [clearFilter, setClearFilter] = useState(false);

  const [pagination, setPagination] = useState({
    activePage: 1,
    column: null,
    direction: null,
  });
  const [projectData, setProjectData] = useState({
    projects: null,
    count: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const readinessFields = [
    'feasibilityStudy',
    'dpr',
    'eia',
    'iee',
    'monitorEvaluation',
    'riskManagement',
    'logFrame',
    'deskStudy',
    'projectConcept',
    'projectProposal',
    'masterProcurement',
    'annualProcurement',
    'annualProcurementComing',
    'implementationPlan',
    'procurementAward',
    'landAcquisition',
  ];
  const [isExporting, setIsExporting] = useState(false);

  const keyValue = query.parse(history.location.search);

  const listProjectsForMap = (query) => {
    dispatch(ProjectAction.checkLoadingMap(true));
    ProjectService.listProjectMap(query)
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.mapProjectList(response));
          dispatch(ProjectAction.checkLoadingMap(false));
        } else {
          dispatch(ProjectAction.checkLoadingMap(false));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (keyValue && keyValue.ranking) {
      setFilters({
        ...filters,
        applied: true,
        ranking: [{ value: keyValue.ranking, label: keyValue.ranking }],
        projectStatus: { label: keyValue.status, value: keyValue.status },
        projectStage: keyValue.stage
          ? { label: keyValue.stage, value: keyValue.stage }
          : { label: 'Select a Project Stage', value: 0 },
      });
      searchProject()(null);
    } else {
      listProjects(`?page=0&pageSize=25`);
      listProjectsForMap();
    }
  }, [keyValue.ranking]);

  useEffect(() => {
    searchProject()(null);
  }, [pagination.activePage]);

  useEffect(() => {
    if (![ROLES.dataApprover, ROLES.dataViewer].includes(user.role)) {
      setFilters((filters) => {
        return {
          ...filters,
          moderationStatus: {
            label: filters.moderationStatus.value,
            value: filters.moderationStatus.value,
          },
          // ministry_id: [{ label: user.ministry.name, value: user.ministry.id }],
        };
      });
    }
  }, [user]);

  const deleteProject = (e) => {
    e.preventDefault();
    ProjectService.deleteProject({ id: modalData.projectId })
      .then((response) => {
        if (response.success) {
          toastr.success('', `${response.message}`);
          setProjectData({ projects: response.data.projects, count: response.data.count });
        } else {
          toastr.error('', `${response.error.message}`);
        }
      })
      .catch((err) => toastr.error('', `${err.message}`));
    setModatData({ ...modalData, modalOpen: !modalData.modalOpen });
  };

  const getQueryString = (appFilters = null) => {
    const joinValues = (arr, type = false) => {
      return type ? map(arr, 'label').join(':') : map(arr, 'value').join(':');
    };
    let f = filters;

    const appliedFilters = [];
    let queryString = '?';

    if (appFilters) {
      f = appFilters;
    }

    for (const filter in f) {
      if (typeof f[filter] === 'string' && f[filter] !== '') {
        queryString = `${queryString}${filter}=${f[filter].trim()}&`;
      } else if (f[filter] instanceof Array && f[filter][0].value !== 0) {
        queryString = `${queryString}${filter}=${joinValues(f[filter])}&`;
        appliedFilters.push({ [filter]: f[filter] });
      } else if (
        typeof f[filter] === 'object' &&
        !Array.isArray(f[filter]) &&
        f[filter].value !== 0
      ) {
        queryString = `${queryString}${filter}=${f[filter].value}&`;
      }
    }

    setAppliedFilters(appliedFilters);

    return queryString;
  };

  const searchProject =
    (appFilters = null) =>
    (e) => {
      if (e) e.preventDefault();
      let { activePage, column, direction } = pagination;
      activePage -= 1;
      let queryString;

      if (appFilters) {
        queryString = getQueryString(appFilters);
      } else {
        queryString = getQueryString();
      }

      if (showView === 'map') {
        listProjectsForMap(queryString);
      }

      if (keyValue.ranking && keyValue.status) {
        if (
          !queryString.includes('ranking') &&
          Object.keys(query.parse(history.location.search)).length > 0
        ) {
          queryString = keyValue.stage
            ? `${queryString}ranking=${keyValue.ranking}&projectStatus=${keyValue.status}&projectStage=${keyValue.stage}&`
            : `${queryString}ranking=${keyValue.ranking}&projectStatus=${keyValue.status}&`;
          setAppliedFilters([{ ranking: [{ value: keyValue.ranking, label: keyValue.ranking }] }]);
        }
      }

      if (handleParams) handleParams(queryString);

      if (column) {
        queryString = `${queryString}column=${column}&sort=${direction}&`;
      }

      queryString = `${queryString}page=${activePage}&pageSize=25`;

      listProjects(queryString);
    };

  const handlePaginationChange = (e, { activePage }) => {
    setPagination({ ...pagination, activePage });
  };

  const handleSort = (clickedColumn) => () => {
    const { column, direction } = pagination;
    if (column !== clickedColumn) {
      setPagination({
        column: clickedColumn,
        direction: 'ascending',
        activePage: 1,
      });

      return;
    }
    setPagination({
      ...pagination,
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      activePage: 1,
    });
  };

  const exportExcel = async () => {
    setIsExporting(true);
    const queryString = getQueryString();

    const data = await ProjectService.exportProjects(queryString);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', 'projectList.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    setIsExporting(false);
  };

  const handleRoute = (projectID) => {
    history.push(`/project/${projectID}`);
  };

  const listProjects = (queryString) => {
    setIsLoading(true);
    const qs = query.parse(queryString);
    let qString = queryString;
    if (qs.projectStatus && qs.projectStatus === 'ongoing' && qs.projectStage) {
      delete qs.projectStage;
      setFilters({ ...filters, projectStage: { label: 'Select Project Stage', value: 0 } });
      qString = `?${query.stringify(qs)}`;
    }
    // process.exit()
    if ([ROLES.npcAdmin].includes(user.role)) {
      if (!qs.moderationStatus) {
        qString = `${qString}&moderationStatus=completed`;
      }
    }

    ProjectService.listProjects(`${qString}`)
      .then((response) => {
        if (response.success) {
          setProjectData({ projects: response.data.projects, count: response.data.count });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastr.error('', `${err.message}`);
      });
  };

  const setView = (view) => {
    setShowView(view, () => {
      if (view === 'map') {
        const queryString = getQueryString();
        listProjectsForMap(queryString);
      }
    });
  };

  const handleSelect = (key, option) => {
    if (key === 'ranking' && query.parse(history.location.search)) {
      history.push({
        pathname: '/dashboard',
        search: '',
      });
    }
    let arr = [];
    const childFilters = {
      ministry_id: 'department',
      province: 'district',
      sector: 'subSector',
    };

    if (filters[key][0].value !== 0) {
      arr = [...filters[key]];
      const check = arr.find((d) => d.value === option.value);
      if (check) arr = arr.filter((d) => d.value !== option.value);
      else arr.push(option);
      if (arr.length < 1) arr = [{ value: 0 }];
    } else {
      arr.push(option);
    }

    if (key === 'ministry_id' || key === 'province' || key === 'sector') {
      const childFilter = filters[childFilters[key]].filter((d) =>
        key === 'sector' ? d[key] !== option.label : d[key] !== option.value
      );
      if (childFilter.length === 0) setFilters({ ...filters, [childFilters[key]]: [{ value: 0 }] });
      else {
        setFilters({ ...filters, [childFilters[key]]: childFilter });
      }
    }

    setFilters({ ...filters, [key]: arr });
    // setPagination({ ...pagination, activePage: 1 });
  };

  const handleChange = (key, option) => {
    if (key === 'projectStatus' && query.parse(history.location.search)) {
      history.push({
        pathname: '/dashboard',
        search: '',
      });
    }

    setFilters({ ...filters, [key]: option });
    // if(key === 'projectStatus' && option.value === 'ongoing') {
    //   setFilters({ ...filters, [key]: { label: 'Select Project Stage', value: 0 } })
    // }

    // setPagination({ ...pagination, activePage: 1 });
  };

  const handleDateChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const clearFilter = () => {
    if (query.parse(history.location.search)) {
      history.push({
        pathname: '/dashboard',
        search: '',
      });
    }

    let ministry_id = [{ label: 'Select a Ministry', value: 0 }];
    if ([ROLES.npcAdmin, ROLES.dataViewer].indexOf(user.role) === -1) {
      ministry_id = [
        {
          label: user.ministry.name,
          value: user.ministry.id,
        },
      ];
    }
    setFilters({
      applied: false,
      program_id: [{ label: 'Select a Program', value: 0 }],
      ministry_id,
      projectStatus: { label: 'Select Project Status', value: 0 },
      projectStage: { label: 'Select Project Stage', value: 0 },
      moderationStatus: { label: 'Select Moderation Status', value: 0 },
      projectType: [{ label: 'Select Project Type', value: 0 }],
      priorityProjectOptions: [{ label: 'Select Project Priority', value: 0 }],
      sector: [{ label: 'Select a Sector', value: 0 }],
      subSector: [{ label: 'Select a Sub-Sector', value: 0 }],
      department: [{ label: 'Select a Agency', value: 0 }],
      province: [{ label: 'Select a Province', value: 0 }],
      district: [{ label: 'Select a District', value: 0 }],
      isYearlyFunding: { label: 'Select an year-on-year', value: 0 },
      ranking: [{ label: '', value: 0 }],
      projectCostFrom: '',
      projectCostTo: '',
      projectStartDateFrom: '',
      projectStartDateTo: '',
      feasibilityStudy: '',
      dpr: '',
      eia: '',
      iee: '',
      monitorEvaluation: '',
      riskManagement: '',
      logFrame: '',
      deskStudy: '',
      projectConcept: '',
      projectProposal: '',
      masterProcurement: '',
      annualProcurement: '',
      annualProcurementComing: '',
      implementationPlan: '',
      procurementAward: '',
      landAcquisition: '',
    });
    searchProject()(null);
    window.location.reload('/dashboard');
  };

  const handleApplyFilterStatus = () => {
    const queryString = getQueryString();
    listProjectsForMap(queryString);
    setFilters({ ...filters, applied: true });
    // searchProject(null);
  };

  const showFiltertag = (filter, label) => {
    const filterWithLabel = {
      isYearlyFunding: 'Year-on-Year Project',
      feasibilityStudy: 'Feasibility study',
      dpr: 'Detailed project plan',
      eia: 'EIA',
      iee: 'IEE',
      monitorEvaluation: 'Monitoring evaluation plan',
      riskManagement: 'Risk management and mitigation plan',
      logFrame: 'Development of Logical framework',
      deskStudy: 'Desk Study',
      projectConcept: 'Project concept note',
      projectProposal: 'Project Proposal',
      masterProcurement: 'Master Procurement Plan',
      annualProcurement: 'Annual Procurement Plan For This Fiscal Year',
      annualProcurementComing: 'Annual Procurement Plan For This Upcoming Fiscal Year',
      implementationPlan: 'Project Implementation Plan',
      procurementAward: 'Procurement and Contract Award',
      landAcquisition: 'Completion of the legal process of land acquisition',
      projectStatus: 'Project Status',
      projectStage: 'Project Stage',
      moderationStatus: 'Moderation Status',
    };

    const multiSelectFilter = {
      ministry_id: 'Ministry',
      department: 'Agency',
      projectType: 'Project Type',
      province: 'Province',
      district: 'District',
      program_id: 'Program',
      priorityProjectOptions: 'Project Priority',
      sector: 'Sector',
      subSector: 'Sub-Sector',
      ranking: 'Ranking',
    };
    if (![ROLES.npcAdmin, ROLES.dataViewer].includes(user.role) && filter === 'ministry_id') {
      return;
    }

    return filterWithLabel[filter] ? (
      <div className="tag">
        <span onClick={() => handleRemoveFilter(filter)}>
          <i className="material-icons">close</i>
        </span>
        {`${filterWithLabel[filter]}: ${label}`}
      </div>
    ) : Array.isArray(label) ? (
      <div key={filter} className="tag">
        {multiSelectFilter[filter]}:
        {label.map((l, i) => (
          <div key={i} className="sub-tag">
            <span
              onClick={() =>
                handleRemoveFilter(
                  filter,
                  filter === 'sector' || filter === 'subSector' ? l.label : l.value
                )
              }
            >
              <i className="material-icons">close</i>
            </span>
            {l.label}
          </div>
        ))}
      </div>
    ) : (
      <div className="tag">
        <span onClick={() => handleRemoveFilter(filter)}>
          <i className="material-icons">close</i>
        </span>
        {label}
      </div>
    );
  };

  const checkForAppliedFilters = () => {
    return !!(
      (appliedFilters && appliedFilters.length) ||
      filters.projectStatus.value !== 0 ||
      filters.projectStage.value !== 0 ||
      filters.isYearlyFunding.value !== 0 ||
      filters.moderationStatus.value !== 0 ||
      (filters.projectCostFrom && filters.projectCostTo) ||
      filters.projectStartDateFrom ||
      filters.projectStartDateTo ||
      filters.feasibilityStudy !== '' ||
      filters.dpr !== '' ||
      filters.eia !== '' ||
      filters.iee !== '' ||
      filters.monitorEvaluation !== '' ||
      filters.riskManagement !== '' ||
      filters.logFrame !== '' ||
      filters.deskStudy !== '' ||
      filters.projectConcept !== '' ||
      filters.projectProposal !== '' ||
      filters.masterProcurement !== '' ||
      filters.annualProcurement !== '' ||
      filters.annualProcurementComing !== '' ||
      filters.implementationPlan !== '' ||
      filters.procurementAward !== '' ||
      filters.landAcquisition !== ''
    );
  };

  const handleRemoveFilter = (filter, value = 0) => {
    if (query.parse(history.location.search)) {
      history.push({
        pathname: '/dashboard',
        search: '',
      });
    }
    const readiness = [
      'feasibility_study',
      'detailed_project_report',
      'eia',
      'iee',
      'monitor_evaluation_plan',
      'risk_management_and_mitigation_plan',
      'development_of_logical_frameWork',
      'desk_study',
      'project_concept_note',
      'project_proposal',
      'master_procurement_plan',
      'annual_procurement_plan_for_this_FY',
      'annual_procurement_plan_for_this_coming_FY',
      'project_implementation_plan',
      'procurement_and_contract_award',
      'completion_of_the_legal_process_of_land_acquisition',
    ];
    const multiSelect = [
      'province',
      'district',
      'ministry_id',
      'department',
      'program_id',
      'projectType',
      'priorityProjectOptions',
      'sector',
      'subSector',
      'ranking',
    ];
    const childFilters = {
      ministry_id: 'department',
      province: 'district',
      sector: 'subSector',
    };
    let reset = { ...filters };
    if (filter === 'removeCostFilter') {
      reset = { ...reset, projectCostFrom: '', projectCostTo: '' };
    } else if (filter === 'removeDateFilter') {
      reset = { ...reset, projectStartDateFrom: '', projectStartDateTo: '' };
    } else if (readiness.includes(filter)) {
      reset = { ...reset, [filter]: '' };
    } else if (multiSelect.includes(filter)) {
      let arr = filters[filter].filter((f) => f.value !== value);
      if (arr.length === 0) arr = [{ value: 0 }];

      if (filter === 'ministry_id' || filter === 'province' || filter === 'sector') {
        const childFilter = filters[childFilters[filter]].filter((d) => d[filter] !== value);
        if (childFilter.length === 0) reset = { ...reset, [childFilters[filter]]: [{ value: 0 }] };
        else {
          reset = { ...reset, [childFilters[filter]]: childFilter };
        }
      }
      reset = { ...reset, [filter]: arr };
    } else if (filter === 'projectStatus') {
      reset = {
        ...reset,
        projectStatus: { label: 'Select Project Status', value: 0 },
        projectStage: { label: 'Select Project Stage', value: 0 },
      };
    } else {
      reset = { ...reset, [filter]: { value: 0 } };
    }
    setFilters(reset);
    searchProject(reset)(null);
  };

  const colorMapping = (statusData) => {
    const colors = {
      verification: '#F9BB81',
      submitted: '#88C26D',
      draft: '#EE8282',
      approved: '#5ACBD2',
    };

    return statusData.map((stat) => colors[stat.label]);
  };

  return (
    <>
      {!isExporting ? (
        <button
          className="btn-normal btn-dark btn-shadow--dark btn-transition btn-export"
          onClick={exportExcel}
        >
          <i className="material-icons md-normal mr-6">system_update_alt</i>
          {language.export_data}
        </button>
      ) : (
        <button className="btn-normal btn-dark btn-shadow--dark btn-transition btn-export">
          <i className="ui secondary loading button" />
          {language.export_data}
        </button>
      )}
      <Filter
        handleSelect={handleSelect}
        handleChange={handleChange}
        filterValues={filters}
        handleDateChange={handleDateChange}
        searchProject={searchProject}
        clearFilter={clearFilter}
        language={language}
        handleApplyFilterStatus={handleApplyFilterStatus}
        user={user}
        applied={filters.applied}
      />
      <div className="flex align-center chart-search-wrapper">
        <div>
          <form onSubmit={searchProject()}>
            <div className="form-search">
              <div className="field-search">
                <input
                  className="input-text input-icon"
                  onChange={(e) => {
                    setFilters({ ...filters, search: e.target.value });
                    setPagination({ ...pagination, activePage: 1 });
                  }}
                  type="text"
                  placeholder="Search by typing project id, title or budget code..."
                />
                <i className="material-icons md-24">search</i>
                <button className="button" type="submit">
                  {language.search}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="pie-chart-loader">
          {yearOnYearLoading ? (
            <LoaderNP />
          ) : (
            <>
              {projectYearOnYear && projectYearOnYear.length > 0 ? (
                <>
                  <div
                    className="pie-chart-wrapper flex align-center"
                    onClick={() => {
                      setChartModal({
                        chartModalOpen: !chartModal.chartModalOpen,
                        data: {
                          data: projectYearOnYear,
                          title: language.is_yearly_funding,
                          colors: ['#CACFD7', '#9AA2AF'],
                        },
                      });
                    }}
                  >
                    <PieChart data={projectYearOnYear} colors={['#CACFD7', '#9AA2AF']} />
                    <div className="flex">
                      {projectYearOnYear &&
                        projectYearOnYear.map((stat, index) => {
                          if (stat.name === 'Yes') {
                            return (
                              <div key={index} className="chart-stat flex">
                                <div>
                                  <p className="chart-stat-info">
                                    {parseFloat(
                                      (stat.count / totalProjectYearOnYear) * 100
                                    ).toFixed(2)}
                                    % <span className="label">year-on-year projects</span>
                                  </p>
                                  <p className="chart-stat-amount">({stat.count})</p>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </>
          )}
        </div>

        {user.role !== 'npcAdmin' && (
          <div className="pie-chart-loader">
            {statusLoading ? (
              <LoaderNP />
            ) : (
              <>
                {projectsStatus && projectsStatus.length > 0 ? (
                  <>
                    <div
                      className="pie-chart-wrapper flex align-center"
                      onClick={() => {
                        setChartModal({
                          chartModalOpen: !chartModal.chartModalOpen,
                          data: {
                            data: projectsStatus,
                            title: language.project_status,
                            colors: colorMapping(projectStatusChartInfo),
                          },
                        });
                      }}
                    >
                      <PieChart
                        data={projectsStatus}
                        colors={colorMapping(projectStatusChartInfo)}
                        // colors={['#EE8282', '#F9BB81', '#88C26D']}
                      />
                      <div className="flex">
                        {projectStatusChartInfo &&
                          projectStatusChartInfo.map((stat, index) => {
                            return (
                              <div key={index} className="chart-stat flex">
                                <i className={`indicator ${stat.label}`} />
                                <div>
                                  <p className="chart-stat-info">
                                    {stat.percentage}%<span className="label">{stat.label}</span>
                                  </p>
                                  <p className="chart-stat-amount">({stat.count})</p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        )}
      </div>

      {filters.applied && (
        <div className="filter-tags">
          {checkForAppliedFilters() && <p>{language.filter_applied}: </p>}

          <div className="tags-list">
            {appliedFilters
              ? appliedFilters.map((a, i) => showFiltertag(Object.keys(a)[0], a[Object.keys(a)[0]]))
              : ''}
            {filters.projectStatus.value !== 0
              ? showFiltertag('projectStatus', filters.projectStatus.label)
              : ''}
            {filters.projectStage.value !== 0 && filters.projectStatus.value !== 'ongoing'
              ? showFiltertag('projectStage', filters.projectStage.label)
              : ''}
            {filters.isYearlyFunding.value !== 0
              ? showFiltertag('isYearlyFunding', filters.isYearlyFunding.label)
              : ''}
            {filters.moderationStatus.value !== 0
              ? showFiltertag('moderationStatus', filters.moderationStatus.label)
              : ''}
            {filters.projectCostFrom &&
              filters.projectCostTo &&
              showFiltertag(
                'removeCostFilter',
                `Budget: ${filters.projectCostFrom} to ${filters.projectCostTo}`
              )}
            {filters.projectStartDateFrom &&
              filters.projectStartDateTo &&
              showFiltertag(
                'removeDateFilter',
                `Project start date: ${filters.projectStartDateFrom} to ${filters.projectStartDateTo}`
              )}
            {filters.projectStartDateFrom &&
              !filters.projectStartDateTo &&
              showFiltertag(
                'removeDateFilter',
                `Project start date: From ${filters.projectStartDateFrom}`
              )}
            {filters.projectStartDateTo &&
              !filters.projectStartDateFrom &&
              showFiltertag(
                'removeDateFilter',
                `Project start date: Till ${filters.projectStartDateTo}`
              )}

            {readinessFields.map((r) => (filters[r] !== '' ? showFiltertag(r, filters[r]) : ''))}
          </div>

          {checkForAppliedFilters() ? (
            <button className="btn-outline btn-outline--red" onClick={clearFilter}>
              Clear all
            </button>
          ) : (
            ''
          )}
        </div>
      )}
      <div className="flex justify-center">
        <div className="btn-toggle-wrapper flex label-2">
          <button
            className={`btn-toggle btn-transition ${showView === 'table' ? 'active' : ''}`}
            onClick={() => setView('table')}
          >
            <i className="material-icons md-18 mr-6">grid_on</i>
            {language.table}
          </button>
          <button
            className={`btn-toggle btn-transition ${showView === 'map' ? 'active' : ''}`}
            onClick={() => setView('map')}
          >
            <i className="material-icons md-18 mr-6">map</i>
            {language.map}
          </button>
        </div>
      </div>

      {showView === 'table' ? (
        <div className="project-list-container">
          {isLoading || projectData.projects === null ? (
            <LoaderNP />
          ) : projectData.projects && projectData.projects.length > 0 ? (
            <>
              {/* {programMsg && (
                <p className="text-right" style={{ paddingRight: '15px' }}>
                  {programMsg}
                </p>
              )} */}
              <Table padded className="project-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} className="id-header  width-8">
                      {language.project_id}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={pagination.column === 'projectName' ? pagination.direction : null}
                      onClick={handleSort('projectName')}
                      width={4}
                    >
                      {language.project_name}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>{language.line_ministry}</Table.HeaderCell>
                    {/* <Table.HeaderCell width={2}> */}
                    {/* {language.agency} */}
                    {/* </Table.HeaderCell> */}
                    <Table.HeaderCell width={2}>{language.department}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {projectData.projects.map((project, index) => {
                    return (
                      // <Table.Row
                      //   key={project.id}
                      //   // onClick={() => {
                      //     //   handleRoute(project.id);
                      //   // }}
                      // >
                      <Link key={project.id} to={`/project/${project.id}`} className="link-row">
                        <Table.Cell className="text-bold project-id">
                          {project.uniqueProjectId}
                        </Table.Cell>
                        <Table.Cell>
                          <p className="project-title">
                            {project.hasPublished ? (
                              <span className="icon-done">
                                <i className="material-icons">done</i>
                              </span>
                            ) : (
                              ''
                            )}{' '}
                            {project.project_name_in_english}
                          </p>
                          <div className="project-metadata">
                            {project.project_status === 'new' ? (
                              <span className="gate-label project-status dark">
                                {project.project_stage === 'appraisal' ? 'new' : ''}
                              </span>
                            ) : (
                              <span className="gate-label project-status dark">
                                {project.project_status}
                              </span>
                            )}
                            {project.moderation_status && (
                              <span
                                className={`project-status ${
                                  project &&
                                  (MODERATION_STATUS[project.moderation_status]
                                    ? MODERATION_STATUS[project.moderation_status]
                                    : MODERATION_STATUS.draft)
                                }`}
                              >
                                {MODERATION_STATUS[project.moderation_status]
                                  ? MODERATION_STATUS[project.moderation_status]
                                  : MODERATION_STATUS.draft}
                              </span>
                            )}
                            {project.parent && (
                              <>
                                <span>
                                  Parent:
                                  {project.parent.project_name_in_english}
                                </span>
                                <span className="divider">|</span>
                              </>
                            )}
                            {project.project_budget_code ? (
                              <>
                                <span>
                                  Budget code:
                                  {project.project_budget_code}
                                </span>
                                <span className="divider">|</span>
                              </>
                            ) : (
                              ''
                            )}
                            {project.program_name && (
                              <>
                                <span
                                  onClick={(e) => {
                                    handleSelect('program_id', {
                                      label: project.program_name,
                                      value: project.program_id,
                                    });
                                    searchProject()(null);
                                  }}
                                >
                                  Program: {project.program_name}
                                </span>
                                <span className="divider">|</span>
                              </>
                            )}
                            {project.ranking ? (
                              <>
                                <span>
                                  Ranking:
                                  {project.ranking}
                                </span>
                                <span className="divider">|</span>
                              </>
                            ) : (
                              ''
                            )}

                            <span>
                              Last updated: {moment(project.updated_at).format('MMM DD, YYYY')}
                            </span>
                          </div>
                        </Table.Cell>
                        <Table.Cell>{project.ministry_name}</Table.Cell>
                        <Table.Cell>
                          <div className="column-action">
                            {project.department_name ? (
                              project.department_name
                            ) : (
                              <span className="not-available">Dept. not available</span>
                            )}
                          </div>
                          <div className="actions">
                            <Can
                              role={user.role}
                              perform="action:edit-delete"
                              data={project.isActionable}
                              yes={() => (
                                <>
                                  <Link
                                    to={`/add/project?projectId=${project.id}&stage=${project.project_stage}`}
                                    onClick={(e) => e.stopPropagation()}
                                    className="btn-outline btn-outline--small btn-outline--blue"
                                  >
                                    <i className="material-icons md-12 mr-2">edit</i> Edit
                                  </Link>{' '}
                                  <span
                                    className="btn-outline btn-outline--small btn-outline--red"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setModatData({
                                        modalOpen: !modalData.modalOpen,
                                        projectId: project.id,
                                      });
                                    }}
                                  >
                                    <i className="material-icons md-12 mr-2 material-delete">
                                      delete_outline
                                    </i>
                                    Delete
                                  </span>
                                </>
                              )}
                              no={() => <></>}
                            />
                          </div>
                        </Table.Cell>
                      </Link>
                      // </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <div className="justify-center">
                <Pagination
                  defaultActivePage={pagination.activePage}
                  onPageChange={handlePaginationChange}
                  totalPages={Math.ceil(projectData.count / 25)}
                  firstItem={{ 'aria-label': 'First item', content: 'First' }}
                  lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
                  prevItem={{
                    'aria-label': 'Previous item',
                    content: 'Previous',
                  }}
                  nextItem={{ 'aria-label': 'First item', content: 'Next' }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="empty">
                <img src={empty} alt="no data found" />
                <p as="p" size="">
                  No projects found.
                  <Can
                    role={user.role}
                    perform="project:create"
                    yes={() => <Link to="/project">Please Add Project</Link>}
                    no={() => ''}
                  />
                </p>
              </div>
            </>
          )}
        </div>
      ) : showView === 'map' ? (
        mapLoading ? (
          <div className="project-list-container">
            <LoaderNP />
          </div>
        ) : (
          <div className="map-container">
            <div className="map-wrapper">
              {' '}
              <ProjectMap />
            </div>
          </div>
        )
      ) : (
        ''
      )}
      <Modal
        dimmer="blurring"
        open={modalData.modalOpen}
        onClose={() => setModatData({ ...modalData, modalOpen: !modalData.modalOpen })}
        size="mini"
      >
        <Modal.Header>Delete program?</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete the project?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => setModatData({ ...modalData, modalOpen: !modalData.modalOpen })}
          >
            Cancel
          </Button>
          <Button
            positive
            content="Yes"
            onClick={(e) => {
              deleteProject(e);
            }}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        open={chartModal.chartModalOpen}
        onClose={() => setChartModal({ ...chartModal, chartModalOpen: !chartModal.chartModalOpen })}
        closeIcon
        className="chart-modal"
      >
        <div className="chart-wrapper bar-chart">
          <div className="bar-chart-header flex justify-between align-center">
            <Header size="tiny">{chartModal.data.title}</Header>
            <div className="no-print">
              <button className="btn-outline btn-outline--blue" onClick={() => window.print()}>
                <i className="material-icons mr-6">print</i>
                Print
              </button>
              <button
                className="btn-outline btn-outline--blue"
                onClick={() => child.current.exportPdf()}
              >
                <i className="material-icons mr-6">cloud_download</i>
                Download graph
              </button>
            </div>
          </div>
          <PieChartLegend
            ref={child}
            data={chartModal.data.data}
            colors={chartModal.data.colors}
            title={chartModal.data.title}
          />
        </div>
      </Modal>
    </>
  );
};
export default ProjectList;
