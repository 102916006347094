import React, { useState } from 'react';
import { Form, Input, Dropdown } from 'semantic-ui-react';

import { handleInputChange, handleSelectChange, handleRepeaterChange } from '../../../../actions';
import { useEffect } from 'react';
import { preventArrowKeys } from '../../../../helpers';
import { numberToWords } from '../../../../helpers/numberToWords';
import { toastr } from 'react-redux-toastr';
import formatNumber from '../../../../helpers/formatNumber';

const CurrencyField = ({
  costLabel,
  costName,
  exchangeRateLabel,
  exchangeRateName,
  costInWordsLabel,
  costInWordsName,
  formInfo = {},
  dispatch,
  fetched,
  field,
  index,
  required = false,
  error = false,
  mainField,
  amountWordRequired = true,
  disabled = false,
}) => {
  const [errorFields, setError] = useState();
  const [costInWords, setCostInWords] = useState('');

  const [maxLimit] = useState(1000000000000);

  const getFormValue = (key) => {
    if (index >= 0) {
      if (mainField) {
        return formInfo[mainField]?.[field]?.[index]?.[key] || '';
      }
      return formInfo[field]?.[index]?.[key] || '';
    }
    return formInfo[field]?.[key] || formInfo[key] || '';
  };

  useEffect(() => {
    setError(error);
  }, [error]);

  // Render default value
  useEffect(() => {
    const costValue = getFormValue(costInWordsName);
    setCostInWords(costValue || '');
  }, [formInfo, index, mainField, field, costInWordsName]);

  // Update costName field if value changed
  useEffect(() => {
    const costValue = getFormValue(costName);
    if (costValue) {
      setCostInWords(numberToWords(costValue));
    } else {
      setCostInWords('');
    }
  }, [formInfo, costName, index, mainField, field]);

  const checkCurrency = () => {
    if (index >= 0 && !mainField) {
      return Object.keys(formInfo[field][index]).length === 0
        ? false
        : formInfo[field][index][`${costName}Currency`] &&
            formInfo[field][index][`${costName}Currency`] !== 'npr';
    } else {
      if (!mainField && field) {
        return Object.keys(formInfo[field]).length === 0
          ? false
          : formInfo[field][`${costName}Currency`] &&
              formInfo[field][`${costName}Currency`] !== 'npr';
      }
      if (mainField) {
        return Object.keys(formInfo[mainField][field][index]).length === 0
          ? false
          : formInfo[mainField][field][index][`${costName}Currency`] &&
              formInfo[mainField][field][index][`${costName}Currency`] !== 'npr';
      }
      return formInfo[`${costName}Currency`] !== 'npr';
    }
  };

  const className = (name) => {
    if (index >= 0) {
      if (
        typeof errorFields === 'object' &&
        Object.keys(errorFields).length > 0 &&
        Object.keys(errorFields).includes(`${name}_${index}_error`) &&
        errorFields[`${name}_${index}_error`]
      ) {
        return 'error field-align-bottom input-with-select';
      }
      if (typeof errorFields !== 'object' && errorFields) {
        return 'error field-align-bottom input-with-select';
      }
      return 'field-align-bottom input-with-select';
    } else {
      if (
        typeof errorFields === 'object' &&
        Object.keys(errorFields).length > 0 &&
        Object.keys(errorFields).includes(name) &&
        errorFields[name]
      ) {
        return 'error field-align-bottom input-with-select';
      }
      if (typeof errorFields !== 'object' && errorFields) {
        return 'error field-align-bottom input-with-select';
      }
      return 'field-align-bottom input-with-select';
    }
  };

  const checkDropDownVal = () => {
    const defaultVal =
      index >= 0
        ? mainField
          ? formInfo[mainField][field][index][`${costName}Currency`]
          : formInfo[field][index][`${costName}Currency`]
        : field
        ? formInfo[field][`${costName}Currency`]
        : formInfo[`${costName}Currency`];

    return defaultVal ? defaultVal : 'npr';
  };

  const handleChange = (value) => {
    const words = numberToWords(value);
    setCostInWords(words);
  };

  const handleKeyDown = (e) => {
    const { value } = e.target;
    const numericValue = parseFloat(value + e.key) || 0;

    if (numericValue > maxLimit && !isNaN(e.key)) {
      e.preventDefault();
      toastr.error('Error', `Value cannot exceed ${formatNumber(maxLimit)}`);
    }
  };

  return (
    <>
      <Form.Field
        control={Input}
        label={costLabel}
        width={8}
        className={className(costName)}
        required={required}
      >
        <Input
          label={
            <Dropdown
              value={checkDropDownVal()}
              name={`${costName}Currency`}
              options={[
                { key: 'usd', text: 'usd', value: 'usd' },
                { key: 'npr', text: 'npr', value: 'npr' },
              ]}
              onChange={(event, { value, name }) => {
                index >= 0
                  ? mainField
                    ? dispatch(
                        handleSelectChange(
                          {
                            value,
                            name,
                            info: formInfo,
                            field: mainField,
                            subField: field,
                            index: index,
                            currencyField: { costName, exchangeRateName, costInWordsName },
                          },
                          fetched
                        )
                      )
                    : dispatch(
                        handleSelectChange(
                          {
                            value,
                            name,
                            info: formInfo,
                            field: field,
                            index: index,
                            currencyField: { costName, exchangeRateName, costInWordsName },
                          },
                          fetched
                        )
                      )
                  : field
                  ? dispatch(
                      handleSelectChange(
                        {
                          value,
                          name,
                          info: formInfo,
                          field: field,
                          currencyField: { costName, exchangeRateName, costInWordsName },
                        },
                        fetched
                      )
                    )
                  : dispatch(
                      handleSelectChange(
                        {
                          value,
                          name,
                          info: formInfo,
                          currencyField: { costName, exchangeRateName, costInWordsName },
                        },
                        fetched
                      )
                    );
              }}
            />
          }
          // className={className()}
          type="number"
          onKeyDown={(e) => {
            preventArrowKeys(e);
            handleKeyDown(e);
          }}
          labelPosition="left"
          placeholder="Eg: 30000"
          name={costName}
          disabled={disabled}
          onChange={(event) => {
            const value = event.target.value;
            handleChange(value);

            index >= 0
              ? mainField
                ? dispatch(
                    handleRepeaterChange(
                      {
                        event,
                        info: formInfo,
                        subField: field,
                        index: index,
                        field: mainField,
                      },
                      fetched
                    )
                  )
                : dispatch(
                    handleRepeaterChange(
                      {
                        event,
                        info: formInfo,
                        field: field,
                        index: index,
                      },
                      fetched
                    )
                  )
              : field
              ? dispatch(handleInputChange({ event, info: formInfo, field: field }, fetched))
              : dispatch(handleInputChange({ event, info: formInfo }, fetched));

            index >= 0
              ? setError({ ...errorFields, [`${costName}_${index}_error`]: false })
              : setError({ ...errorFields, [`${costName}`]: false });
          }}
          value={
            index >= 0
              ? mainField
                ? formInfo[mainField][field][index][costName] || ''
                : formInfo[field][index][costName] || ''
              : field
              ? formInfo[field][costName] || ''
              : formInfo[costName] || ''
          }
        />
      </Form.Field>

      {checkCurrency() && (
        <Form.Field
          type="number"
          onKeyDown={preventArrowKeys}
          control={Input}
          required={required}
          label={exchangeRateLabel}
          placeholder="Eg: 80"
          width={4}
          className={className(exchangeRateName)}
          name={exchangeRateName}
          onChange={(event) => {
            index >= 0
              ? mainField
                ? dispatch(
                    handleRepeaterChange(
                      {
                        event,
                        info: formInfo,
                        subField: field,
                        index: index,
                        field: mainField,
                      },
                      fetched
                    )
                  )
                : dispatch(
                    handleRepeaterChange(
                      {
                        event,
                        info: formInfo,
                        field: field,
                        index: index,
                      },
                      fetched
                    )
                  )
              : field
              ? dispatch(handleInputChange({ event, info: formInfo, field: field }, fetched))
              : dispatch(handleInputChange({ event, info: formInfo }, fetched));
            index >= 0
              ? setError({ ...errorFields, [`${exchangeRateName}_${index}_error`]: false })
              : setError({ ...errorFields, [`${exchangeRateName}`]: false });
          }}
          value={
            index >= 0
              ? mainField
                ? formInfo[mainField][field][index][`${costName}ExchangeRate`] || ''
                : formInfo[field][index][`${costName}ExchangeRate`] || ''
              : field
              ? formInfo[field][`${costName}ExchangeRate`] || ''
              : formInfo[`${costName}ExchangeRate`] || ''
          }
        />
      )}

      <Form.Field
        required={required && amountWordRequired}
        control={Input}
        label={costInWordsLabel}
        placeholder="Eg: Thirty thousand only"
        width={4}
        disabled
        className={className(costInWordsName)}
        name={costInWordsName}
        value={costInWords}
      />
    </>
  );
};

export default CurrencyField;
