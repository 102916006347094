import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Dropdown } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import FileUpload from '../FileUpload';
import { localization } from '../../../../localization';
import { handleSaveAndExit, handleSelectChange } from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/Justification/action';
import * as ProjectAction from '../../../../reducers/form/action';

const Justification = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage]['justification'];
  const dispatch = useDispatch();
  const { justification } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  useEffect(() => {
    if (projectInfo.justification) {
      dispatch(fetched({ ...projectInfo.justification }));
    }
    if (!projectInfo.justification) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(justification);
    delete data['step'];
    handleSaveAndExit({ justification: data }, type, dispatch, 12, urlParams);
  };

  const justificationTypes = [
    { key: '', text: 'Select a Justification type', value: '' },
    { key: 'extended', text: 'Extended', value: 'extended' },
    { key: 'merged', text: 'Merged', value: 'merged' },
    { key: 'phased_out', text: 'Phased Out', value: 'phased_out' },
  ];

  return (
    <Form className="form-new">
      <div className="form-group-container">
        <Form.Group>
          <Form.Field width={8}>
            <label>{lang.please_select}</label>
            <Dropdown
              search
              selection
              placeholder="Select a Justification type"
              options={justificationTypes}
              name="justification"
              value={justification.justification}
              onChange={(event, { name, value }) => {
                dispatch(
                  handleSelectChange(
                    {
                      value,
                      name,
                      info: justification,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Field>
        </Form.Group>
        {justification.justification && (
          <FileUpload
            formName={'justification'}
            label={justification.justification}
            fileData={justification.justificationFile}
            name="justificationFile"
            form={justification}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        )}
      </div>

      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 8 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default Justification;
