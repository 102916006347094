import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Form, Grid, Header, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import 'react-circular-progressbar/dist/styles.css';
import { useUrlSearchParams } from 'use-url-search-params';

import UserService from '../../services/User';
import MinistryService from '../../services/Ministry';
import DepartmentService from '../../services/Department';

import { auth } from '../../helpers';

import { localization } from '../../localization';

import Can from '../../components/Can/can';
import PageNotFound from '../PageNotFound';

const AddUser = ({ history }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language].users;

  const iconStyle = {
    position: 'absolute',
    top: '50%',
    bottom: 13,
    margin: 'auto',
    right: '2.5em',
    lineHeight: 0,
    zIndex: 1,
    display: 'inline-flex',
    alignItems: 'center',
    transform: 'translateX(-50%)',
  };

  const [userDetail, setUserDetail] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    password: '',
    role: '',
    status: 'active',
    ministry_id: 0,
    department_id: null,
    confirm_password: '',
  });

  const [errObj, setValidationError] = useState({
    first_name: false,
    last_name: false,
    username: false,
    email: false,
    role: false,
    password: false,
    ministry: false,
    password_match: false,
    password_not_match: false,
    confirm_password: false,
  });

  const [params] = useUrlSearchParams();

  const [ministries, setMinistries] = useState([]);
  const [departments, setDepartments] = useState([]);

  const user = useSelector(auth.getCurrentUser);

  const roleList =
    user.role === 'npcAdmin'
      ? [
          {
            key: 'dataEntry',
            text: 'Data Entry',
            value: 'dataEntry',
          },
          {
            key: 'dataApprover',
            text: 'Data Verifier', // was Data Approver now Data Verifier. same permissions, different name
            value: 'dataApprover',
          },
          {
            key: 'ministryAdmin',
            text: 'Ministry Admin/Data Approver', // was Ministry Admin
            value: 'ministryAdmin',
          },
          {
            key: 'dataViewer',
            text: 'Data Viewer',
            value: 'dataViewer',
          },
        ]
      : [
          {
            key: 'dataEntry',
            text: 'Data Entry',
            value: 'dataEntry',
          },
          {
            key: 'dataApprover',
            text: 'Data Verifier', // was Data Approver
            value: 'dataApprover',
          },
        ];

  useEffect(() => {
    if (params && params.userId) {
      getUserDetail();
    }

    if (user.role === 'npcAdmin') {
      listMinistries();
    } else {
      listDepartments({ ministryId: user.ministry.id });
      setUserDetail((userDetail) => {
        return { ...userDetail, ministry_id: user.ministry.id };
      });
    }
  }, [params, user]);

  const getUserDetail = () => {
    UserService.getUser(params)
      .then((response) => {
        if (response.success) {
          const u = response.data.user;
          listDepartments({ ministryId: u.ministry_id });
          setUserDetail({
            ...userDetail,
            first_name: u.first_name,
            last_name: u.last_name,
            username: u.username,
            email: u.email,
            password: '',
            role: u.role,
            status: u.status,
            ministry_id: u.ministry_id,
            department_id: u.department_id,
            confirm_password: '',
          });
        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        history.push('/dashboard');
        toastr.error('', `${err.message}`);
      });
  };

  const listMinistries = () => {
    MinistryService.listAllMinistries(`?isDashboard=true&`)
      .then((response) => {
        const ministries = [];
        response.data.ministries.lists.forEach((m) => {
          ministries.push({ key: m.id, text: m.name, value: m.id });
        });
        setMinistries(ministries);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const listDepartments = (params) => {
    DepartmentService.listDepartmentByMinistry(params)
      .then((response) => {
        const departmentList = [];
        let filteredList = [];
        response.data.department.forEach((m) => {
          departmentList.push({ key: m.id, text: m.name, value: m.id, ministry_id: m.ministry_id });
        });
        if (user.role === 'npcAdmin') {
          filteredList = departmentList.filter(
            (depart) => depart.ministry_id === params.ministryId
          );
        }
        user.role === 'npcAdmin' ? setDepartments(filteredList) : setDepartments(departmentList);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const userAction = () => {
    let sendUser = true;
    const obj = { ...errObj };

    if (userDetail.first_name.trim() === '') {
      obj.first_name = true;
      sendUser = false;
    }

    if (userDetail.last_name.trim() === '') {
      obj.last_name = true;
      sendUser = false;
    }

    if (userDetail.username.trim() === '') {
      obj.username = true;
      sendUser = false;
    }

    if (userDetail.email.trim() === '') {
      obj.email = true;
      sendUser = false;
    }

    if (params && !params.userId && userDetail.password.trim() === '') {
      obj.password = true;
      sendUser = false;
    }
    if (params && !params.userId && userDetail.confirm_password.trim() === '') {
      obj.confirm_password = true;
      sendUser = false;
    }

    if (
      params &&
      !params.userId &&
      (userDetail.password !== '' || userDetail.confirm_password !== '')
    ) {
      if (userDetail.password.trim() !== userDetail.confirm_password.trim()) {
        sendUser = false;
        obj.password_match = false;
        obj.password_not_match = true;
      }
    }

    if (userDetail.role.trim() === '') {
      obj.role = true;
      sendUser = false;
    }

    if (userDetail.ministry_id <= 0) {
      obj.ministry = true;
      sendUser = false;
    }

    if (userDetail.department_id === 0) {
      userDetail.department_id = null;
    }
    if (
      params &&
      params.userId &&
      (userDetail.password !== '' || userDetail.confirm_password !== '')
    ) {
      if (userDetail.password.trim() !== userDetail.confirm_password.trim()) {
        sendUser = false;
        obj.password_match = false;
        obj.password_not_match = true;
      }
    }

    setValidationError(obj);

    if (sendUser) {
      if (params && params.userId) {
        UserService.updateUser({ userId: params.userId }, userDetail)
          .then((response) => {
            if (response.success) {
              toastr.success('', `${response.message}`);
              history.push('/users');
            } else {
              throw new Error(response.error.message);
            }
          })
          .catch((err) => {
            toastr.error('', `${err.message}`);
          });
      } else {
        UserService.createUser(userDetail)
          .then((response) => {
            if (response.success) {
              toastr.success('', `${response.message}`);
              history.push('/users');
            } else {
              throw new Error(response.error.message);
            }
          })
          .catch((err) => {
            toastr.error('', `${err.message}`);
          });
      }
    }
  };

  return (
    <Can
      role={user.role}
      perform="user:create"
      yes={() => (
        <>
          <Grid columns="equal">
            <Grid.Column className="form-container">
              <Container text className="add-project-form">
                {params && params.userId ? (
                  <Header size="medium">{lang.edit_user}</Header>
                ) : (
                  <Header size="medium">{lang.add_user}</Header>
                )}
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field required>
                      <label>{lang.first_name}</label>
                      <Form.Input
                        value={userDetail.first_name}
                        onChange={(e) => {
                          setUserDetail({ ...userDetail, first_name: e.target.value });
                          setValidationError({ ...errObj, first_name: false });
                        }}
                        error={
                          errObj.first_name
                            ? {
                                content: lang.first_name_required,
                                pointing: 'below',
                              }
                            : null
                        }
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>{lang.last_name}</label>
                      <Form.Input
                        value={userDetail.last_name}
                        onChange={(e) => {
                          setUserDetail({ ...userDetail, last_name: e.target.value });
                          setValidationError({ ...errObj, last_name: false });
                        }}
                        error={
                          errObj.last_name
                            ? {
                                content: lang.last_name_required,
                                pointing: 'below',
                              }
                            : null
                        }
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field required>
                      <label>{lang.username}</label>
                      <Form.Input
                        value={userDetail.username}
                        onChange={(e) => {
                          setUserDetail({ ...userDetail, username: e.target.value });
                          setValidationError({ ...errObj, username: false });
                        }}
                        error={
                          errObj.username
                            ? {
                                content: lang.username_required,
                                pointing: 'below',
                              }
                            : null
                        }
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>{lang.email}</label>
                      <Form.Input
                        value={userDetail.email}
                        onChange={(e) => {
                          setUserDetail({ ...userDetail, email: e.target.value });
                          setValidationError({ ...errObj, email: false });
                        }}
                        error={
                          errObj.email
                            ? {
                                content: lang.email_required,
                                pointing: 'below',
                              }
                            : null
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  {params && params.userId ? (
                    <></>
                  ) : (
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>{lang.password}</label>
                        <Form.Input
                          value={userDetail.password}
                          type="password"
                          onChange={(e) => {
                            setUserDetail({ ...userDetail, password: e.target.value });

                            if (userDetail.confirm_password.trim() === e.target.value.trim()) {
                              setValidationError({ ...errObj, password_match: true });
                            } else {
                              setValidationError({
                                ...errObj,
                                password_match: false,
                                password: false,
                              });
                            }
                          }}
                          error={
                            errObj.password
                              ? {
                                  content: lang.password_required,
                                  pointing: 'below',
                                }
                              : null
                          }
                          icon={
                            errObj.password_match && userDetail.password.length > 0 && 'checkmark'
                          }
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>{lang.confirm_password}</label>
                        <Form.Input
                          value={userDetail.confirm_password}
                          type="password"
                          onChange={(e) => {
                            setUserDetail({ ...userDetail, confirm_password: e.target.value });
                            if (userDetail.password.trim() === e.target.value.trim()) {
                              setValidationError({ ...errObj, password_match: true });
                            } else {
                              setValidationError({
                                ...errObj,
                                password_match: false,
                                confirm_password: false,
                              });
                            }
                          }}
                          icon={
                            errObj.password_match && userDetail.password.length > 0
                              ? 'checkmark'
                              : userDetail.password.length !== 0 && 'cancel'
                          }
                          error={
                            errObj.confirm_password
                              ? {
                                  content: lang.password_required,
                                  pointing: 'below',
                                }
                              : null
                          }
                        />
                      </Form.Field>
                    </Form.Group>
                  )}

                  <Form.Group>
                    {user.role !== 'npcAdmin' ? (
                      <Form.Field width={8} required>
                        <label>{lang.ministry}</label>
                        <input
                          value={user.ministry ? user.ministry.name : lang.no_ministry_associated}
                          disabled
                        />
                      </Form.Field>
                    ) : (
                      <Form.Field width={8} required>
                        <label>{lang.ministry}</label>
                        <Form.Field>
                          <Form.Select
                            options={ministries}
                            value={userDetail.ministry_id}
                            onChange={(e, data) => {
                              setUserDetail({ ...userDetail, ministry_id: data.value });
                              setValidationError({ ...errObj, ministry: false });
                              listDepartments({ ministryId: data.value });
                            }}
                            error={
                              errObj.ministry
                                ? {
                                    content: lang.ministry_required,
                                    pointing: 'below',
                                  }
                                : null
                            }
                            search
                          />
                        </Form.Field>
                      </Form.Field>
                    )}
                    {userDetail.ministry_id !== 0 &&
                      userDetail.ministry_id !== 0 &&
                      userDetail.ministry_id !== 0 && (
                        <Form.Field width={8}>
                          <label>{lang.department}</label>
                          <Form.Field style={{ position: 'relative' }}>
                            {userDetail.department_id && userDetail.department_id > 0 ? (
                              <Icon
                                link
                                name="close"
                                style={iconStyle}
                                onClick={() => {
                                  setUserDetail({ ...userDetail, department_id: 0 });
                                }}
                              />
                            ) : (
                              <></>
                            )}

                            <Form.Select
                              options={departments}
                              value={userDetail.department_id}
                              onChange={(e, data) => {
                                setUserDetail({ ...userDetail, department_id: data.value });
                              }}
                              search
                            />
                          </Form.Field>
                        </Form.Field>
                      )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} required>
                      <label>{lang.role}</label>
                      <Form.Field>
                        {params && +params.userId === +user.id ? (
                          <input value={userDetail.role} disabled />
                        ) : (
                          <Form.Select
                            options={roleList}
                            value={userDetail.role}
                            onChange={(e, data) => {
                              setUserDetail({ ...userDetail, role: data.value });
                              setValidationError({ ...errObj, role: false });
                            }}
                            error={
                              errObj.role
                                ? {
                                    content: lang.role_required,
                                    pointing: 'below',
                                  }
                                : null
                            }
                            search
                          />
                        )}
                      </Form.Field>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    {params &&
                      params.userId &&
                      (user.role === 'npcAdmin' || user.role === 'ministryAdmin') && (
                        <>
                          <Form.Field width={8}>
                            <label>{lang.new_password}</label>
                            <Form.Input
                              value={userDetail.password}
                              type="password"
                              onChange={(e) => {
                                setUserDetail({ ...userDetail, password: e.target.value });
                                if (userDetail.confirm_password.trim() === e.target.value.trim()) {
                                  setValidationError({ ...errObj, password_match: true });
                                } else {
                                  setValidationError({ ...errObj, password_match: false });
                                }
                              }}
                              icon={
                                errObj.password_match &&
                                userDetail.password.length > 0 &&
                                'checkmark'
                              }
                            />
                          </Form.Field>
                          <Form.Field width={8}>
                            <label>{lang.confirm_password}</label>
                            <Form.Input
                              value={userDetail.confirm_password}
                              type="password"
                              onChange={(e) => {
                                setUserDetail({ ...userDetail, confirm_password: e.target.value });
                                if (userDetail.password.trim() === e.target.value.trim()) {
                                  setValidationError({ ...errObj, password_match: true });
                                } else {
                                  setValidationError({ ...errObj, password_match: false });
                                }
                                // setValidationError({...errObj,password_not_match:false})
                              }}
                              icon={
                                errObj.password_match && userDetail.password.length > 0
                                  ? 'checkmark'
                                  : userDetail.password.length !== 0 && 'cancel'
                              }
                            />
                            {errObj.password_not_match && !errObj.password_match ? (
                              <span>Password didn't match</span>
                            ) : (
                              <></>
                            )}
                          </Form.Field>
                        </>
                      )}
                  </Form.Group>

                  <Header size="small" className="header-required">
                    {lang.status}
                  </Header>
                  <Form.Group inline>
                    <Form.Field
                      label="Active"
                      control="input"
                      type="radio"
                      name="isPartOfProgram"
                      value="active"
                      onChange={(e) => {
                        setUserDetail({ ...userDetail, status: e.target.value });
                      }}
                      checked={userDetail.status === 'active'}
                    />
                    <Form.Field
                      label="Blocked"
                      control="input"
                      type="radio"
                      name="isPartOfProgram"
                      value="inactive"
                      onChange={(e) => setUserDetail({ ...userDetail, status: e.target.value })}
                      checked={userDetail.status === 'inactive'}
                    />
                  </Form.Group>
                  <Button primary onClick={userAction}>
                    Save
                  </Button>

                  <Button basic onClick={() => history.push('/users')}>
                    Cancel
                  </Button>
                </Form>
              </Container>
            </Grid.Column>
          </Grid>
        </>
      )}
      no={() => <PageNotFound message={`You can't access this interface`} />}
    />
  );
};

export default withRouter(AddUser);
