import currentYear from '../../../helpers/constant/CurrentYearGate';
const initialState = {
  // isYearlyFunding: '',
  // projectStartDateAD: '',
  // projectStartDateBS: '',
  // estimatedProjectEndDateAD: '',
  // estimatedProjectEndDateBS: '',
  estimatedTime: '',
  projectDescription: '',
  projectDescriptionFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  extendedDeadline: {
    fromDateBS: '',
    fromDateAD: '',
    toDateBS: '',
    toDateAD: ''
  },
  projectCostImplementation: {
    originalCost: '',
    originalCostWord: '',
    originalCostExchangeRate: '',
    originalCostCurrency: 'npr',
  },
  isProjectCostRevise: '',
  revisedCost: '',
  revisedCostExchangeRate: '',
  fiscalYearForRevised: currentYear(),
  revisedCostCurrency: 'npr',
  revisedCostWord: '',
  // revisedCostUSD: '',
  // revisedCostNPR: '',
  revisedDateEstimateAD: '',
  revisedDateEstimateBS: '',
  isBreakOfCost: '',
  summaryOfCost: [
    {
      costHeadingNumber: '',
      costHeading: '',
      fCostComponent: '',
      fCostComponentExchangeRate: '',
      fCostComponentCurrency: 'npr',
      fCostComponentWord: '',
      // totalCost:'',
      // totalCostWord:'',
      // totalCostExchangeRate:'',
      // totalCostCurrency:'npr',
      // nprCostComponent: '',
      // totalCostInNpr: '',
      // nprCostComponentWord: '',
      // totalCostInNprWord: '',
    },
  ],
  summaryCostFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],

  briefEnvironmentalFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  briefEnvironmentalApprovedDateAD: '',
  briefEnvironmentalApprovedDateBS: '',
  preliminaryEnvironmentalFile: [
    { fileName: '', fileDescription: '', fileSize: '', originalName: '' },
  ],
  preliminaryEnvironmentalApprovedDateAD: '',
  preliminaryEnvironmentalApprovedDateBS: '',
  environmentalImpactAssessmentFile: [
    { fileName: '', fileDescription: '', fileSize: '', originalName: '' },
  ],
  environmentalImpactAssessmentApprovedDateAD: '',
  environmentalImpactAssessmentApprovedDateBS: '',
  environmentStudyNotNecessaryFile: [
    { fileName: '', fileDescription: '', fileSize: '', originalName: '' },
  ],

  detailEngineeringReportFile: [
    { fileName: '', fileDescription: '', fileSize: '', originalName: '' },
  ],
  detailProjectReportFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  detailProjectReportStudyBy: '',
  approvedReportFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  approvedReportStudyBy: '',
  studyNotNecessaryFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],

  procurementMasterPlanFile: [
    { fileName: '', fileDescription: '', fileSize: '', originalName: '' },
  ],
  annualProcurementPlanFile: [
    { fileName: '', fileDescription: '', fileSize: '', originalName: '' },
  ],
  actionPlanFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  resultFrameworkFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  directoryFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  operationGuidelinesFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  manualFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  preFeasibility: '',
  preFeasibilityFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  feasibilityStudy: '',
  feasibilityStudyFile: [{ fileName: '', fileDescription: '', fileSize: '', originalName: '' }],
  is_deadline_extended: '',
  projectStartDateBS: '',
  projectStartDateAD: '',
  actualProjectStartDateBS: '',
  actualProjectStartDateAD: '',
  projectCompletionDateBS: '',
  projectCompletionDateAD: '',
  projectedProjectStartDateBS: '',
  projectedProjectStartDateAD: '',
  projectedProjectEndDateBS: '',
  projectedProjectEndDateAD: ''
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };

    case 'SPECIFIC_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_SPECIFIC_FETCHED':
      return initialState;

    default:
      return state;
  }
}
