import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Header, Select,Grid,GridColumn,GridRow,Image } from 'semantic-ui-react';
import { cloneDeep, pick } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { localization } from '../../../../localization';
import { fetched, validation } from '../../../../reducers/form/ModerationStatus/action';
import { handleSelectChange, handleSaveAndExit } from '../../../../actions';
import * as ProjectAction from '../../../../reducers/form/action';
import ProjectApi from '../../../../services/Gate/Project';
import FileUpload from '../FileUpload/index';

const ModerationStatus = ({ urlParams }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage].project_status;
  const project_id_text = localization[selectedLanguage].general_information_view.project_id;

  const { moderationStatus } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);
  const [projectError, setProjectError] = useState({
    moderationStatusFile_error: false,
  });

  useEffect(() => {
    if (projectInfo) {
      const data = {
        ...moderationStatus,
        moderation_status: projectInfo?.moderation_status,
        moderationStatusFile: projectInfo?.moderationStatusFile ?? [
          {
            fileName: '',
            fileDescription: '',
            fileSize: '',
            originalName: '',
          },
        ],
      };

      dispatch(fetched(data));
    }
  }, [projectInfo]);

  const status = [
    { text: 'Save as draft', value: 'draft', key: 'draft' },
    { text: 'Send for verification', value: 'published', key: 'published' },
    { text: 'Send for submission', value: 'approved', key: 'approved' },
    { text: 'Submit', value: 'completed', key: 'completed' ,disabled:user.role !== "npcAdmin"},
  ];

  const handleSaveAndContinue = (type) => {
    const data = cloneDeep(moderationStatus);
    delete data.step;
    const errors = {};
    let canSubmit = true;

    const moderationStatusFile = data?.moderationStatusFile;

    if (
      projectInfo.project_status !== 'study' && moderationStatus.moderation_status === 'approved' &&
      (!moderationStatusFile.length || !moderationStatusFile.some((file) => file?.fileName))
    ) {
      canSubmit = false;
      errors.moderationStatusFile_error = true;
    }

    if (!canSubmit) {
      setProjectError(errors);
      return;
    }

    handleSaveAndExit(
      {
        moderation_status: moderationStatus?.moderation_status,
        ...(moderationStatus?.moderation_status === 'approved' && {
          moderationStatusFile: moderationStatus?.moderationStatusFile,
        }),
      },
      type,
      dispatch,
      14,
      urlParams
    );
  };

  const handleSelect = (name, value) => {
    if (user.role === 'dataEntry' && !['draft', 'published'].includes(value)) {
      toastr.error('Data Entry can save the form as draft or send for verification.');
      return false;
    }

    if (user.role === 'dataApprover' && !['draft', 'approved'].includes(value)) {
      toastr.error('Data Verifier can send the form back to draft or send for submission.');
      return false;
    }

    if (user.role === 'ministryAdmin' && !['draft', 'completed'].includes(value)) {
      toastr.error(
        'Ministry Admin/Data Approver can send the form back to draft or submit the form.'
      );
      return false;
    }

    if (
      user.role === 'npcAdmin' &&
      projectInfo.hasPublished &&
      projectInfo.moderation_status === 'completed'
    ) {
      toastr.error(
        '',
        `Assessment form was already approved for this project. Please re-do assessment to change its moderation status.`
      );
    } else if (value === 'published' || value === 'completed' || value === 'approved') {
      const dataToValidate = cloneDeep(projectInfo);
      dataToValidate.stage = dataToValidate.sent_for_appraisal ? 'appraisal' : 'identification';
      const META_DATA = [
        'general_information',
        'project_specific',
        'progress',
        'project_readiness',
        'basis_of_project_selection',
        'project_prioritization_score',
        'project_implementation_modality',
        'project_financing_arrangement',
        'project_viability',
        'land_acquisition_status',
        'justification',
        'contact',
        'remarks',
        'stage',
      ];

      const metaObject = pick(dataToValidate, META_DATA);
      ProjectApi.validateProjectData({ projectId: projectInfo.id }, {...metaObject,project_status:projectInfo.project_status}).then((result) => {
        if (result.data.errorForms.length > 0) {
          dispatch(validation({ ...moderationStatus, errorForms: result.data.errorForms }));
          toastr.error(
            '',
            'Some of the required fields are empty. Please fill up those fields to send for verification'
          );
        } else {
          dispatch(
            handleSelectChange(
              {
                value,
                name,
                info: moderationStatus,
              },
              fetched
            )
          );
        }
      });
    } else {
      dispatch(
        handleSelectChange(
          {
            value,
            name,
            info: moderationStatus,
          },
          fetched
        )
      );
    }

    return true;
  };

  const checklistRows = [
    { id: 1, text: 'project_brief_environmental_assessment_report_(bea)_or_initial_environmental_examination_report_(iee)_or_environmental_impact_assessment_report_(eia)' },
    { id: 2, text: 'estimating_cost_of_a_project' },
    { id: 3, text: 'project_detailed_engineering_report_(der)_or_detailed_project_report_(dpr)_or_project_proposal_approved_report' },
    { id: 4, text: 'land_acquisition_plan' },
    { id: 5, text: 'procurement_plan' },
    { id: 6, text: 'implementation_work_plan' },
    { id: 7, text: 'result_framework' },
    { id: 8, text: 'monitoring_and_evaluation_plan' },
  ];

  const TableRows = () => {
    return checklistRows.map(({ id, text }) => (
      <tr key={id}>
        <td>{id}</td>
        <td>{lang[text]}</td>
        <td />
        <td />
        <td />
      </tr>
    ));
  };

  const NewTableRows  = () => {
    return [1,2,3].map(({id}) => (
      <tr key={id} className='row'>
        <td>{id}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    ))
  }

  return (
    <Form className="form-new">
      <div className="form-group-container print-hidden">
        <Form.Group>
          <Form.Field
            label={lang.form_name}
            control={Select}
            width={8}
            name="moderation_status"
            options={status}
            required
            value={moderationStatus.moderation_status}
            onChange={(event, { name, value }) => {
              handleSelect(name, value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Header>{lang.note}:</Header>
          <ul className="notes-list">
            <li
              dangerouslySetInnerHTML={{
                __html: lang.draft_text,
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: lang.needs_approval_text,
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: lang.approved_text,
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: lang.submitted_text,
              }}
            />
          </ul>
        </Form.Group>
      </div>

      {projectInfo.project_status !== 'study' && moderationStatus?.moderation_status === 'approved' && (
        <>
          <div className="form-group-container p-0">
            <div className="project-info-header">
              <div>
                <p>
                  <b>{lang.project_name} : </b>
                  {projectInfo?.project_name_in_nepali}
                </p>
                <p>
                  <b>{lang.ministry_name} : </b>
                  {projectInfo?.ministry?.name}
                </p>
                <p>
                  <b>{project_id_text} : </b>
                  {projectInfo?.gate_id}
                </p>
                <p>
                  <b>{lang.please_mark_the_appropriate_box}</b>
                </p>
              </div>
              <div className="print-hidden">
                <button
                  type="button"
                  className="btn-transition btn-outline btn-outline--blue"
                  onClick={() => window.print()}
                  >
                  <i className="material-icons mr-6">cloud_download</i>
                  Download
                </button>
              </div>
            </div>

            <table className="checklist-table">
              <thead>
                <tr>
                  <th>{lang.serial_no}</th>
                  <th>{lang.description}</th>
                  <th>{lang.available} </th>
                  <th>{lang.not_available}</th>
                  <th>{lang.remarks}</th>
                </tr>
              </thead>
              <tbody>
                <TableRows />
              </tbody>
            </table>

            {/* New table for sn 1 & 3 as new document */}
            <p>
              <b>{lang.disclose_header_for_institute}</b>
            </p>
            <table className="checklist-table">
              <thead>
                <tr>
                  <th>{lang.name_of_report}</th>
                  <th>{lang.approving_authority}</th>
                  <th>{lang.position_of_approver} </th>
                  <th>{lang.approved_date}</th>
                </tr>
              </thead>
              <tbody>
              <NewTableRows />
              </tbody>
            </table>

           <div className='form-container'>
             <Grid columns={3} className="p-0">
             <GridRow>
                <GridColumn>
                  <div className='flex-col'>
                    <div>
                     <u><b>{lang.signature_prepare} :</b></u> 
                     </div>
                    <div><b>{lang.name} : </b></div>
                    <div><b>{lang.position} : </b></div>
                  </div>
                </GridColumn>
                <GridColumn>
                  <div className='flex-col'>
                    <div>
                     <u><b>{lang.signature_subscriber} : </b></u> 
                     </div>
                    <div><b>{lang.name} : </b></div>
                    <div><b>{lang.position} : </b></div>
                  </div>
                </GridColumn>
                <GridColumn>
                  <div className='flex-col'>
                    <div>
                     <u><b>{lang.signature_verify}:</b></u> 
                     </div>
                    <div><b>{lang.name} : </b></div>
                    <div><b>{lang.position} : </b></div>
                  </div>
                </GridColumn>
              
              </GridRow>
             </Grid>
           </div>
          </div>

          <div className="form-group-container print-hidden">
            <FileUpload
              required
              formName="project_status"
              label="upload_document"
              fetched={fetched}
              name="moderationStatusFile"
              fileData={moderationStatus?.moderationStatusFile}
              form={moderationStatus}
              projectId={urlParams.projectId}
              error={projectError ? projectError[`moderationStatusFile_error`] : false}
            />
          </div>
        </>
      )}

      <div className="form-actions flex print-hidden">
        <button
          type="submit"
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          type="submit"
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 13 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default ModerationStatus;
