const N2WIndian = (() => {
  const zeroTo99 = [];
  const place = 'Thousand|Lakh|Crore|Arab|Kharab|Nil'.split('|');

  const initialize = () => {
    const ones =
      '|One|Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Eleven|Twelve|Thirteen|Fourteen|Fifteen|Sixteen|Seventeen|Eighteen|Nineteen'.split(
        '|'
      );
    const tens = '||Twenty|Thirty|Forty|Fifty|Sixty|Seventy|Eighty|Ninety'.split('|');

    for (let i = 0; i < 100; i++) {
      const t = Math.floor(i / 10);
      const o = i % 10;
      zeroTo99.push(t < 2 ? ones[i] : tens[t] + (o ? ' ' + ones[o] : ''));
    }
  };

  const convert = (x) => {
    let n = x.length;
    x = n === 0 ? '00' : n === 1 || n % 2 === 0 ? '0' + x : x;
    n = x.length;
    let r = zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
    if (n >= 1) {
      const v = zeroTo99[x.charCodeAt((n -= 1)) - 48];
      if (v) r = v + ' Hundred' + (r ? ' ' + r : '');
    }
    for (let i = 0; n > 0; i++) {
      const v = zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
      if (v) r = v + ' ' + place[i] + (r ? ' ' + r : '');
    }
    return r || '';
  };

  initialize();

  return {
    convert,
    initialize,
  };
})();

export const numberToWords = (num) => {
  if (isNaN(num) || num === '') return ''; 
  return N2WIndian.convert(num.toString());
};
