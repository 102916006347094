import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';
import $ from 'jquery';

import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/ic_calendar.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg';
import { handleNepaliDateChange, handleRadioChange } from '../../../../actions';

const DatePickerComponent = ({
  lang,
  label,
  name,
  formName,
  fetched,
  field = null,
  changeField = null,
  error = false,
  formSection
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    import('../../../../nepali-date-picker/jquery.nepaliDatePicker').then(() => {
      $('.datePicker').nepaliDatePicker({
        dateFormat: '%D, %M %d, %y',
        closeOnDateSelect: true,
      });
    });

    $('.datePicker').on('dateSelect', function (event) {
      let selectedNepaliDate = `${event.datePickerData.bsYear}/${event.datePickerData.bsMonth}/${event.datePickerData.bsDate}`;
      if (event.target.id === name) {
        dispatch(
          handleNepaliDateChange(
            {
              info: formName,
              value: event.target.value,
              name: name,
              field: field,
              changeField: changeField,
              selectedNepaliDate: selectedNepaliDate,
            },
            fetched
          )
        );
      }
    });
  }, []);

  // useEffect(()=>{
  //   $(`#${name}`).on('dateSelect', function (event) {
  //     console.log('in useEffect of select')
  //     let selectedNepaliDate = `${event.datePickerData.bsYear}/${event.datePickerData.bsMonth}/${event.datePickerData.bsDate}`;
  //     if (event.target.id === name) {
  //       dispatch(
  //         handleNepaliDateChange(
  //           {
  //             info: formName,
  //             value: event.target.value,
  //             name: name,
  //             field: field,
  //             changeField: changeField,
  //             selectedNepaliDate: selectedNepaliDate,
  //           },
  //           fetched
  //         )
  //       );
  //     }
  //   });
  // },[name])

  // useEffect(() => {

  // }, [name])

  const dateRef = useRef(null);

  const { projectSpecific } = useSelector((state) => state);

  const removeDateVal = () => {
    if (dateRef && dateRef.current && dateRef.current.value) {
      dateRef.current.value = null
    }

    if (name === 'estimateDateBS') {
      dispatch(
        handleRadioChange(
          {
            info: formSection,
            value: {...projectSpecific.projectCostImplementation, estimateDateAD: null, estimateDateBS: null},
            name: 'projectCostImplementation'
          },
          fetched
        )
      )
    } else {
      dispatch(
        handleRadioChange(
          {
            info: formSection,
            value: null,
            name,
          },
          fetched
        )
      );
    }

  }

  $('.datePicker').on('dateSelect', function (event) {
    let selectedNepaliDate = `${event.datePickerData.bsYear}/${event.datePickerData.bsMonth}/${event.datePickerData.bsDate}`;
    if (event.target.id === name) {
      dispatch(
        handleNepaliDateChange(
          {
            info: formName,
            value: event.target.value,
            name: name,
            field: field,
            changeField: changeField,
            selectedNepaliDate: selectedNepaliDate,
          },
          fetched
        )
      );
    }
  });

  const activeValue = field ? formName[field][name] : formName[name];
  
  return (
    <>
      {/* Form field date picker start */}
      <Form.Field
        width={4}
        className={
          error
            ? 'error position-relative field-align-bottom'
            : 'date-picker-nepali position-relative field-align-bottom'
        }
      >
        <label>{lang[label]}</label>
        <i className="ic-calendar">
          <CalendarIcon className="datePicker" id={name} />
        </i>
        {/* Add cross icon here */}
        {/* {
          // (dateRef && dateRef.current && dateRef.current.value)
          (activeValue)?
          <i className="ic-remove-date" onClick={removeDateVal}>
            <CloseIcon className="remove-date" id={name} />
          </i>: ''
        } */}
        <input
          type="text"
          name={'labelforDate.fieldBS'}
          onChange={(e) => e}
          value={field ? formName[field][name] : formName[name]}
          className="datePicker"
          id={name}
          ref={dateRef}
        />
      </Form.Field>
      {/* Form field date picker end */}
    </>
  );
};

export default DatePickerComponent;
