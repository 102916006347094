import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid, Divider, Table } from 'semantic-ui-react';
import { get } from 'lodash';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';

const ProjectFinancingSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['financing_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  const modalityType =
    projectInfo?.project_appraisal?.project_implementation_modality?.projectImplementationModalType;

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  return (
    <div name="project-financing">
      <div className="project-section" id="project-financing">
        <div className="header medium">
          {projectInfo.project_status === 'new' && gate === 'project_identification'
            ? lang.form_name_alternate
            : lang.section_name}
        </div>
        <Tab.Pane>
          {modalityType === 'publicGON' && (
            <>
              {/* Government of Nepal Budget */}
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <h3 className="font-500">{lang.government_budget}</h3>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{lang.federal}</div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.budget_amount} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(
                        projectData,
                        'project_financing_arrangement.governmentBudget.federal.budgetAmount'
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.budget_amount_words} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(
                        projectData,
                        'project_financing_arrangement.governmentBudget.federal.budgetAmountInWords'
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>

                {/* <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.province}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.budget_amount} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(
                    projectData,
                    'project_financing_arrangement.governmentBudget.province.budgetAmount'
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.budget_amount_words} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(
                    projectData,
                    'project_financing_arrangement.governmentBudget.province.budgetAmountInWords'
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.local_level}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.budget_amount} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(
                    projectData,
                    'project_financing_arrangement.governmentBudget.localLevel.budgetAmount'
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.budget_amount_words} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(
                    projectData,
                    'project_financing_arrangement.governmentBudget.localLevel.budgetAmountInWords'
                  )}
                </p>
              </Grid.Column>
            </Grid.Row> */}
              </Grid>

              <Divider />

              {/* Loan */}
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <h3 className="font-500">{lang.loan}</h3>
                  </Grid.Column>
                </Grid.Row>
                {/* Internal loan section  */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{lang.internal_loan}</div>
                  </Grid.Column>
                </Grid.Row>
                {projectData?.project_financing_arrangement?.loans?.internalLoan?.length > 0 &&
                  projectData?.project_financing_arrangement?.loans?.internalLoan?.map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.name} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'name')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.budget_amount} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'budgetAmount')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.budget_amount_words} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'budgetAmountInWords')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        {index !==
                          projectData?.project_financing_arrangement?.loans?.internalLoan?.length -
                            1 && <Divider />}
                      </React.Fragment>
                    )
                  )}
                <Divider />
                {/* External loan section  */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{lang.external_loan}</div>
                  </Grid.Column>
                </Grid.Row>
                {projectData?.project_financing_arrangement?.loans?.externalLoan?.length > 0 &&
                  projectData?.project_financing_arrangement?.loans?.externalLoan?.map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.name} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'name')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.budget_amount} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'budgetAmount')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.budget_amount_words} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'budgetAmountInWords')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        {index !==
                          projectData?.project_financing_arrangement?.loans?.externalLoan?.length -
                            1 && <Divider />}
                      </React.Fragment>
                    )
                  )}
              </Grid>

              <Divider />

              {/* Foreign Grants */}
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{lang.foreign_grant}</div>
                  </Grid.Column>
                </Grid.Row>
                {projectData?.project_financing_arrangement?.foreignGrants?.length > 0 &&
                  projectData?.project_financing_arrangement?.foreignGrants?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{lang.name} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{get(item, 'name')}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{lang.budget_amount} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{get(item, 'budgetAmount')}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{lang.budget_amount_words} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{get(item, 'budgetAmountInWords')}</p>
                        </Grid.Column>
                      </Grid.Row>
                      {index !==
                        projectData?.project_financing_arrangement?.foreignGrants?.length - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))}
              </Grid>

              <Divider />

              {/* Others */}
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{lang.others}</div>
                  </Grid.Column>
                </Grid.Row>
                {projectData?.project_financing_arrangement?.others?.length > 0 &&
                  projectData?.project_financing_arrangement?.others?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{lang.name} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{get(item, 'name')}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{lang.budget_amount} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{get(item, 'budgetAmount')}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{lang.budget_amount_words} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{get(item, 'budgetAmountInWords')}</p>
                        </Grid.Column>
                      </Grid.Row>
                      {index !== projectData?.project_financing_arrangement?.others?.length - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))}
              </Grid>
            </>
          )}

          {modalityType === 'ppp' && (
            <>
              {/* Public-Private Partnership Projects */}
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <h3 className="font-500">{lang.ppp_projects}</h3>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{lang.public_source}</div>
                  </Grid.Column>
                </Grid.Row>
                {projectData?.project_financing_arrangement?.publicPrivatePartnership?.publicSource
                  ?.length > 0 &&
                  projectData?.project_financing_arrangement?.publicPrivatePartnership?.publicSource?.map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.name} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'name')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.budget_amount} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'budgetAmount')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.budget_amount_words} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'budgetAmountInWords')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        {index !==
                          projectData?.project_financing_arrangement?.publicPrivatePartnership
                            ?.publicSource?.length -
                            1 && <Divider />}
                      </React.Fragment>
                    )
                  )}
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{lang.private_source}</div>
                  </Grid.Column>
                </Grid.Row>
                {projectData?.project_financing_arrangement?.publicPrivatePartnership?.privateSource
                  ?.length > 0 &&
                  projectData?.project_financing_arrangement?.publicPrivatePartnership?.privateSource?.map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.name} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'name')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.budget_amount} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'budgetAmount')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={5}>
                            <p>{lang.budget_amount_words} :</p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="project-info">{get(item, 'budgetAmountInWords')}</p>
                          </Grid.Column>
                        </Grid.Row>
                        {index !==
                          projectData?.project_financing_arrangement?.publicPrivatePartnership
                            ?.privateSource?.length -
                            1 && <Divider />}
                      </React.Fragment>
                    )
                  )}
              </Grid>
            </>
          )}
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ProjectFinancingSection;
