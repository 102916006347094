import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid,Divider } from 'semantic-ui-react';
import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { auth,getDate,API_URL } from '../../../helpers';
import { projectStatus } from '../../../helpers/constant/ProjectStatus';
import { localization } from '../../../localization';
import ProjectApi from '../../../services/Gate/Project';
import formatNumber from '../../../helpers/formatNumber';

const GeneralInformationSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['general_information_view'];
  const projectSpecificLang = localization[language]['project_specific_view'];
  const generalInfoLang = localization[language]['general_information'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();
  const [sectors, setSectors] = useState([]);
  const [locations, setLocations] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  useEffect(() => {
    fetchLocation();
    fetchSectors();
  }, []);
  useEffect(() => {
    selectedSector();
    selectedLocation();
  }, []);
  const categorization = get(projectData, 'general_information.categorization');

  let categorizationContent;

  switch (categorization) {
    case 'belowThree':
      categorizationContent = 'Below 3 years';
      break;

    case 'threeToFive':
      categorizationContent = '3-5 years';
      break;

    case 'fiveToSeven':
      categorizationContent = '5-7 years';
      break;

    case 'sevenToTen':
      categorizationContent = '7-10 years';
      break;

    case 'moreThanTen':
      categorizationContent = 'More than 10 years';
      break;

    default:
      break;
  }

  const priorities = [
    {
      key: 'nationalPrideProject',
      text: 'National Pride Project',
      value: 'nationalPrideProject',
    },
    { key: 'priority1', text: 'Priority 1', value: 'priority1' },
    { key: 'priority2', text: 'Priority 2', value: 'priority2' },
    { key: 'gameChanger', text: 'Game Changer', value: 'gameChanger' },
    { key: 'others', text: 'Other', value: 'others' },
  ];
  const projectType = [
    {
      key: 'serviceOriented',
      text: 'Service Oriented',
      value: 'serviceOriented',
    },
    { key: 'construction', text: 'Construction', value: 'construction' },
    { key: 'production', text: 'Production', value: 'production' },
    { key: 'trade', text: 'Trade', value: 'trade' },
    { key: 'others', text: 'Other', value: 'others' },
  ];

  const fetchSectors = () => {
    ProjectApi.getAllsectors()
      .then((response) => {
        if (response.success) {
          setSectors(response.data.sectorList);
        }
      })
      .catch((err) => toastr.error('', err.message));
  };

  const fetchLocation = () => {
    ProjectApi.getAllLocation()
      .then((response) => {
        if (response.success) {
          let districts = [];
          for (let d of response.data.districts) {
            districts.push({
              key: d.id,
              text: d.name,
              value: d.id,
              parent_id: d.parent_id,
            });
          }
          setLocations({ provinces: response.data.provinces, districts });
        }
      })
      .catch((err) => toastr.error('', err.message));
  };

  const selectedSector = () => {
    let sectorId = projectData && projectData.general_information.sectors;
    let sectorData = [];
    sectors.filter((sector) => {
      sectorId.filter((id) => {
        if (+id === sector.value) sectorData.push(sector);
      });
    });
    return sectorData;
  };
  const selectedLocation = () => {
    let provinceId = projectData && projectData.general_information.provinces;
    let districtId = projectData && projectData.general_information.districts;
    let municipalityId = projectData && projectData.general_information.municipalities;

    let provinceData = [];
    let districtData = [];
    let municipalityData = [];

    locations &&
      locations.provinces.filter((locate) => {
        provinceId.filter((id) => {
          if (+id === +locate.id) provinceData.push(locate);
        });
      });
    locations &&
      locations.districts.filter((locate) => {
        districtId.filter((id) => {
          if (+id === +locate.key) districtData.push(locate);
        });
        municipalityId.filter((id) => {
          if (+id === +locate.key) municipalityData.push(locate);
        });
      });

    return { provinceData, districtData, municipalityData };
  };

  const getFormattedValue = (filteringArray, value) => {
    let filteredVal = filteringArray.filter((type) => value === type.value);
    if (filteredVal.length > 0) {
      return filteredVal[0].text;
    }
  };

  return (
    <div name="general-information">
      <div className="project-section" id="general-information">
        <div className="header medium">{lang.section_name}</div>
        <Tab.Pane>
          <Grid columns={2}>
            {/*Line Ministry … Implementing Agency */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_id} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'uniqueProjectId')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.line_ministry} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'ministry.name')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.implementing_agency} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'department.name')}</p>
              </Grid.Column>
            </Grid.Row>

            {/* Project Name En .. project Name Ne …. Project budget code  */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_name_in_english} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'project_name_in_english')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_name_in_nepali} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'project_name_in_nepali')}</p>
              </Grid.Column>
            </Grid.Row>
            {projectInfo?.project_status === 'ongoing' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <p>{lang.project_budget_code} :</p>
                </Grid.Column>
                <Grid.Column>
                  <p className="project-info">
                    {get(projectInfo, 'project_budget_code')
                      ? formatNumber(get(projectInfo, 'project_budget_code'))
                      : ''}
                  </p>
                </Grid.Column>
              </Grid.Row>
            )}

            {/* Location */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.region} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'general_information.location_type')}
                </p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'general_information.location_type') === 'National' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.project_province} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {projectData &&
                        projectData.general_information &&
                        selectedLocation().provinceData.map((province, index) => {
                          return (
                            <span key={index} className="general-array">
                              {province.name}
                            </span>
                          );
                        })}
                    </p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.project_district} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {projectData &&
                        projectData.general_information &&
                        selectedLocation().districtData.map((district, index) => {
                          return (
                            <span key={index} className="general-array">
                              {district.text}
                            </span>
                          );
                        })}
                    </p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.project_municipality} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {projectData &&
                        projectData.general_information &&
                        selectedLocation().municipalityData.map((municipality, index) => {
                          return (
                            <span key={index} className="general-array">
                              {municipality.text}
                            </span>
                          );
                        })}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{'Country'} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'general_information.country')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{'Address'} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'general_information.address')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            {/* Project Period */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{'Project Period'} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'general_information.projectPeriod')}
                </p>
              </Grid.Column>
            </Grid.Row>

            {/* Priority of Project */}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.priority_of_project} :</p>
              </Grid.Column>
              <Grid.Column>
                {get(projectData, 'general_information.projectPriority') === 'others' ? (
                  <p
                    className="project-info"
                    dangerouslySetInnerHTML={{
                      __html: get(projectData, 'general_information.projectPriorityOther'),
                    }}
                  />
                ) : (
                  <p className="project-info">
                    {getFormattedValue(
                      priorities,
                      get(projectData, 'general_information.projectPriority')
                    )}
                  </p>
                )}
              </Grid.Column>
            </Grid.Row>

            {/* Project Type */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_type} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'general_information.projectType')}
                </p>
              </Grid.Column>
            </Grid.Row>

            {/* Sector */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.sector} :</p>
              </Grid.Column>

              <div className="project-info">
                {projectData?.general_information &&
                  selectedSector().map((sector, sectorIndex) => (
                    <div key={sectorIndex}>
                      <p>{sector.label}</p>
                      <ul className="sub-sector">
                        {projectData.general_information.subSector
                          .map((subSector, subSectorIndex) => {
                            const filteredSector = sector.subSector.find(
                              (subSectorId) => +subSectorId.value === +subSector
                            );

                            if (!filteredSector) return null;

                            return (
                              <li key={subSectorIndex}>
                                {filteredSector.label}
                                {projectData.general_information.othersText?.[
                                  filteredSector.value
                                ] && (
                                  <span>{` (${
                                    projectData.general_information.othersText[filteredSector.value]
                                  })`}</span>
                                )}
                              </li>
                            );
                          })
                          .filter(Boolean)}
                      </ul>
                    </div>
                  ))}
              </div>
            </Grid.Row>

            {/* Project Status */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_status} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {projectStatus.includes(get(projectInfo, 'project_status')) && get(projectInfo, 'project_status')}
                </p>
              </Grid.Column>
            </Grid.Row>
            {projectInfo.project_status === 'new' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <p>{lang.stage} :</p>
                </Grid.Column>
                <Grid.Column>
                  <p className="project-info">
                    {projectInfo && projectInfo.project_status === 'ongoing'
                      ? ''
                      : projectInfo.sent_for_appraisal
                      ? 'Appraisal'
                      : 'Identification'}
                  </p>
                </Grid.Column>
              </Grid.Row>
            )}

            {/* Part of Program */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.is_project_part_of_program} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'part_of_program')}</p>
              </Grid.Column>
            </Grid.Row>
            {projectInfo.part_of_program === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.program_name} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{get(projectInfo, 'program.name')}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.program_budget_code} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectInfo, 'program.program_budget_code')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}

            {/* Parent Project info */}
            {projectInfo.parent_id && (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.parent_name} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectInfo, 'gate.project_name_in_english')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.parent_budget_code} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectInfo, 'gate.project_budget_code')
                        ? formatNumber(get(projectInfo, 'gate.project_budget_code'))
                        : ''}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            {/* This section is for project study  */}
            {
            projectInfo.project_status === 'study' && (
          <>
            <Grid.Row>
              <Grid.Column>
                <div className="header small">{projectSpecificLang.project_implementation_cost}</div>
              </Grid.Column>
            </Grid.Row>

            {/* Project Cost for Implementation */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>
                  {/* {lang.original_cost} ({lang.usd}) : */}
                  {generalInfoLang.estimated_cost_for_study} :
                </p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'general_information.projectCostImplementation.originalCost')}
                </p>
              </Grid.Column>
            </Grid.Row>
          {/* study completion date  */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{generalInfoLang.estimated_cost_for_study_word}:</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'general_information.projectCostImplementation.originalCostWord')}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{generalInfoLang.study_date} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {getDate(
                    get(projectData, 'general_information.studyCompletionDateAD') ?? '',
                    get(projectData, 'general_information.studyCompletionDateBS') ?? ''
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>

            {/* document section */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.ministry_documents} :</p>
              </Grid.Column>
              <Grid.Column>
                  <div className="project-info file-download">
                    {projectData?.general_information?.ministryFile?.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                    })}
                  </div>
              </Grid.Column>
            </Grid.Row>     
            
            {/* study matters  */}
            <Grid.Row>
                  <Grid.Column>
                    <div className="header small">Study Matters</div>
                  </Grid.Column>
                </Grid.Row>
                {projectData?.general_information?.studyMatters?.length > 0 &&
                  projectData?.general_information?.studyMatters?.map(
                    (item, index) => (
                        <Grid.Row key={index}>
                          {/* <Grid.Column width={5}>
                            <p>{generalInfoLang.study_matter_name} :</p>
                          </Grid.Column> */}
                          <Grid.Column>
                            <li className="project-info">{get(item, 'studyMatterName')}</li>
                          </Grid.Column>
                        </Grid.Row>
                     
                    )
                  )}
          </>
          )}
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default GeneralInformationSection;
