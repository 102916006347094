const initialState = {
  governmentBudget: {
    federal: {
      budgetAmount: '',
      budgetAmountInWords: '',
    },
    province: {
      budgetAmount: '',
      budgetAmountInWords: '',
    },
    localLevel: {
      budgetAmount: '',
      budgetAmountInWords: '',
    },
  },
  loans: {
    internalLoan: [
      {
        name: '',
        budgetAmount: '',
        budgetAmountInWords: '',
      },
    ],
    externalLoan: [
      {
        name: '',
        budgetAmount: '',
        budgetAmountInWords: '',
      },
    ],
  },
  foreignGrants: [
    {
      name: '',
      budgetAmount: '',
      budgetAmountInWords: '',
    },
  ],
  others: [
    {
      name: '',
      budgetAmount: '',
      budgetAmountInWords: '',
    },
  ],
  publicPrivatePartnership: {
    publicSource: [
      {
        name: '',
        budgetAmount: '',
        budgetAmountInWords: '',
      },
    ],
    privateSource: [
      {
        name: '',
        budgetAmount: '',
        budgetAmountInWords: '',
      },
    ],
  },
};

const mergeArraysOrDefault = (apiArray, defaultArray) => {
  if (!apiArray || !Array.isArray(apiArray) || apiArray.length === 0) {
    return defaultArray;
  }
  return apiArray;
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};

    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };

    case 'FINANCING_ARRANGEMENT_FETCHED':
      return {
        ...initialState,
        ...payload,
        governmentBudget: {
          ...initialState.governmentBudget,
          ...payload?.governmentBudget,
        },
        loans: {
          ...initialState.loans,
          ...payload?.loans,
          internalLoan: mergeArraysOrDefault(
            payload?.loans?.internalLoan,
            initialState.loans.internalLoan
          ),
          externalLoan: mergeArraysOrDefault(
            payload?.loans?.externalLoan,
            initialState.loans.externalLoan
          ),
        },
        foreignGrants: mergeArraysOrDefault(payload?.foreignGrants, initialState.foreignGrants),
        others: mergeArraysOrDefault(payload?.others, initialState.others),
        publicPrivatePartnership: {
          ...initialState.publicPrivatePartnership,
          ...payload?.publicPrivatePartnership,
          publicSource: mergeArraysOrDefault(
            payload?.publicPrivatePartnership?.publicSource,
            initialState.publicPrivatePartnership.publicSource
          ),
          privateSource: mergeArraysOrDefault(
            payload?.publicPrivatePartnership?.privateSource,
            initialState.publicPrivatePartnership.privateSource
          ),
        },
      };

    case 'NULL_FINANCING_ARRANGEMENT_FETCHED':
      return initialState;

    default:
      return state;
  }
}
