/* eslint-disable no-redeclare */
/* global process */
import * as authHelper from './AuthHelper';
import moment from 'moment';

export const call = (handler, ...data) => {
  if (handler) {
    handler(...data);
  }
};

export const auth = authHelper;
export const user = authHelper;
export const API_URL = process.env.REACT_APP_API_URL;
export const EXTERNAL_API_URL = process.env.REACT_APP_EXTERNAL_API_URL;

export const preventArrowKeys = (e) => {
  if (
    e.key === 'ArrowUp' ||
    e.key === 'ArrowDown' ||
    e.key === 'e' ||
    e.key === 'E' ||
    e.key === '+' ||
    e.key === '-'
  ) {
    e.preventDefault();
  }
};

export const getDate = (engDate, nepDate) => {
  if (engDate && nepDate) {
    return `${moment(engDate).format('YYYY-MM-DD')} (${nepDate})`;
  }
};
