import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid, Divider } from 'semantic-ui-react';
import { get } from 'lodash';


import { API_URL, auth,getDate } from '../../../helpers';
import { localization } from '../../../localization';

const ProjectSpecificSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['project_specific_view'];
  const langSpecific = localization[language]['specific'];
  const langProjectReadinessView = localization[language]['project_readiness_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);


  const extendedDeadline = get(projectData, 'project_specific.extendedDeadline');
  const summaryOfCost = get(projectData, 'project_specific.summaryOfCost', []);

  const DesignedBy = ({ fileType, studyBy }) => {
    return (
      <Grid.Row>
        <Grid.Column width={5}>
          <p>
            {fileType} {langSpecific.designed_by} :
          </p>
        </Grid.Column>
        <Grid.Column>
          <p>{get(projectData, `project_specific.${studyBy}`, '')}</p>
        </Grid.Column>
      </Grid.Row>
    );
  };

  const ApprovedDate = ({ fileType, AD, BS }) => {
    return (
      <Grid.Row>
        <Grid.Column width={5}>
          <p>
            {fileType} {langSpecific.approved_date} :
          </p>
        </Grid.Column>
        <Grid.Column>
          <p>
            {getDate(
              get(projectData, `project_specific.${AD}`) ?? '',
              get(projectData, `project_specific.${BS}`) ?? ''
            )}
          </p>
        </Grid.Column>
      </Grid.Row>
    );
  };

  if (!projectData) return null;

  return (
    <div name="project-section">
      <div className="project-section" id="project-section">
        <div className="header medium">{lang.section_name}</div>
        <Tab.Pane>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.project_implementation_cost}</div>
              </Grid.Column>
            </Grid.Row>

            {/* Project Cost for Implementation */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>
                  {/* {lang.original_cost} ({lang.usd}) : */}
                  {lang.original_cost} :
                </p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_specific.projectCostImplementation.originalCost')}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{'Project Cost Estimate In Word'} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_specific.projectCostImplementation.originalCostWord')}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{langSpecific.project_start_date} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {getDate(
                    get(projectData, 'project_specific.projectStartDateAD') ?? '',
                    get(projectData, 'project_specific.projectStartDateBS') ?? ''
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{langSpecific.actual_project_start_date} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {getDate(
                    get(projectData, 'project_specific.actualProjectStartDateAD') ?? '',
                    get(projectData, 'project_specific.actualProjectStartDateBS') ?? ''
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{langSpecific.project_completion_date} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {getDate(
                    get(projectData, 'project_specific.projectCompletionDateAD') ?? '',
                    get(projectData, 'project_specific.projectCompletionDateBS') ?? ''
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>

            {/*  project cost revised */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.is_project_cost_revised} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_specific.isProjectCostRevise')}
                </p>
              </Grid.Column>
            </Grid.Row>

            {get(projectData, 'project_specific.isProjectCostRevise') === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.revised_cost_estimated} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_specific.revisedCost')}
                    </p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.revised_cost_estimated_word}:</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_specific.revisedCostWord')}
                    </p>
                  </Grid.Column>
                </Grid.Row>

                {get(projectData, 'project_specific.revisedCostCurrency') === 'usd' && (
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>{lang.revised_usd_exchange_rate} :</p>
                    </Grid.Column>
                    <Grid.Column>
                      <p className="project-info">
                        {get(projectData, 'project_specific.revisedCostExchangeRate')}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.revised_date_estimate} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {getDate(
                        get(projectData, 'project_specific.revisedDateEstimateAD') ?? '',
                        get(projectData, 'project_specific.revisedDateEstimateBS') ?? ''
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.revised_cost_fiscal_year} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_specific.fiscalYearForRevised')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}
            <Divider />
            {/* Extended Deadline */}
            <Grid.Row>
              <Grid.Column>
                <div className="header small">{langSpecific.extended_deadline_date}</div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{langSpecific.from_date} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {extendedDeadline
                    ? `${getDate(extendedDeadline.fromDateAD, extendedDeadline.fromDateBS) ?? ''}`
                    : ''}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{langSpecific.to_date} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {extendedDeadline
                    ? `${getDate(extendedDeadline.toDateAD, extendedDeadline.toDateBS) ?? ''}`
                    : ''}
                </p>
              </Grid.Column>
            </Grid.Row>

            {/* Break down */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{langSpecific.breakdown} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'project_specific.isBreakOfCost')}</p>
              </Grid.Column>
            </Grid.Row>

            {/* Summary of Cost Under Major Headings */}
            {get(projectData, 'project_specific.isBreakOfCost') === 'Yes' && (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">
                      {langSpecific.summary_of_cost_under_major_headings}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                {summaryOfCost &&
                  summaryOfCost.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{langSpecific.cost_heading_number} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{item.costHeadingNumber}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{langSpecific.cost_headings} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{item.costHeading}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{langSpecific.cost_usd} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{item.fCostComponent}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{langSpecific.cost_usd_word} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">{item.fCostComponentWord}</p>
                        </Grid.Column>
                      </Grid.Row>
                    </React.Fragment>
                  ))}
              </>
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{'Summary of cost'} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData?.project_specific?.summaryCostFile?.map((item, index) => {
                    return (
                      Object.keys(item).length !== 0 && (
                        <p key={index}>
                          {item.originalName ? item.originalName : item.fileName}
                          {item.fileName.length > 0 && (
                            <>
                              {item.fileSize ? <span>({item.fileSize})</span> : ''}
                              <a
                                href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                target="_blank"
                                download
                                rel="noopener noreferrer"
                              >
                                (Download)
                              </a>
                            </>
                          )}
                        </p>
                      )
                    );
                  })}
                </div>
              </Grid.Column>
            </Grid.Row>

            {projectInfo.project_status === 'new' && (
              <>
                {/* Project Feasibility Study */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{langSpecific.project_feasibility_study}</div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.pre_feasibility_study} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{get(projectData, 'project_specific.preFeasibility')}</p>
                  </Grid.Column>
                </Grid.Row>

                {get(projectData, 'project_specific.preFeasibility') === 'Yes' && (
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>{'Documents related to pre feasibility study'} :</p>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="project-info file-download">
                        {projectData?.project_specific?.preFeasibilityFile?.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.feasibility_study} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{get(projectData, 'project_specific.feasibilityStudy')}</p>
                  </Grid.Column>
                </Grid.Row>

                {get(projectData, 'project_specific.feasibilityStudy') === 'Yes' && (
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>{'Documents related to feasibility study'} :</p>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="project-info file-download">
                        {projectData?.project_specific?.feasibilityStudyFile?.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                )}

                {/* Study */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{langSpecific.study}</div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.detail_engineering_report} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.detailEngineeringReportFile?.map(
                        (item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        }
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.detail_project_report} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.detailProjectReportFile?.map(
                        (item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        }
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <DesignedBy
                  studyBy="detailProjectReportStudyBy"
                  fileType={langSpecific.detail_project_report}
                />

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langProjectReadinessView.project_proposal} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.approvedReportFile?.map((item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName : item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                  {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                  <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <DesignedBy
                  studyBy="approvedReportStudyBy"
                  fileType={langProjectReadinessView.project_proposal}
                />

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.not_necessary} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.studyNotNecessaryFile?.map((item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName : item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                  {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                  <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      })}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                {/* Environmental Study Report */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{langSpecific.environmental_study_report}</div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.brief_environmental_study_report} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.briefEnvironmentalFile?.map((item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName : item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                  {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                  <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <ApprovedDate
                  fileType={langSpecific.brief_environmental_study_report}
                  AD="briefEnvironmentalApprovedDateAD"
                  BS="briefEnvironmentalApprovedDateBS"
                />

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.preliminary_environmental_test_report} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.preliminaryEnvironmentalFile?.map(
                        (item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        }
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <ApprovedDate
                  fileType={langSpecific.preliminary_environmental_test_report}
                  AD="preliminaryEnvironmentalApprovedDateAD"
                  BS="preliminaryEnvironmentalApprovedDateBS"
                />

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.environmental_impact_assessment} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.environmentalImpactAssessmentFile?.map(
                        (item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        }
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <ApprovedDate
                  fileType={langSpecific.environmental_impact_assessment}
                  AD="environmentalImpactAssessmentApprovedDateAD"
                  BS="environmentalImpactAssessmentApprovedDateBS"
                />

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.not_necessary} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.environmentStudyNotNecessaryFile?.map(
                        (item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        }
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                {/* Purchase plan prepared */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{langSpecific.purchase_plan_prepared}</div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.procurement_master_plan} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.procurementMasterPlanFile?.map(
                        (item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        }
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                {/* Annual procurement plan */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{langSpecific.annual_procurement}</div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.annual_procurement} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.annualProcurementPlanFile?.map(
                        (item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        }
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                {/* Implementation action plan prepared */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">
                      {langSpecific.implementation_action_plan_prepared}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.action_plan} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.actionPlanFile?.map((item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName : item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                  {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                  <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      })}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                {/* Result blueprint prepared */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">{langSpecific.result_blueprint_prepared}</div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.result_framework} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.resultFrameworkFile?.map((item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName : item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                  {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                  <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      })}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                {/* Modality of project implementation has been prepared */}
                <Grid.Row>
                  <Grid.Column>
                    <div className="header small">
                      {langSpecific.modality_of_project_implementation_has_been_prepared}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.directory} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.directoryFile?.map((item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName : item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                  {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                  <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      })}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.operation_guidelines} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.operationGuidelinesFile?.map(
                        (item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        }
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{langSpecific.manual} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData?.project_specific?.manualFile?.map((item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName : item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                  {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                  <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ProjectSpecificSection;
