const PROJECT_STATUS = {
  'Save as Draft': 'draft',
  'Sent to Verification': 'verification',
  'Sent to Submission': 'approved',
  Submitted: 'submitted',
};

const projectStatus = ['ongoing', 'new', 'parent', 'study'];

module.exports = { PROJECT_STATUS, projectStatus };
