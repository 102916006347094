import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Radio, Select, Input } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import { localization } from '../../../../localization';
import { auth } from '../../../../helpers';
import Editor from '../CkEditor/index';
import fiscalYear from '../data/fiscalYear';
import CurrencyField from '../CurrencyField';
import FileUpload from '../FileUpload/index';
import {
  addRepeaterField,
  handleRadioChange,
  handleSelectChange,
  handleEditorChange,
  handleRepeaterChange,
  removeRepeaterField,
  handleSaveAndExit,
  handleInputChange,
  repeaterFieldOptimise,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/ProjectSpecific/action';
import * as ProjectAction from '../../../../reducers/form/action';

import DatePicker from '../DatePicker';
import NepaliDatePicker from '../DatePicker/NepaliDatePicker';
import DatePickerComponent from '../DatePicker';
import ConditionalRadioButton from './ConditionalRadioButton';
import { numberToWords } from '../../../../helpers/numberToWords';

const ProjectSpecific = ({ urlParams, projectStatus = 'ongoing' }) => {
  const dispatch = useDispatch();
  const language = useSelector(auth.getSelectedLanguage);

  const lang = localization[language].specific;
  const { projectSpecific } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  useEffect(() => {
    if (projectInfo.project_specific) {
      dispatch(
        fetched({
          ...projectSpecific,
          ...projectInfo.project_specific,
        })
      );
    }
    if (!projectInfo.project_specific) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const [projectError, setProjectError] = useState({
    isYearlyFunding_error: false,
    projectStartDateAD_error: false,
    projectStartDateBS_error: false,
    estimatedProjectEndDateAD_error: false,
    estimatedProjectEndDateBS_error: false,
    originalCost_error: false,
    originalCostWord_error: false,
    originalCostExchangeRate_error: false,
    originalCostCurrency_error: false,
    revisedCost_error: false,
    revisedCostCurrency_error: false,
    revisedCostWord_error: false,
    revisedCostExchangeRate_error: false,
  });

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(projectSpecific);
    if (data.revisedCost) {
      data.revisedCostWord = numberToWords(data.revisedCost);
    }

    data.summaryOfCost = data.summaryOfCost.map((item) => {
      if (item.fCostComponent) {
        item.fCostComponentWord = numberToWords(item.fCostComponent);
      }
      return item;
    });

    delete data['step'];
    const requiredFields = ['isYearlyFunding'];
    const regexCheckNumberFields = [
      'costHeadingNumber',
      'originalCost',
      'originalCostExchangeRate',
      'revisedCost',
      'revisedCostExchangeRate',
      'fCostComponent',
      'fCostComponentExchangeRate',
      'totalCost',
      'totalCostExchangeRate',
    ];
    const regexCheckWordFields = [
      'originalCostWord',
      'revisedCostWord',
      'totalCostWord',
      'fCostComponentWord',
    ];
    const dependentFields = [
      'projectStartDateAD',
      'projectStartDateBS',
      'estimatedProjectEndDateAD',
      'estimatedProjectEndDateBS',
    ];
    const errors = {};
    let canSubmit = true;
    errors[`revisedCost_error`] = {};
    for (let d in data) {
      if (data['projectStartDateAD'] && data['estimatedProjectEndDateAD']) {
        let start = new Date(data['projectStartDateAD']);
        let end = new Date(data['estimatedProjectEndDateAD']);

        if (start > end) {
          errors['projectStartDateAD_error'] = true;
          errors['estimatedProjectEndDateAD_error'] = true;
          errors['projectStartDateBS_error'] = true;
          errors['estimatedProjectEndDateBS_error'] = true;
        }
      }
      if (
        requiredFields.includes(d) &&
        (data[d] === '' || data[d].length === 0) &&
        d !== 'projectCostImplementation'
      ) {
        errors[`${d}_error`] = true;
        canSubmit = false;
      }
      if (d === 'isYearlyFunding' && data[d] === 'No' && urlParams.stage === 'appraisal') {
        dependentFields.map((field) => {
          if (!data[field] || data[field] === '') {
            errors[`${field}_error`] = true;
            canSubmit = false;
          }
        });
      }
      if (d === 'projectCostImplementation') {
        errors[`${d}_error`] = {};
        for (let pCost in data[d]) {
          let regexNumber = /^[+]?\d+(\.\d+)?$/;
          let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;
          if (
            data[d][pCost] !== '' &&
            regexCheckNumberFields.includes(pCost) &&
            !regexNumber.test(data[d][pCost])
          ) {
            errors[`${d}_error`][`${pCost}`] = true;
            canSubmit = false;
          }
          if (
            data[d][pCost] !== '' &&
            regexCheckWordFields.includes(pCost) &&
            !regexWord.test(data[d][pCost])
          ) {
            errors[`${d}_error`][`${pCost}`] = true;
            canSubmit = false;
          }
        }
      }

      if (d === 'revisedCost' || d === 'revisedCostExchangeRate' || d === 'revisedCostWord') {
        let regexNumber = /^[+]?\d+(\.\d+)?$/;
        let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;
        if (regexCheckNumberFields.includes(d) && !regexNumber.test(data[d]) && data[d] !== '') {
          errors[`revisedCost_error`][`${d}`] = true;
          canSubmit = false;
        }
        if (data[d] !== '' && regexCheckWordFields.includes(d) && !regexWord.test(data[d])) {
          errors[`revisedCost_error`][`${d}`] = true;
          canSubmit = false;
        }
      }
      if (d === 'summaryOfCost') {
        errors[`${d}_error`] = {};
        for (let i = 0; i < data[d].length; i++) {
          for (let sCost in data[d][i]) {
            let regexNumber = /^[+]?\d+(\.\d+)?$/;
            let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;
            if (
              data[d][i][sCost] !== '' &&
              regexCheckNumberFields.includes(sCost) &&
              !regexNumber.test(data[d][i][sCost])
            ) {
              sCost !== 'costHeadingNumber'
                ? (errors[`${d}_error`][`${sCost}_${i}_error`] = true)
                : (errors[`${sCost}_${i}_error`] = true);
              canSubmit = false;
            }
            if (
              data[d][i][sCost] !== '' &&
              regexCheckWordFields.includes(sCost) &&
              !regexWord.test(data[d][i][sCost])
            ) {
              errors[`${d}_error`][`${sCost}_${i}_error`] = true;
              canSubmit = false;
            }
          }
        }
      }
    }

    // logic to require in any one file
    const validateRequiredFiles = (fields, data, errors) => {
      const isValid = fields.some((field) => data[field]?.[0]?.fileName !== '');
      if (!isValid) {
        canSubmit = false;
        fields.forEach((field) => {
          errors[`${field}_error`] = true;
        });
      }
    };

    const requiredAnyOneFile = [
      'briefEnvironmentalFile',
      'preliminaryEnvironmentalFile',
      'environmentalImpactAssessmentFile',
      'environmentStudyNotNecessaryFile',
    ];

    const studyFileFields = [
      'detailEngineeringReportFile',
      'detailProjectReportFile',
      'approvedReportFile',
      'studyNotNecessaryFile',
    ];
    validateRequiredFiles(requiredAnyOneFile, data, errors);
    validateRequiredFiles(studyFileFields, data, errors);
    // validate files
    const requiredUploadFiles = ['actionPlanFile', 'resultFrameworkFile'];
    requiredUploadFiles.forEach((item) => {
      const isValid = data[item].some((item) => item?.fileName !== '');
      if (!isValid) {
        canSubmit = false;
        errors[`${item}_error`] = true;
      }
    });

    const isValidStudyFiles = studyFileFields
      .map((item) => data[item][0].fileName === '')
      .some((item) => item === false);
    if (!isValidStudyFiles) {
      studyFileFields.forEach((item) => {
        errors[`${item}_error`] = true;
      });
    }

    setProjectError(errors);
    if (canSubmit) {
      data.stage = urlParams.stage;
      let newProjectSpecific = repeaterFieldOptimise(data, 'summaryOfCost', [
        {
          costHeadingNumber: '',
          costHeading: '',
          fCostComponent: '',
          fCostComponentExchangeRate: '',
          fCostComponentCurrency: 'npr',
          fCostComponentWord: '',
        },
      ]);
      data.summaryOfCost = [...newProjectSpecific];
      handleSaveAndExit(
        { project_specific: data },
        type,
        dispatch,
        projectInfo?.project_status === 'new' ? 10 : 3,
        urlParams
      );
    }
  };

  return (
    <Form className="form-new">
      {urlParams.stage === 'identification' && projectInfo.project_status === 'new' && (
        <div className="form-group-container">
          <Form.Group>
            <Form.Field width={16}>
              <label>{lang.brief_description_salient_feature}</label>
              <Editor
                formData={projectSpecific.projectDescription}
                formInfo={projectSpecific}
                field={false}
                name="projectDescription"
                handleEditorChange={handleEditorChange}
                dispatch={dispatch}
                fetched={fetched}
              />
            </Form.Field>
          </Form.Group>
          <FileUpload
            formName="specific"
            label="upload_documents"
            fileData={projectSpecific.projectDescriptionFile}
            name="projectDescriptionFile"
            form={projectSpecific}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </div>
      )}
      {urlParams.stage === 'identification' && projectInfo.project_status === 'new' && (
        <div className="form-group-container">
          <Form.Group>
            <Form.Field
              control={Input}
              name="estimatedTime"
              width={8}
              className="field-align-bottom"
              label={lang.estimated_time}
              value={projectSpecific.estimatedTime}
              placeholder="Eg: 5 Years"
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      info: projectSpecific,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>
        </div>
      )}

      <div className="form-group-container">
        <p className="form-info">
          {projectInfo.project_status === 'new'
            ? lang.project_cost_implementation
            : lang.project_cost_implementation}
        </p>

        <Form.Group>
          <CurrencyField
            costLabel={
              projectInfo.project_status === 'new'
                ? lang.estimated_cost
                : lang.original_cost_estimate
            }
            costName="originalCost"
            exchangeRateLabel={lang.usd_exchange_rate}
            exchangeRateName="originalCostExchangeRate"
            costInWordsLabel={
              projectInfo.project_status === 'new'
                ? lang.estimated_cost_word
                : lang.original_cost_estimate_word
            }
            costInWordsName="originalCostWord"
            formInfo={projectSpecific}
            dispatch={dispatch}
            fetched={fetched}
            field="projectCostImplementation"
            error={projectError.projectCostImplementation_error}
            disabled={projectInfo?.moderation_status === 'completed'}
          />
        </Form.Group>
      </div>

      {projectInfo?.project_status === 'new' ? (
        <div className="form-group-container">
          <p className="form-info">{lang.project_start_date_end_date}</p>
          <Form.Group>
            <NepaliDatePicker
              lang={lang}
              label="projected_project_start_date_BS"
              name="projectedProjectStartDateBS"
              formName={projectSpecific}
              fetched={fetched}
              changeField="projectedProjectStartDateAD"
            />
            <DatePicker
              lang={lang}
              label="projected_project_start_date_AD"
              name="projectedProjectStartDateAD"
              formName={projectSpecific}
              fetched={fetched}
              changeField="projectedProjectStartDateBS"
            />
          </Form.Group>

          <Form.Group>
            <NepaliDatePicker
              lang={lang}
              label="projected_project_end_date_BS"
              name="projectedProjectEndDateBS"
              formName={projectSpecific}
              fetched={fetched}
              changeField="projectedProjectEndDateAD"
            />
            <DatePicker
              lang={lang}
              label="projected_project_end_date_AD"
              name="projectedProjectEndDateAD"
              formName={projectSpecific}
              fetched={fetched}
              changeField="projectedProjectEndDateBS"
            />
          </Form.Group>
        </div>
      ) : (
        ''
      )}

      <>
        {urlParams.stage !== 'identification' && projectInfo?.project_status === 'ongoing' && (
          <>
            <div className="form-group-container">
              <Form.Group grouped>
                <Form.Field label={lang.is_the_project_cost_revise} />
                <Form.Group inline>
                  <Form.Field
                    label={lang.yes}
                    control={Radio}
                    name="isProjectCostRevise"
                    value="Yes"
                    checked={projectSpecific.isProjectCostRevise === 'Yes'}
                    onChange={(event, { value, name }) => {
                      dispatch(
                        handleRadioChange(
                          {
                            info: projectSpecific,
                            value,
                            name,
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <Form.Field
                    label={lang.no}
                    control={Radio}
                    name="isProjectCostRevise"
                    value="No"
                    checked={projectSpecific.isProjectCostRevise === 'No'}
                    onChange={(event, { value, name }) => {
                      dispatch(
                        handleRadioChange(
                          {
                            info: projectSpecific,
                            value,
                            name,
                          },
                          fetched
                        )
                      );
                    }}
                  />
                </Form.Group>
              </Form.Group>
              {projectSpecific.isProjectCostRevise === 'Yes' && (
                <>
                  <Form.Group>
                    <CurrencyField
                      costLabel={lang.revised_cost_estimated}
                      costName="revisedCost"
                      exchangeRateLabel={lang.usd_exchange_rate}
                      exchangeRateName="revisedCostExchangeRate"
                      costInWordsLabel={lang.revised_cost_estimated_word}
                      costInWordsName="revisedCostWord"
                      formInfo={projectSpecific}
                      dispatch={dispatch}
                      fetched={fetched}
                      field={false}
                      error={projectError.revisedCost_error}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label={lang.fiscal_year}
                      options={fiscalYear}
                      placeholder={lang.fiscal_year}
                      width={4}
                      name="fiscalYearForRevised"
                      onChange={(event, { value, name }) => {
                        dispatch(
                          handleSelectChange(
                            {
                              value,
                              name,
                              info: projectSpecific,
                            },
                            fetched
                          )
                        );
                      }}
                      value={projectSpecific.fiscalYearForRevised}
                    />
                    <NepaliDatePicker
                      lang={lang}
                      label="revised_date_estimate_bs"
                      name="revisedDateEstimateBS"
                      formName={projectSpecific}
                      fetched={fetched}
                      changeField="revisedDateEstimateAD"
                    />
                    <DatePicker
                      lang={lang}
                      label="revised_date_estimate_ad"
                      name="revisedDateEstimateAD"
                      formName={projectSpecific}
                      fetched={fetched}
                      changeField="revisedDateEstimateBS"
                    />
                  </Form.Group>
                </>
              )}
            </div>
          </>
        )}

        {projectInfo?.project_status === 'ongoing' && (
          <>
            <div className="form-group-container">
              <Form.Group>
                <NepaliDatePicker
                  lang={lang}
                  label="project_start_date_BS"
                  name="projectStartDateBS"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="projectStartDateAD"
                />
                <DatePicker
                  lang={lang}
                  label="project_start_date_AD"
                  name="projectStartDateAD"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="projectStartDateBS"
                />
              </Form.Group>

              <Form.Group>
                <NepaliDatePicker
                  lang={lang}
                  label="actual_project_start_date_BS"
                  name="actualProjectStartDateBS"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="actualProjectStartDateAD"
                />
                <DatePicker
                  lang={lang}
                  label="actual_project_start_date_AD"
                  name="actualProjectStartDateAD"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="actualProjectStartDateBS"
                />
              </Form.Group>

              <Form.Group>
                <NepaliDatePicker
                  lang={lang}
                  label="project_completion_date_BS"
                  name="projectCompletionDateBS"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="projectCompletionDateAD"
                />
                <DatePicker
                  lang={lang}
                  label="project_completion_date_AD"
                  name="projectCompletionDateAD"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="projectCompletionDateBS"
                />
              </Form.Group>
            </div>

            <div className="form-group-container">
              <Form.Group>
                <Form.Field
                  label={lang['is_deadline_extended']}
                  required={false}
                  className={projectError && projectError[`isDeadlineExtendedError`] ? 'error' : ''}
                />
                <Form.Field
                  control={Radio}
                  label={lang.yes}
                  name={'is_deadline_extended'}
                  value="Yes"
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: projectSpecific,
                          value,
                          name: 'is_deadline_extended',
                        },
                        fetched
                      )
                    );
                  }}
                  checked={projectSpecific['is_deadline_extended'] === 'Yes'}
                />

                <Form.Field
                  control={Radio}
                  label={lang.no}
                  name={'is_deadline_extended'}
                  value="no"
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: projectSpecific,
                          value,
                          name: 'is_deadline_extended',
                        },
                        fetched
                      )
                    );
                  }}
                  checked={projectSpecific['is_deadline_extended'] === 'no'}
                />
              </Form.Group>
              {projectSpecific['is_deadline_extended'] === 'Yes' ? (
                <div>
                  <p className="form-info">{lang.extended_deadline_date}</p>
                  <Form.Group>
                    <NepaliDatePicker
                      lang={lang}
                      label="from_date_BS"
                      name="fromDateBS"
                      formName={projectSpecific}
                      fetched={fetched}
                      changeField="fromDateAD"
                      field="extendedDeadline"
                      formSection={projectSpecific}
                    />
                    <DatePicker
                      lang={lang}
                      label="from_date_AD"
                      name="fromDateAD"
                      formName={projectSpecific}
                      fetched={fetched}
                      changeField="fromDateBS"
                      field="extendedDeadline"
                    />
                  </Form.Group>
                  <Form.Group>
                    <NepaliDatePicker
                      lang={lang}
                      label="to_date_BS"
                      name="toDateBS"
                      formName={projectSpecific}
                      fetched={fetched}
                      changeField="toDateAD"
                      field="extendedDeadline"
                      formSection={projectSpecific}
                    />
                    <DatePicker
                      lang={lang}
                      label="to_date_AD"
                      name="toDateAD"
                      formName={projectSpecific}
                      fetched={fetched}
                      changeField="toDateBS"
                      field="extendedDeadline"
                    />
                  </Form.Group>
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        )}

        <div className="form-group-container">
          <Form.Group grouped>
            <Form.Field label={lang.breakdown} />
            <Form.Group inline>
              <Form.Field
                label={lang.yes}
                control={Radio}
                name="isBreakOfCost"
                value="Yes"
                checked={projectSpecific.isBreakOfCost === 'Yes'}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        info: projectSpecific,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
              <Form.Field
                label={lang.no}
                control={Radio}
                name="isBreakOfCost"
                value="No"
                checked={projectSpecific.isBreakOfCost === 'No'}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        info: projectSpecific,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
            </Form.Group>
          </Form.Group>
          {projectSpecific.isBreakOfCost === 'Yes' && (
            <>
              <Form.Group grouped>
                <Form.Field label={lang.summary_of_cost_under_major_headings} />
                {projectSpecific.summaryOfCost.map((value, index) => {
                  return (
                    <div
                      className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                      key={index}
                    >
                      <Form.Group>
                        <Form.Field
                          control={Input}
                          label={lang.cost_heading_number}
                          width={4}
                          className={
                            projectError[`costHeadingNumber_${index}_error`]
                              ? 'error'
                              : 'field-align-bottom'
                          }
                          name="costHeadingNumber"
                          onChange={(event) => {
                            dispatch(
                              handleRepeaterChange(
                                {
                                  event,
                                  info: projectSpecific,
                                  field: 'summaryOfCost',
                                  index: index,
                                },
                                fetched
                              )
                            );
                            setProjectError({
                              ...projectError,
                              [`costHeadingNumber_${index}_error`]: false,
                            });
                          }}
                          value={value.costHeadingNumber || ''}
                        />
                        <Form.Field
                          control={Input}
                          label={lang.cost_headings}
                          placeholder={lang.cost_headings}
                          width={4}
                          className="field-align-bottom"
                          name="costHeading"
                          onChange={(event) => {
                            dispatch(
                              handleRepeaterChange(
                                {
                                  event,
                                  info: projectSpecific,
                                  field: 'summaryOfCost',
                                  index: index,
                                },
                                fetched
                              )
                            );
                          }}
                          value={value.costHeading || ''}
                        />
                      </Form.Group>
                      <Form.Group>
                        <CurrencyField
                          costLabel={lang.cost_usd}
                          costName="fCostComponent"
                          exchangeRateLabel="Exchange rate"
                          exchangeRateName="fCostComponentExchangeRate"
                          costInWordsLabel={lang.cost_usd_word}
                          costInWordsName="fCostComponentWord"
                          formInfo={projectSpecific}
                          dispatch={dispatch}
                          fetched={fetched}
                          field="summaryOfCost"
                          index={index}
                          error={projectError.summaryOfCost_error}
                        />
                      </Form.Group>
                      {index > 0 && (
                        <div
                          className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                          onClick={(event) => {
                            dispatch(
                              removeRepeaterField(
                                {
                                  event,
                                  info: projectSpecific,
                                  field: 'summaryOfCost',
                                  index: index,
                                },
                                fetched
                              )
                            );
                          }}
                        >
                          <i className="material-icons md-12 mr-2 material-delete">
                            delete_outline
                          </i>
                          Delete
                        </div>
                      )}
                    </div>
                  );
                })}
              </Form.Group>

              <Form.Group className="field-repeat-btn">
                <Form.Field>
                  <button
                    className="btn-text"
                    onClick={(event) => {
                      dispatch(
                        addRepeaterField(
                          {
                            event,
                            info: projectSpecific,
                            field: 'summaryOfCost',
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    + {lang.add_more}
                  </button>
                </Form.Field>
              </Form.Group>
            </>
          )}
        </div>
        <div className="form-group-container mb-7">
          <FileUpload
            formName="specific"
            label="upload_documents_summary_of_cost"
            fileData={projectSpecific.summaryCostFile}
            name="summaryCostFile"
            form={projectSpecific}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </div>
      </>

      <div>
        <h3 className="">{lang.project_feasibility_study}</h3>
        <div className="form-group-container">
          <ConditionalRadioButton
            lang={lang}
            label="pre_feasibility_study"
            uploadLabel="upload_pre_feasibility_study"
            uploadName="preFeasibilityFile"
            name="preFeasibility"
            projectId={urlParams.projectId}
            showNoField={false}
            notRequiredLabel={lang.no}
          />
        </div>
        <div className="form-group-container">
          <ConditionalRadioButton
            lang={lang}
            label="feasibility_study"
            uploadLabel="upload_project_feasibility_study"
            uploadName="feasibilityStudyFile"
            name="feasibilityStudy"
            projectId={urlParams.projectId}
            required={false}
            error={projectError}
            showNoField={false}
            notRequiredLabel={lang.no}
          />
        </div>
        <h3 className="required-field">{lang.study}</h3>
        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="detail_engineering_report"
            fetched={fetched}
            name="detailEngineeringReportFile"
            fileData={projectSpecific['detailEngineeringReportFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`detailEngineeringReportFile_error`] : false}
          />
        </div>

        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="detail_project_report"
            fetched={fetched}
            name="detailProjectReportFile"
            fileData={projectSpecific['detailProjectReportFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`detailProjectReportFile_error`] : false}
          />
          {projectSpecific['detailProjectReportFile'].some((item) => item?.fileName !== '') ? (
            <>
              <Form.Field
                control={Input}
                label={lang.designed_by}
                className={
                  projectError.detailProjectReportStudyBy_error
                    ? 'error field-align-bottom'
                    : 'field-align-bottom'
                }
                width={4}
                name="detailProjectReportStudyBy"
                value={projectSpecific.detailProjectReportStudyBy}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        info: projectSpecific,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, detailProjectReportStudyBy_error: false });
                }}
              />
            </>
          ) : (
            ''
          )}
        </div>

        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="approved_report_of_project_proposal"
            fetched={fetched}
            name="approvedReportFile"
            fileData={projectSpecific['approvedReportFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`approvedReportFile_error`] : false}
          />
          {projectSpecific['approvedReportFile'].some((item) => item?.fileName !== '') ? (
            <>
              <Form.Field
                control={Input}
                label={lang.designed_by}
                className={
                  projectError.approvedReportStudyBy_error
                    ? 'error field-align-bottom'
                    : 'field-align-bottom'
                }
                width={4}
                name="approvedReportStudyBy"
                value={projectSpecific.approvedReportStudyBy}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        info: projectSpecific,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, approvedReportStudyBy_error: false });
                }}
              />
            </>
          ) : (
            ''
          )}
        </div>

        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="not_necessary"
            fetched={fetched}
            name="studyNotNecessaryFile"
            fileData={projectSpecific['studyNotNecessaryFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`studyNotNecessaryFile_error`] : false}
          />
        </div>

        <h3 className="required-field">{lang.environmental_study_report}</h3>
        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="brief_environmental_study_report"
            fetched={fetched}
            name="briefEnvironmentalFile"
            fileData={projectSpecific['briefEnvironmentalFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`briefEnvironmentalFile_error`] : false}
          />
          {projectSpecific['briefEnvironmentalFile'].some((item) => item?.fileName !== '') ? (
            <>
              <p></p>
              <Form.Group>
                <DatePickerComponent
                  lang={lang}
                  label="approved_date_AD"
                  name="briefEnvironmentalApprovedDateAD"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="briefEnvironmentalApprovedDateBS"
                />
                <NepaliDatePicker
                  lang={lang}
                  label="approved_date_BS"
                  name="briefEnvironmentalApprovedDateBS"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="briefEnvironmentalApprovedDateAD"
                  formSection={projectSpecific}
                />
              </Form.Group>
            </>
          ) : (
            ''
          )}
        </div>

        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="preliminary_environmental_test_report"
            fetched={fetched}
            name="preliminaryEnvironmentalFile"
            fileData={projectSpecific['preliminaryEnvironmentalFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`preliminaryEnvironmentalFile_error`] : false}
          />
          {projectSpecific['preliminaryEnvironmentalFile'].some((item) => item?.fileName !== '') ? (
            <>
              <p></p>
              <Form.Group>
                <DatePickerComponent
                  lang={lang}
                  label="approved_date_AD"
                  name="preliminaryEnvironmentalApprovedDateAD"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="preliminaryEnvironmentalApprovedDateBS"
                />
                <NepaliDatePicker
                  lang={lang}
                  label="approved_date_BS"
                  name="preliminaryEnvironmentalApprovedDateBS"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="preliminaryEnvironmentalApprovedDateAD"
                  formSection={projectSpecific}
                />
              </Form.Group>
            </>
          ) : (
            ''
          )}
        </div>

        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="environmental_impact_assessment"
            fetched={fetched}
            name="environmentalImpactAssessmentFile"
            fileData={projectSpecific['environmentalImpactAssessmentFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`environmentalImpactAssessmentFile_error`] : false}
          />
          {projectSpecific['environmentalImpactAssessmentFile'].some(
            (item) => item?.fileName !== ''
          ) ? (
            <>
              <p></p>
              <Form.Group>
                <DatePickerComponent
                  lang={lang}
                  label="approved_date_AD"
                  name="environmentalImpactAssessmentApprovedDateAD"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="environmentalImpactAssessmentApprovedDateBS"
                />
                <NepaliDatePicker
                  lang={lang}
                  label="approved_date_BS"
                  name="environmentalImpactAssessmentApprovedDateBS"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="environmentalImpactAssessmentApprovedDateAD"
                  formSection={projectSpecific}
                />
              </Form.Group>
            </>
          ) : (
            ''
          )}
        </div>

        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="not_necessary"
            fetched={fetched}
            name="environmentStudyNotNecessaryFile"
            fileData={projectSpecific['environmentStudyNotNecessaryFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`environmentStudyNotNecessaryFile_error`] : false}
          />
        </div>

        <h3>{lang.purchase_plan_prepared}</h3>
        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="procurement_master_plan"
            fetched={fetched}
            name="procurementMasterPlanFile"
            fileData={projectSpecific['procurementMasterPlanFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`procurementMasterPlanFileError`] : false}
          />
        </div>

        <h3>{lang.annual_procurement}</h3>
        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="annual_procurement"
            fetched={fetched}
            name="annualProcurementPlanFile"
            fileData={projectSpecific['annualProcurementPlanFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`annualProcurementPlanFile_error`] : false}
          />
        </div>

        <h3>{lang.implementation_action_plan_prepared}</h3>
        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="action_plan"
            fetched={fetched}
            required={true}
            name="actionPlanFile"
            fileData={projectSpecific['actionPlanFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`actionPlanFile_error`] : false}
          />
        </div>

        <h3>{lang.result_blueprint_prepared}</h3>
        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="result_framework"
            fetched={fetched}
            required={true}
            name="resultFrameworkFile"
            fileData={projectSpecific['resultFrameworkFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`resultFrameworkFile_error`] : false}
          />
        </div>

        <h3>{lang.modality_of_project_implementation_has_been_prepared}</h3>
        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="directory"
            fetched={fetched}
            name="directoryFile"
            fileData={projectSpecific['directoryFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`directoryFileError`] : false}
          />
        </div>

        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="operation_guidelines"
            fetched={fetched}
            name="operationGuidelinesFile"
            fileData={projectSpecific['operationGuidelinesFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`operationGuidelinesFileError`] : false}
          />
        </div>

        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="manual"
            fetched={fetched}
            name="manualFile"
            fileData={projectSpecific['manualFile']}
            form={projectSpecific}
            projectId={urlParams.projectId}
            error={projectError ? projectError[`manualFileError`] : false}
          />
        </div>
        {/* ENd File */}
      </div>
      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 1 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default ProjectSpecific;
