/* eslint-disable react/prefer-stateless-function */
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { setCurrentUser } from '../services/Auth';
import store from '../store';

import PrivateRoute from '../components/Router/PrivateRoute';
import Header from '../layouts/Header';
import Login from '../pages/auth/Login';
import DashBoard from '../pages/Dashboard';
import DepartmentList from '../pages/Department';
import Department from '../pages/Department/Department';
import Faq from '../pages/Faq';
import FeedbackForm from '../pages/Feedback';
import Gate from '../pages/Gate';
import AssessmentGateList from "../pages/Gate/AssessmentGateProjectList/index";
import BasicAddProject from '../pages/Gate/BasicInfo';
import ClosedProjectList from '../pages/Gate/ClosedProject';
import Config from '../pages/Gate/Configuration';
import EmailList from '../pages/Gate/Configuration/EmailList';
import GateDashBoard from '../pages/Gate/Dashboard';
import GateExample from '../pages/Gate/Example';
import GateViewPage from '../pages/Gate/GateViewPages/GateViewPage';
import GateMinistryList from '../pages/Gate/Ministry';
import GateMinistry from '../pages/Gate/Ministry/Ministry';
import ParentProject from '../pages/Gate/ParentProject';
import AggregateProvince from "../pages/Gate/PBMIS/AggregateProvince";
import PBMIS from "../pages/Gate/PBMIS/index";
import QuarterlyAssessment from "../pages/Gate/QuarterlyAssessment";
import NewAppraisalRanking from "../pages/Gate/Ranking/NewAppraisalRanking";
import NewIdentificationRanking from "../pages/Gate/Ranking/NewIdentificationRanking";
import OngoingGateRanking from "../pages/Gate/Ranking/OngoingGateRanking";
import GateSector from '../pages/Gate/Sector';
import GateTrash from '../pages/Gate/Trash';
import UploadGuideline from '../pages/Gate/UploadGuideline';
import HomePage from '../pages/HomePage';
import NewProjectRanking from '../pages/NewProjectRanking';
import PageNotFound from '../pages/PageNotFound';
import ProgramList from '../pages/Program';
import Map from '../pages/ProjectMap';
import UserList from '../pages/User';
import Profile from '../pages/User/Profile';
import User from '../pages/User/User';
import UserSetting from '../pages/User/UserSetting';


import Bises from '../pages/Gate/Bises';
import SamaPurak from '../pages/Gate/SamaPurak';
import SamapurakProjects from '../pages/SamapurakProjects';
import BisesProjects from '../pages/BisesProjects';

window.language = localStorage.getItem('language') || 'ne';

const cookieToken = Cookies.get('npbmisToken');
if (localStorage.npbmisToken && cookieToken) {
  const parsedToken = JSON.parse(localStorage.npbmisToken);
  const { token } = parsedToken;
  const { timestamp } = parsedToken;
  const today = new Date();

  const previousDate = new Date(timestamp);
  previousDate.setDate(previousDate.getDate() + 7);

  const fullDateToday = today.getTime();
  const fullPreviousDate = previousDate.getTime();

  if (fullDateToday <= fullPreviousDate) {
    const decoded = jwtDecode(token);
    store.dispatch(setCurrentUser(decoded));
  } else {
    localStorage.removeItem('npbmisToken');
    Cookies.remove('npbmisToken');
  }
}

class Routes extends Component {
  render() {
    return (
      <>
        <div>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-left"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar={false}
            closeOnToastrClick
          />
        </div>
        <BrowserRouter>
          <Header />
          <Switch>
            {this.props.auth.isAuthenticated ? (
              <Route exact path="/" component={DashBoard}>
                {' '}
                <Redirect from="/" to="/dashboard" />
              </Route>
            ) : (
                <Route exact path="/" component={HomePage} />
              )}
            <Route exact path="/login" component={Login} />
            <Route exact path="/samapurak-projects" component={SamapurakProjects} />
            <Route exact path="/bises-projects" component={BisesProjects} />

            {/* <PrivateRoute exact path="/dashboard" component={DashBoard} />
            <PrivateRoute exact path="/add/project" component={Project} /> */}

            {/* removing project list page */}
            {/* <PrivateRoute exact path="/projects" component={ProjectList} /> */}

            {/* <PrivateRoute exact path="/project" component={AddProject} /> */}
            {/* <PrivateRoute exact path="/project/:projectId" component={ViewProject} /> */}
            <PrivateRoute exact path="/faq" component={Faq} />
            <PrivateRoute exact path="/programs" component={ProgramList} />
            <PrivateRoute exact path="/users" component={UserList} />
            <PrivateRoute exact path="/add/users" component={User} />
            <PrivateRoute exact path="/departments" component={DepartmentList} />
            <PrivateRoute exact path="/add/departments" component={Department} />
            {/* <PrivateRoute exact path="/ministries" component={MinistryList} /> */}
            {/* <PrivateRoute exact path="/add/ministry" component={Ministry} /> */}
            <PrivateRoute exact path="/feedback" component={FeedbackForm} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/setting" component={UserSetting} />
            <PrivateRoute exact path="/new-project-ranking" component={NewProjectRanking} />
            {/*<PrivateRoute exact path="/ongoing-project-ranking" component={OngoingProjectRanking} />*/}
            <PrivateRoute exact path="/projects/map" component={Map} />
            {/* <PrivateRoute exact path="/sectors" component={SectorList} /> */}
            {/* <PrivateRoute exact path="/trash" component={Trash} /> */}

            {/*
             *   gate routes
             */}

            {/* <PrivateRoute exact path="/gate" component={BasicAddProject} /> */}
            <PrivateRoute exact path="/project" component={BasicAddProject} />
            {/* <PrivateRoute exact path="/gate/add" component={Gate} /> */}
            <PrivateRoute exact path="/add/project" component={Gate} />
            <PrivateRoute exact path="/gate/example" component={GateExample} />
            {/* <PrivateRoute exact path="/gate/dashboard" component={GateDashBoard} /> */}
            <PrivateRoute exact path="/dashboard" component={GateDashBoard} />
            {/* <PrivateRoute exact path="/gate/project/:projectId" component={GateViewPage} /> */}
            <PrivateRoute exact path="/project/:projectId" component={GateViewPage} />
            {/* <PrivateRoute exact path="/gate/trash" component={GateTrash} /> */}
            <PrivateRoute exact path="/trash" component={GateTrash} />
            {/* <PrivateRoute exact path="/gate/ministries" component={GateMinistryList} /> */}
            <PrivateRoute exact path="/ministries" component={GateMinistryList} />
            {/* <PrivateRoute exact path="/gate/add/ministry" component={GateMinistry} /> */}
            <PrivateRoute exact path="/add/ministry" component={GateMinistry} />
            {/* <PrivateRoute exact path="/gate/sectors" component={GateSector} /> */}
            <PrivateRoute exact path="/sectors" component={GateSector} />
            <PrivateRoute exact path="/gate/parent" component={ParentProject} />
            <PrivateRoute
              exact
              path="/new-identification-ranking"
              component={NewIdentificationRanking}
            />
            <PrivateRoute exact path="/new-appraisal-ranking" component={NewAppraisalRanking} />
            <PrivateRoute exact path="/ongoing-project-ranking" component={OngoingGateRanking} />
            <PrivateRoute exact path="/assessment/:projectId" component={QuarterlyAssessment} />
            <PrivateRoute exact path="/config" component={Config} />
            <PrivateRoute exact path="/emails" component={EmailList} />
            <PrivateRoute exact path="/closed-projects" component={ClosedProjectList} />
            <PrivateRoute exact path="/upload-guidelines" component={UploadGuideline} />
            <PrivateRoute exact path="/stats/province/:provinceNo" component={PBMIS} />
            <PrivateRoute exact path="/stats/province" component={AggregateProvince} />
            <PrivateRoute exact path="/evaluated" component={AssessmentGateList} key="evaluated" />
            <PrivateRoute exact path="/approved" component={AssessmentGateList} key="approved" />
            <PrivateRoute exact path="/bises-visualization" component={Bises} />
            {/* <PrivateRoute exact path="/test" component={SamaPurak} /> */}
            <PrivateRoute exact path="/samapurak-visualization" component={SamaPurak} />
            {/* <PrivateRoute exact path="/samapurak-visualization" component={SamaPurak} /> */}

            <PrivateRoute component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(Routes);
