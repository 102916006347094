/**
 *
 * @param {stat: {count: number}} projectStatus
 * @param {number} totalProject
 */

const mapProjectStatusChartInfo = (projectStatus, totalProject) => {
  /**
   *
   * @type {{label: string, percentage: number, count: number}[]}
   */
  const chartInfo = [];
  projectStatus.forEach((stat) => {
    let label = '';
    const percentage = parseFloat((stat.count / totalProject) * 100).toFixed(2);
    if (stat.name === 'Save as Draft') {
      label = 'draft';
    }
    if (stat.name === 'Sent to Verification') {
      label = 'verification';
    }
    if (stat.name === 'Submit') {
      label = 'submitted';
    }
    if (stat.name === 'Sent to Submission') {
      label = 'approved';
    }
    chartInfo.push({
      label,
      percentage,
      count: stat.count,
    });
  });
  return chartInfo;
};

export default mapProjectStatusChartInfo;
