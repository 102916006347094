import React, { useState, useEffect } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { numberToWords } from '../../helpers/numberToWords';
import { handleInputChange, handleRepeaterChange } from '../../actions';
import { toastr } from 'react-redux-toastr';
import formatNumber from '../../helpers/formatNumber';
import { preventArrowKeys } from '../../helpers';

const NumberToWordsInput = ({
  identifier,
  index,
  amountLabel,
  amountInWordsLabel,
  amountPlaceholder,
  amountInWordsPlaceholder,
  amountFieldName,
  amountInWordsFieldName,
  field,
  amountValue,
  amountInWordsValue,
  error,
  setError,
  data,
  fetched,
  required = false,
}) => {
  const dispatch = useDispatch();
  const [maxLimit] = useState(1000000000000);
  const [amountInWords, setAmountInWords] = useState(amountInWordsValue ?? '');

  useEffect(() => {
    if (amountValue) {
      const numericValue = parseFloat(amountValue);
      const words = numberToWords(numericValue);
      setAmountInWords(words);
    } else {
      setAmountInWords('');
    }
  }, [amountValue]);

  const handleChange = (event) => {
    const { value, name } = event.target;
    const numericValue = parseFloat(value) || 0;
    const words = numberToWords(numericValue);

    if (parseInt(value, 10) > maxLimit) {
      toastr.error('Error', `Value cannot exceed ${formatNumber(maxLimit)}`);
    } else {
      let updatedData = { ...data };

      if (identifier === 'financialArrangement') {
        const fieldParts = field.split('.');

        const updateNestedValue = (obj, path, value) => {
          if (path.length === 0) return obj;

          const [current, ...rest] = path;

          if (!isNaN(rest[0])) {
            const arrayKey = current;
            const arrayIndex = parseInt(rest[0]);
            const remainingPath = rest.slice(1);

            return {
              ...obj,
              [arrayKey]: Array.isArray(obj[arrayKey])
                ? [
                    ...obj[arrayKey].slice(0, arrayIndex),
                    remainingPath.length === 0
                      ? {
                          ...obj[arrayKey][arrayIndex],
                          [name]: value,
                          [amountInWordsFieldName]: words,
                        }
                      : updateNestedValue(obj[arrayKey][arrayIndex] || {}, remainingPath, value),
                    ...obj[arrayKey].slice(arrayIndex + 1),
                  ]
                : [],
            };
          }
          // If current key points to an object
          return {
            ...obj,
            [current]: updateNestedValue(obj[current] || {}, rest, value),
          };
        };

        updatedData = updateNestedValue(updatedData, fieldParts, value);

        dispatch(
          handleRepeaterChange(
            {
              event,
              info: updatedData,
              field: fieldParts[0],
              subField: fieldParts.length > 2 ? fieldParts[1] : '', // like [loans,internalLoan,0] max length is 3 for financingArrangement so we are taking 1 as subField
              index: parseInt(fieldParts[fieldParts.length - 1]),
            },
            fetched
          )
        );
      } else if (field && typeof field === 'string') {
        const keys = field.split('.');
        keys.reduce((acc, key, index) => {
          if (index === keys.length - 1) {
            acc[key] = { ...acc[key], [name]: value, [amountInWordsFieldName]: words };
          } else {
            acc[key] = acc[key] || {};
          }
          return acc[key];
        }, updatedData);
        dispatch(handleInputChange({ event, info: updatedData, field }, fetched));
      } else {
        updatedData[name] = value;
        updatedData[amountInWordsFieldName] = words;
        dispatch(handleInputChange({ event, info: updatedData, field }, fetched));
      }
    }
  };
  return (
    <>
      <Form.Field
        type="number"
        onKeyDown={preventArrowKeys}
        required={required}
        control={Input}
        label={amountLabel}
        placeholder={amountPlaceholder}
        width={8}
        className={error ? 'error' : 'field-align-bottom'}
        name={amountFieldName}
        value={amountValue ?? ''}
        onChange={handleChange}
      />
      <Form.Field
        type="text"
        required={required}
        control={Input}
        label={amountInWordsLabel}
        placeholder={amountInWordsPlaceholder}
        width={8}
        className={error ? 'error' : 'field-align-bottom'}
        name={amountInWordsFieldName}
        value={amountInWords}
        disabled
      />
    </>
  );
};

export default NumberToWordsInput;
