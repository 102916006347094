export const validateNonRequired = (value, errorKey, errors) => {
  if (value !== undefined && value !== '' && isNaN(value)) {
    errors[errorKey] = true;
    return false;
  }
  return true;
};

export const validateRequired = (value, errorKey, errors) => {
  if (!value || isNaN(value)) {
    errors[errorKey] = true;
    return false;
  }
  return true;
};

export const validateNameAmountPairs = (data, errors) => {
  let isValid = true;

  // Helper function to validate a single item
  const validateItem = (item, errorPrefix, index) => {
    const hasName = item.name && item.name.trim().length > 0;
    const hasAmount = item.budgetAmount && item.budgetAmount.toString().trim().length > 0;

    if ((hasName && !hasAmount) || (!hasName && hasAmount)) {
      errors[`${errorPrefix}_${index}_name_error`] = !hasName;
      errors[`${errorPrefix}_${index}_budgetAmount_error`] = !hasAmount;
      isValid = false;
    }
  };

  // Helper function to validate array of items
  const validateArray = (items, errorPrefix) => {
    if (!items) return;
    items.forEach((item, index) => validateItem(item, errorPrefix, index));
  };

  if (data.loans) {
    validateArray(data.loans.internalLoan, 'internalLoan');
    validateArray(data.loans.externalLoan, 'externalLoan');
  }

  validateArray(data.foreignGrants, 'foreignGrants');
  validateArray(data.others, 'others');

  if (data.publicPrivatePartnership) {
    validateArray(data.publicPrivatePartnership.publicSource, 'publicSource');
    validateArray(data.publicPrivatePartnership.privateSource, 'privateSource');
  }

  return isValid;
};
