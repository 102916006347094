import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Dropdown, Form, Input, Radio, Select } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import ReactSelect from 'react-select';
import { cloneDeep } from 'lodash';

import { auth, preventArrowKeys } from '../../../../helpers';
import { numberToWords } from '../../../../helpers/numberToWords';
import { localization } from '../../../../localization';
import AddModal from '../../../../components/AddModal';
import ProjectAddModal from '../../../../components/ProjectAddModal';
import DatePicker from '../DatePicker';
import NepaliDatePicker from '../DatePicker/NepaliDatePicker';
import CurrencyField from '../CurrencyField';
import FileUpload from '../FileUpload';
import {
  handleInputChange,
  handleRadioChange,
  handleEditorChange,
  handleCheckboxChange,
  handleSaveAndExit,
  handleSelectChange,
  addRepeaterField,
  handleRepeaterChange,
  removeRepeaterField,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/GeneralInformation/action';
import {
  fetched as f,
  fetchedNullData as fn,
} from '../../../../reducers/form/ProjectReadiness/action';

import DepartmentService from '../../../../services/Department';
import ProjectApi from '../../../../services/Gate/Project';

import Editor from '../CkEditor/index';

import MinistryApi from '../../../../services/Ministry';
import ProgramApi from '../../../../services/Program';
import DepartmentApi from '../../../../services/Department';
import Countries from '../../../../data/Countries.json';

const GeneralForm = ({ urlParams }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const language = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[language]['general_information'];
  const langReadiness = localization[language]['readiness'];
  const project_onboarding = localization[language]['project_boarding_page'];
  const [departmentList, setDepartmentList] = useState({
    all: [],
    filtered: [],
  });
  let departmentFilter;
  const [sectors, setSectors] = useState([]);
  const [locations, setLocations] = useState({
    provinces: null,
    districts: null,
  });

  const [ministriesList, setMinistriesList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [programsList, fetchMinistryProgram] = useState([]);

  const [department, setDepartment] = useState({
    label: 'Select a Department',
    value: '',
  });

  const [program, setProgram] = useState({
    label: 'Select a Program',
    value: '',
    // budgetCode:''
  });
  const [projectError, setProjectError] = useState({
    project_name_in_english_error: false,
    project_name_in_nepali_error: false,
    project_budget_code_error: false,
    department_id_error: false,
    projectType_error: false,
    projectTypeOther_error: false,
    projectPriority_error: false,
    projectPriorityOther_error: false,
    provinces_error: false,
    districts_error: false,
    municipalities_error: false,
    categorization_error: false,
    sectors_error: false,
    subSector_error: false,
    projectPeriod_error: false,
    country_error: false,
    address_error: false,
    location_type_error: false,
  });

  const dispatch = useDispatch();
  const user = useSelector(auth.getCurrentUser);
  const { generalInformation } = useSelector((state) => state);
  // newly added state
  const { projectReadiness } = useSelector((state) => state);

  const { projectInfo, programList } = useSelector((state) => state.projectBasicInfo);
  const [ministry, setMinistry] = useState({
    selected: { label: 'Select a Ministry', value: '' },
    ministryID: '',
  });

  const [projectModalOpen, setProjectModalOpen] = useState(false);

  const [projectSelected, setSelectedProject] = useState({
    selected: { label: 'Select a Project', value: '' },
    projectBudgetCode: '',
    projectId: null,
  });

  const [project, setProject] = useState({
    project_status: '',
    project_stage: '',
    part_of_program: '',
    is_sub_project: '',
    parent_id: null,
    program_id: null,
    project_name_in_english: '',
    project_name_in_nepali: '',
    project_budget_code: '',
  });

  const listDepartments = (params) => {
    DepartmentService.listDepartmentByMinistry(params)
      .then((response) => {
        let departments = [{ label: 'Select a Department', value: 0 }];
        for (const d of response.data.department) {
          departments.push({ label: d.name, value: d.id, ministryId: d.ministry_id });
        }

        if (['dataEntry', 'dataApprover'].includes(user.role) && user.department) {
          const findDep = departments.find((d) => d.value === user.department.id);
          setDepartment(findDep);
        }
        setDepartmentList({
          all: cloneDeep(departments),
          filtered: cloneDeep(departments).filter((d) => d.ministryId === projectInfo.ministry_id),
        });
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const listMinistries = () => {
    MinistryApi.listAllMinistries(`?isDashboard=true&`)
      .then((response) => {
        if (response.success) {
          const ministries = [];
          for (const d of response.data?.ministries?.lists) {
            ministries.push({ label: d.name, value: d.id });
          }
          setMinistriesList(ministries);
        } else {
          toastr.error('', `${response.error.message}`);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const listProjects = (params) => {
    ProjectApi.listParentProjects(params)
      .then((response) => {
        let selectedProject = response.filter((project) => {
          return project.value === projectInfo.parent_id;
        });
        if (selectedProject.length > 0) {
          setSelectedProject({
            selected: selectedProject[0],
            projectId: selectedProject[0].value,
            projectBudgetCode: selectedProject[0].project_budget_code,
          });
        } else {
          setSelectedProject({
            selected: { label: 'Select a Project', value: '' },
            projectBudgetCode: '',
            projectId: null,
          });
        }
        setProjectList(response);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const fetchProgram = (params) => {
    ProgramApi.listPrograms(params)
      .then((response) => {
        let selectedProgram = response.filter((program) => {
          return program.value === projectInfo.program_id;
        });
        if (selectedProgram.length > 0) {
          setProgram({
            label: selectedProgram[0].label,
            value: selectedProgram[0].value,
            budgetCode: selectedProgram[0].program_budget_code,
          });
        } else {
          setProgram({
            label: 'Select a Program',
            value: '',
            budgetCode: '',
          });
        }
        fetchMinistryProgram(response);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const fetchSectors = () => {
    ProjectApi.getAllsectors()
      .then((response) => {
        if (response.success) {
          setSectors(response.data.sectorList);
        }
      })
      .catch((err) => toastr.error('', err.message));
  };

  const fetchDepartment = (departmentId) => {
    DepartmentApi.getDepartment({ departmentId })
      .then((response) => {
        if (response.success) {
          setDepartment({
            label: response.data.department.name,
            value: response.data.department.id,
          });
        }
      })
      .catch((err) => toastr.error('', err.message));
  };

  const fetchLocation = () => {
    ProjectApi.getAllLocation()
      .then((response) => {
        if (response.success) {
          let districts = [];
          for (let d of response.data.districts) {
            districts.push({
              key: d.id,
              text: d.name,
              value: d.id,
              parent_id: d.parent_id,
            });
          }
          setLocations({ provinces: response.data.provinces, districts });
        }
      })
      .catch((err) => toastr.error('', err.message));
  };

  useEffect(() => {
    const ministryID = user.ministry.id;
    if (user && user.ministry && user.ministry.id) {
      // const ministryID = user.ministry.id;
      if (projectInfo && programList) {
        // listDepartments({ ministryId: ministryID });
        fetchSectors();
        fetchLocation();
        fetchProgram({ ministryId: projectInfo.ministry.id });
        // fetching program according to ministry issue no 515
        // const program = programList.find((p) => p.value === projectInfo.program_id);
        // setProgram(program);
        departmentFilter = cloneDeep(departmentList.all).filter((d) => {
          return d.ministryId === projectInfo.ministry_id;
        });
        setDepartmentList({ ...departmentList, filtered: departmentFilter });
        if (projectInfo.department_id) {
          fetchDepartment(projectInfo.department_id);
        }

        const projectOnBoardingInfo = {
          part_of_program: projectInfo.part_of_program,
          program_id: projectInfo.program_id,
          project_name_in_english: projectInfo.project_name_in_english,
          project_name_in_nepali: projectInfo.project_name_in_nepali,
          project_budget_code: projectInfo.project_budget_code,
          department_id: projectInfo.department_id,
          gate: projectInfo.gate,
          project_status: projectInfo.project_status,
          project_stage: urlParams.stage,
          ministry:
            projectInfo.ministry && projectInfo.ministry.id
              ? { value: projectInfo.ministry.id, label: projectInfo.ministry.name }
              : '',
          is_sub_project: projectInfo.parent_id ? 'Yes' : 'No',
          ministry_id:
            projectInfo.ministry && projectInfo.ministry.id ? projectInfo.ministry.id : '',
          parent_id: projectInfo.parent_id ? projectInfo.parent_id : null,
        };

        if (projectInfo.general_information) {
          dispatch(
            fetched({
              ...projectInfo.general_information,
              projectOnBoarding: projectOnBoardingInfo,
            })
          );
        }
        if (projectInfo.project_readiness) {
          dispatch(f({ ...projectInfo.project_readiness }));
        }
        if (!projectInfo.project_readiness) {
          dispatch(fn({ projectOnBoarding: projectOnBoardingInfo }));
        }
        // else {
        //   dispatch(
        //     fetched({
        //       ...generalInformation,
        //       projectOnBoarding: projectOnBoardingInfo,
        //     })
        //   );
        // }
        if (!projectInfo.general_information) {
          dispatch(fetchedNullData({ projectOnBoarding: projectOnBoardingInfo }));
        }
      }
    }
    if (projectInfo) {
      if (projectInfo.ministry) {
        setMinistry({
          selected: { label: projectInfo.ministry.name, value: projectInfo.ministry.id },
          ministryID: projectInfo.ministry.id,
        });
        listProjects({ ministryId: projectInfo.ministry.id });
      }
    }
  }, [projectInfo, programList]);

  useEffect(() => {
    const ministryID = user.ministry.id;
    if (user.role === 'npcAdmin') {
      listMinistries();
    } else if (user && user.ministry && user.ministry.id) {
      setMinistry({ selected: { label: user.ministry.name, value: ministryID }, ministryID });
    }

    // fetchProgram({ ministryId: ministryID });
    listDepartments({ ministryId: ministryID });
    // listProjects({ ministryId: ministryID });
  }, [user]);

  const categories = [
    { label: 'Below 3 years', value: 'belowThree' },
    { label: '3-5 years', value: 'threeToFive' },
    { label: '5-7 years', value: 'fiveToSeven' },
    { label: '7-10 years', value: 'sevenToTen' },
    { label: 'more than 10 years', value: 'moreThanTen' },
  ];

  const priorities = [
    { key: '', text: 'Select Priority of Project', value: '' },
    {
      key: 'nationalPrideProject',
      text: lang.option_national_pride_project,
      value: 'nationalPrideProject',
    },
    { key: 'priority1', text: lang.option_national_priority_one, value: 'priority1' },
    { key: 'priority2', text: lang.option_national_priority_two, value: 'priority2' },
    { key: 'gameChanger', text: lang.option_national_game_changer, value: 'gameChanger' },
    { key: 'others', text: lang.option_other, value: 'others' },
  ];

  const projectType = [
    { key: '', text: 'Select a Project type', value: '' },
    // {
    //   key: 'serviceOriented',
    //   text: lang.option_service_oriented,
    //   value: 'serviceOriented',
    // },
    // { key: 'construction', text: lang.option_construction, value: 'construction' },
    // { key: 'production', text: lang.option_production, value: 'production' },
    // { key: 'trade', text: lang.option_trade, value: 'trade' },
    // { key: 'others', text: lang.option_other, value: 'others' },
    { key: 'Infrastructure', text: lang.infrastructure, value: 'Infrastructure' },
    { key: 'Non-infrastructure', text: lang.non_infrastructure, value: 'Non-infrastructure' },
  ];

  const closeModal = () => {
    setModalOpen(!modalOpen);
  };

  const projectCloseModal = () => {
    setProjectModalOpen(!projectModalOpen);
  };

  const removeChildLocation = (data, callFromProvince = false) => {
    if (generalInformation.districts.length) {
      let districts = cloneDeep(locations.districts);
      let districtList = cloneDeep(districts).filter((d) => !data.includes(d.parent_id));

      if (callFromProvince) {
        const children = [];
        for (let d of cloneDeep(districts)) {
          if (data.includes(d.parent_id)) {
            children.push(d.value);
          }
        }

        districtList = districtList.filter((d) => !children.includes(d.parent_id));
      }
      for (let s of districtList) {
        let index = generalInformation.districts.indexOf(s.value);
        let mindex = generalInformation.municipalities.indexOf(s.value);
        if (index !== -1) {
          generalInformation.districts.splice(index, 1);
        }
        if (mindex !== -1) {
          generalInformation.municipalities.splice(mindex, 1);
        }
      }
    }
  };

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(generalInformation);
    if (data.projectCostImplementation?.originalCost) {
      data.projectCostImplementation.originalCostWord = numberToWords(data.projectCostImplementation.originalCost);
    }
    delete data['step'];
    const requiredFields = ['projectType', 'sectors', 'projectPeriod', 'location_type'];

    if (data.location_type === 'National') {
      requiredFields.push('provinces', 'districts');
    } else if (data.location_type === 'International') {
      requiredFields.push('country', 'address');
    }

    if (projectInfo.project_status === 'ongoing') {
      requiredFields.push('projectPriority');
    }

    const requiredFieldsOnboarding = ['project_name_in_english', 'part_of_program', 'ministry_id'];

    const errors = [];
    let canSubmit = true;

    for (let d in data) {
      if (d === 'projectOnBoarding') {
        for (let p in data[d]) {
          if (requiredFieldsOnboarding.includes(p)) {

            if (data[d][p] === '' || data[d][p] === null) {
              canSubmit = false;
              errors[`${p}_error`] = true;
            }
          }
        }
      } else {
     
        if (requiredFields.includes(d) && (data[d] === '' || data[d].length === 0)) {
          canSubmit = false;
          errors[`${d}_error`] = true;
        }
      }
    }

    if (data.subSector && data.subSector.length > 0) {
      data.subSector.filter((s) => {
        return s !== undefined;
      });
    }
    if(projectInfo.project_status === 'study'){
      delete errors.part_of_program_error;
      delete errors.project_original_cost_estimate_error;
      delete errors.is_sub_project_error;
      canSubmit = true;
    }
    setProjectError(errors);
    if (canSubmit) {
      const projectOnBoarding = data.projectOnBoarding;
      delete data['projectOnBoarding'];
      // delete othersText unused data
      let othersText = {
        ...data.othersText,
      };
      Object.keys(othersText)
        .filter((item) => data.subSector.indexOf(item) === -1)
        .forEach((item) => delete othersText[item]);
        handleSaveAndExit(
        {
          projectOnBoarding:{...projectOnBoarding,part_of_program:'No'},
          general_information: { ...data, location_type: data.location_type, othersText },
        },
        type,
        dispatch,
        2,
        urlParams
      );
    }
  };

  const isSectorChecked = (sectorValue) =>
    generalInformation.sectors?.includes(sectorValue.toString());

  const isSubSectorChecked = (subValue) =>
    generalInformation.subSector?.includes(subValue.toString());

  const handleSectorCheckboxChange = (sector, event) => {
    dispatch(handleCheckboxChange({ event, info: generalInformation, field: 'sectors' }, fetched));

    if (generalInformation.subSector.length && sector.subSector.length) {
      sector.subSector.forEach((s) => {
        const idx = generalInformation.subSector.indexOf(s.value.toString());
        if (idx !== -1) {
          generalInformation.subSector.splice(idx, 1);
        }
      });
    }

    setProjectError({ ...projectError, sectors_error: false });
  };

  const handleSubSectorCheckboxChange = (sector, sub, event) => {
    if (event.target.checked) {
      sector.subSector.push({
        name: sub.value,
        value: sub.value,
        isOtherInputField: true,
      });
    } else {
      sector.subSector = sector.subSector.filter(
        (item) => !(item?.isOtherInputField && item.name === sub.value)
      );
    }
    dispatch(
      handleCheckboxChange({ event, info: generalInformation, field: 'subSector' }, fetched)
    );
  };

  const projectPeriods = [
    { value: '', label: 'Select a Project period' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '10+', label: '10+' },
  ];

  const isAllDistrictSelected = () =>
    generalInformation.districts.some((val) => {
      const selected = (locations?.districts ?? []).find((d) => d.value === val);
      return selected && selected.text === 'All Districts';
    });

  const isDistrictSelected = () =>
    generalInformation.districts.some((val) => {
      const selected = (locations?.districts ?? []).find((d) => d.value === val);
      return selected && selected.text !== 'All Districts';
    });

  const isAllMunicipalitySelected = () =>
    generalInformation.municipalities.some((val) => {
      const selected = (locations?.districts ?? []).find((m) => m.value === val);
      return selected && selected.text === 'All Municipalities';
    });

  const isMunicipalitySelected = () =>
    generalInformation.municipalities.some((val) => {
      const selected = (locations?.districts ?? []).find((m) => m.value === val);
      return selected && selected.text !== 'All Municipalities';
    });

  const municipalitiesData = (locations?.districts ?? []).filter((item) => {
    if (locations?.districts?.map((item) => item.key).includes(item.parent_id)) {
      return true;
    }
    return false;
  });

  return (
    <Form className="form-new">
      {/* Line Ministry … Implementing Agency */}
      <div className="form-group-container">
        <p className="form-info">{lang.form_description}</p>

        <Form.Group>
          <Form.Field width={8} required>
            <Form.Field
              label={lang.line_ministry}
              required
              className={projectError.ministry_id_error ? 'error' : ''}
            />
            <ReactSelect
              isDisabled={['dataEntry', 'dataApprover', 'ministryAdmin'].includes(user.role)}
              name="ministry_id"
              value={ministry.selected}
              options={ministriesList}
              onChange={(selectedOption) => {
                setMinistry({ selected: selectedOption, ministryID: selectedOption.value });
                departmentFilter = cloneDeep(departmentList.all).filter(
                  (d) => d.ministryId === selectedOption.value
                );
                dispatch(
                  handleSelectChange(
                    {
                      field: 'projectOnBoarding',
                      info: generalInformation,
                      value: selectedOption.value,
                      name: 'ministry_id',
                    },
                    fetched
                  )
                );
                listProjects({ ministryId: selectedOption.value });
                fetchProgram({ ministryId: selectedOption.value });
                setDepartmentList({ ...departmentList, filtered: departmentFilter });
                setDepartment([{ label: 'Select a Department', value: 0 }]);
                // setProjectError({ ...projectError, ministry_id_error: false });
              }}
              isSearchable
              className={`react-select ${projectError.ministry_id_error ? 'error' : ''}
              ${
                ['dataEntry', 'dataApprover', 'ministryAdmin'].includes(user.role) ? 'disabled' : ''
              }
                        `}
            />
          </Form.Field>
          <Form.Field width={8}>
            <Form.Field label={lang.authority} />
            <Form.Field disabled={['dataEntry', 'dataApprover'].includes(user.role)}>
              <ReactSelect
                name="department"
                value={department}
                options={departmentList.filtered}
                isDisabled={['dataEntry', 'dataApprover'].includes(user.role)}
                onChange={(selectedOption) => {
                  setDepartment(selectedOption);
                  dispatch(
                    handleSelectChange(
                      {
                        field: 'projectOnBoarding',
                        info: generalInformation,
                        value: selectedOption.value,
                        name: 'department_id',
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, department_id_error: false });
                }}
                isSearchable
                className={`react-select ${projectError.department_id_error ? 'error' : ''}`}
              />
            </Form.Field>
          </Form.Field>
        </Form.Group>
      </div>

      {/* Project Name En .. project Name Ne …. Project budget code */}
      <div className="form-group-container">
        <Form.Group>
          <Form.Field
            required
            control={Input}
            label={
              generalInformation.projectOnBoarding.gate
                ? project_onboarding.sub_project_name_in_english
                : project_onboarding.project_name_in_english
            }
            className={
              projectError.project_name_in_english_error
                ? 'error field-align-bottom'
                : 'field-align-bottom'
            }
            width={4}
            name="project_name_in_english"
            value={generalInformation.projectOnBoarding.project_name_in_english}
            onChange={(event) => {
              dispatch(
                handleInputChange(
                  {
                    event,
                    field: 'projectOnBoarding',
                    info: generalInformation,
                  },
                  fetched
                )
              );
              setProjectError({ ...projectError, project_name_in_english_error: false });
            }}
          />
          <Form.Field
            control={Input}
            label={
              generalInformation.projectOnBoarding.gate
                ? project_onboarding.sub_project_name_in_nepali
                : project_onboarding.project_name_in_nepali
            }
            width={4}
            name="project_name_in_nepali"
            value={generalInformation.projectOnBoarding.project_name_in_nepali}
            className={
              projectError.project_name_in_nepali_error
                ? 'error field-align-bottom'
                : 'field-align-bottom'
            }
            onChange={(event) => {
              dispatch(
                handleInputChange(
                  {
                    event,
                    field: 'projectOnBoarding',
                    info: generalInformation,
                  },
                  fetched
                )
              );
              setProjectError({ ...projectError, project_name_in_nepali_error: false });
            }}
          />
          {generalInformation.projectOnBoarding.project_status === 'ongoing' && (
            <Form.Field
              control={Input}
              label={
                generalInformation.projectOnBoarding.gate
                  ? project_onboarding.sub_project_budget_code
                  : project_onboarding.budget_code
              }
              placeholder=""
              width={4}
              name="project_budget_code"
              value={generalInformation.projectOnBoarding.project_budget_code}
              type="number"
              onKeyDown={preventArrowKeys}
              className={
                projectError.project_budget_code_error
                  ? 'error field-align-bottom'
                  : 'field-align-bottom'
              }
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      field: 'projectOnBoarding',
                      info: generalInformation,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, project_budget_code_error: false });
              }}
            />
          )}
        </Form.Group>
      </div>

      {/* Location */}
      <div className="form-group-container">
        <p className="form-info">{lang.project_location_description}</p>

        <Form.Field
          required
          control={Select}
          label={lang.select_a_region}
          className={`mb-7 ${projectError.location_type_error ? 'error' : ''}`}
          options={[
            {
              key: '',
              value: '',
              text: lang.select_a_region,
            },
            { key: 'national', value: 'National', text: lang.national },
            { key: 'international', value: 'International', text: lang.international },
          ]}
          width={8}
          name="location_type"
          placeholder={lang.select_a_region}
          value={generalInformation.location_type}
          onChange={(event, { value, name }) => {
            let updatedInfo = { ...generalInformation, [name]: value };

            if (value === 'National') {
              updatedInfo = { ...updatedInfo, country: '', address: '' };
            } else if (value === 'International') {
              updatedInfo = { ...updatedInfo, provinces: [], districts: [], municipalities: [] };
            }

            dispatch(handleSelectChange({ value, name, info: updatedInfo }, fetched));
            setProjectError({ ...projectError, location_type_error: false });
          }}
          search
        />

        {/* National Location: Province > District > Municipality */}
        {generalInformation.location_type === 'National' && (
          <>
            <Form.Group>
              <Form.Field label={lang.province} required />
              <Form.Group inline>
                {locations.provinces?.map((obj, index) => {
                  const allProvinceIds = locations.provinces.map((province) =>
                    province.id.toString()
                  );
                  return (
                    <Form.Field
                      key={index}
                      label={language === 'en' ? obj.name : obj.nepali_name}
                      className={projectError.provinces_error ? 'error' : ''}
                      control={Checkbox}
                      width={4}
                      name={obj.id}
                      id={obj.name}
                      onChange={(event) => {
                        dispatch(
                          handleCheckboxChange(
                            { event, info: generalInformation, field: 'provinces' },
                            fetched
                          )
                        );
                        let checkItem = generalInformation.provinces.filter(
                          (item) => item === event.target.name
                        );

                        if (checkItem.length > 0) {
                          let unCheckedIndex = generalInformation.provinces.indexOf(checkItem[0]);
                          let data = [];
                          generalInformation.provinces.map((item, index) => {
                            if (index !== unCheckedIndex) {
                              data.push(item);
                            }
                          });
                          data = data.map(Number);

                          removeChildLocation(data, true);
                        }

                        let updatedProvinces = [...generalInformation.provinces];
                        const selectedId = event.target.name;

                        if (obj.name === 'All Provinces') {
                          if (updatedProvinces.includes(selectedId)) {
                            // If "All Provinces" is already selected, unselect everything
                            updatedProvinces = [];
                            // Call removeChildLocation when unselecting "All Provinces"
                            removeChildLocation([], true);
                          } else {
                            // Select all provinces
                            updatedProvinces = allProvinceIds;
                          }
                        } else {
                          if (updatedProvinces.includes(selectedId)) {
                            // If the province is already selected, remove it
                            updatedProvinces = updatedProvinces.filter((id) => id !== selectedId);

                            // If any province is unselected, "All Provinces" should also be unselected
                            updatedProvinces = updatedProvinces.filter(
                              (id) => id !== allProvinceIds[7]
                            );
                          } else {
                            // Select province
                            updatedProvinces.push(selectedId);

                            // If all provinces are selected, mark "All Provinces" as selected
                            if (updatedProvinces.length === allProvinceIds.length - 1) {
                              updatedProvinces = [...allProvinceIds];
                            }
                          }
                        }

                        dispatch(
                          fetched({
                            ...generalInformation,
                            provinces: updatedProvinces,
                          })
                        );

                        setProjectError({ ...projectError, provinces_error: false });
                      }}
                      checked={
                        generalInformation.provinces?.length > 0 &&
                        generalInformation.provinces?.filter((s) => s === obj.id.toString())
                          .length > 0
                      }
                    />
                  );
                })}
              </Form.Group>
            </Form.Group>

            {/* <Form.Group>
              <Form.Field width={8}>
                <Form.Field required label={lang.province} />
                <Dropdown
                  multiple
                  search
                  selection
                  placeholder="Select Provinces"
                  options={locations.provinces?.map((obj) => ({
                    key: obj.id,
                    value: obj.id.toString(),
                    text: language === 'en' ? obj.name : obj.nepali_name,
                  }))}
                  name="provinces"
                  value={generalInformation.provinces}
                  onChange={(event, { name, value }) => {
                    let updatedProvinces = [...value];
                    const allProvinceIds = locations.provinces.map((province) =>
                      province.id.toString()
                    );
                    const allProvincesId = allProvinceIds[7];

                    if (value.includes(allProvincesId)) {
                      if (generalInformation.provinces.includes(allProvincesId)) {
                        // If "All Provinces" is unselected, keep only selected provinces
                        updatedProvinces = value.filter((id) => id !== allProvincesId);
                      } else {
                        // If "All Provinces" is selected, select all provinces
                        updatedProvinces = allProvinceIds;
                      }
                    } else {
                      // If a province is deselected, "All Provinces" should also be removed
                      updatedProvinces = updatedProvinces.filter((id) => id !== allProvincesId);
                    }

                    let recentlyRemovedItem = generalInformation.provinces.filter(
                      (item) => !updatedProvinces.includes(item)
                    );

                    if (recentlyRemovedItem.length > 0) {
                      let unCheckedIndex = generalInformation.provinces.indexOf(
                        recentlyRemovedItem[0]
                      );
                      let data = generalInformation.provinces.filter(
                        (item, index) => index !== unCheckedIndex
                      );
                      data = data.map(Number);

                      removeChildLocation(data, true);
                    }

                    dispatch(
                      handleSelectChange(
                        {
                          value: updatedProvinces,
                          name,
                          info: generalInformation,
                        },
                        fetched
                      )
                    );

                    setProjectError({ ...projectError, provinces_error: false });
                  }}
                  className={projectError.provinces_error ? 'error' : ''}
                />
              </Form.Field>
            </Form.Group> */}

            <Form.Group>
              <Form.Field width={8}>
                <Form.Field
                  required
                  label={lang.districts}
                  className={projectError.districts_error ? 'error' : ''}
                />
                <Dropdown
                  className={projectError.districts_error ? 'error' : ''}
                  multiple
                  search
                  selection
                  placeholder="Select Districts"
                  options={[
                    ...(generalInformation.provinces?.length > 0
                      ? [
                          {
                            key: 'select-all-districts',
                            value: 'select-all',
                            text: language === 'en' ? 'Select All' : 'सबै छान्नुहोस्',
                            disabled: isAllDistrictSelected(),
                          },
                        ]
                      : []),
                    ...(locations?.districts ?? [])
                      .filter((d) => generalInformation.provinces?.includes(d.parent_id.toString()))
                      .map((d) => ({
                        ...d,
                        disabled:
                          d.text === 'All Districts'
                            ? // Disable "All Districts" if any other district is selected
                              isDistrictSelected()
                            : // Disable other districts if "All Districts" is selected
                              isAllDistrictSelected(),
                      })),
                  ]}
                  name="districts"
                  value={generalInformation.districts}
                  onChange={(event, { name, value }) => {
                    let selectedDistricts = value;

                    if (value.includes('select-all')) {
                      selectedDistricts = locations.districts
                        .filter(
                          (d) =>
                            generalInformation.provinces?.includes(d.parent_id.toString()) &&
                            d.text !== 'All Districts'
                        )
                        .map((d) => d.value);
                    }

                    // Find districts that have been removed
                    const removedDistricts =
                      generalInformation.districts?.filter(
                        (district) => !selectedDistricts.includes(district)
                      ) || [];

                    if (removedDistricts.length > 0) {
                      let removedIndex = generalInformation.districts.indexOf(removedDistricts[0]);
                      let updatedDistricts = [];

                      generalInformation.districts.forEach((item, index) => {
                        if (index !== removedIndex) {
                          updatedDistricts.push(item);
                        }
                      });

                      updatedDistricts = updatedDistricts.map(Number);

                      removeChildLocation(updatedDistricts, true);
                    }

                    // Ensure locations.municipalities exists before filtering
                    const updatedMunicipalities =
                      generalInformation.municipalities?.filter(
                        (municipality) =>
                          !(
                            locations?.municipalities?.length > 0 &&
                            locations.municipalities.some(
                              (m) =>
                                removedDistricts.includes(m.parent_id) && m.value === municipality
                            )
                          )
                      ) || [];

                    // Dispatch updated values to Redux
                    dispatch(
                      handleSelectChange(
                        {
                          value: selectedDistricts,
                          name,
                          info: { ...generalInformation, municipalities: updatedMunicipalities },
                        },
                        fetched
                      )
                    );

                    setProjectError({ ...projectError, districts_error: false });
                  }}
                />
              </Form.Field>

              <Form.Field width={8}>
                <Form.Field label={lang.municipality} />
                <Dropdown
                  multiple
                  search
                  selection
                  options={[
                    ...(generalInformation.districts?.length > 0
                      ? [
                          {
                            key: 'select-all-municipalities',
                            value: 'select-all',
                            text: language === 'en' ? 'Select All' : 'सबै छान्नुहोस्',
                            disabled: isAllMunicipalitySelected(),
                          },
                        ]
                      : []),

                    ...(isAllDistrictSelected()
                      ? municipalitiesData.map((m) => ({
                          ...m,
                          disabled:
                            m.text === 'All Municipalities'
                              ? // Disable "All Municipalities" if any other municipalities is selected
                                isMunicipalitySelected()
                              : // Disable other municipalities if "All Municipalities" is selected
                                isAllMunicipalitySelected(),
                        }))
                      : (locations?.districts ?? []).filter((m) => {
                          return generalInformation.districts?.includes?.(m?.parent_id);
                        })),
                  ]}
                  name="municipalities"
                  value={generalInformation.municipalities}
                  placeholder="Select Municipalities"
                  onChange={(event, { value, name }) => {
                    let selectedMunicipalities = value;

                    if (value.includes('select-all')) {
                      selectedMunicipalities = municipalitiesData
                        .filter((m) => m.text !== 'All Municipalities')
                        .map((m) => m.value);
                    }

                    dispatch(
                      handleSelectChange(
                        {
                          value: selectedMunicipalities,
                          name,
                          info: generalInformation,
                        },
                        fetched
                      )
                    );
                  }}
                />
              </Form.Field>
            </Form.Group>
          </>
        )}

        {/* International Location: Select Country and Address */}
        {generalInformation.location_type === 'International' && (
          <>
            <Form.Group className="flex">
              <Form.Field
                control={Select}
                label={'Select a country'}
                className={projectError.country_error ? 'error' : ''}
                options={[
                  { key: 'select-country', value: 'select-country', text: 'Select a country' },
                  ...Countries.map((country) => ({
                    key: country.code,
                    value: country.name,
                    text: country.name,
                  })),
                ]}
                width={8}
                name="country"
                placeholder="Select a country"
                value={generalInformation.country}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleSelectChange(
                      {
                        value,
                        name,
                        info: generalInformation,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, country_error: false });
                }}
                search
              />

              <Form.Field
                control={Input}
                label={'Add detailed address of project location'}
                placeholder="Enter the detailed address"
                className={projectError.address_error ? 'error' : ''}
                width={8}
                name="address"
                value={generalInformation.address ?? ''}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleSelectChange(
                      {
                        value,
                        name,
                        info: generalInformation,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, address_error: false });
                }}
              />
            </Form.Group>
          </>
        )}
      </div>

      {/* Project Period */}
      <div className="form-group-container">
        <Form.Group>
          <Form.Field
            required
            control={Select}
            label={lang.project_period}
            className={projectError.projectPeriod_error ? 'error' : ''}
            options={projectPeriods.map((item) => {
              return { key: item.value, text: item.label, value: item.value };
            })}
            width={8}
            name="projectPeriod"
            placeholder="Select a Project period"
            value={generalInformation.projectPeriod ?? ''}
            onChange={(event, { value, name }) => {
              dispatch(
                handleSelectChange(
                  {
                    value,
                    name,
                    info: generalInformation,
                  },
                  fetched
                )
              );
              setProjectError({ ...projectError, projectPeriod_error: false });
            }}
            search
          />
        </Form.Group>
      </div>

      {/* Priority_of Project */}

     {
      projectInfo.project_status !== 'study' && (
        <div className="form-group-container">
        <Form.Group>
          <Form.Field
            required
            control={Select}
            label={lang.priority_of_project}
            className={projectError.projectPriority_error ? 'error' : ''}
            options={priorities}
            width={8}
            name="projectPriority"
            placeholder="Select Priority of Project"
            value={generalInformation.projectPriority}
            onChange={(event, { value, name }) => {
              dispatch(
                handleSelectChange(
                  {
                    value,
                    name,
                    info: generalInformation,
                  },
                  fetched
                )
              );
              setProjectError({ ...projectError, projectPriority_error: false });
            }}
          />
          {generalInformation.projectPriority === 'others' && (
            <Form.Field width={8}>
              <label>{lang.if_priority_of_project_other}</label>
              <Editor
                formData={generalInformation.projectPriorityOther}
                formInfo={generalInformation}
                field={false}
                name="projectPriorityOther"
                handleEditorChange={handleEditorChange}
                fetched={fetched}
              />
            </Form.Field>
          )}
        </Form.Group>
      </div>
      )
     }

      {/* Project Type */}
      <div className="form-group-container">
        <Form.Group>
          <Form.Field
            required
            control={Select}
            label={lang.project_type}
            className={projectError.projectType_error ? 'error' : ''}
            options={projectType}
            width={8}
            name="projectType"
            placeholder="Select a Project type"
            value={generalInformation.projectType}
            onChange={(event, { value, name }) => {
              dispatch(
                handleSelectChange(
                  {
                    value,
                    name,
                    info: generalInformation,
                  },
                  fetched
                )
              );
              setProjectError({ ...projectError, projectType_error: false });
            }}
            search
          />
          {generalInformation.projectType === 'others' && (
            <Form.Field width={8}>
              <label>{lang.mention_other_type}</label>
              <Editor
                formData={generalInformation.projectTypeOther}
                formInfo={generalInformation}
                field={false}
                name="projectTypeOther"
                handleEditorChange={handleEditorChange}
                fetched={fetched}
              />
            </Form.Field>
          )}
        </Form.Group>

        {urlParams.stage === 'identification' && projectInfo.project_status === 'new' && (
          <Form.Group grouped>
            <Form.Field label={lang.categorization} />
            <Form.Group inline>
              {categories.map((c, index) => {
                return (
                  <Form.Field
                    key={index}
                    label={c.label}
                    className={projectError.categorization_error ? 'error' : ''}
                    control={Radio}
                    value={c.value}
                    name="categorization"
                    checked={generalInformation.categorization === c.value}
                    onChange={(event, { value, name }) => {
                      dispatch(
                        handleRadioChange(
                          {
                            info: generalInformation,
                            value,
                            name,
                          },
                          fetched
                        )
                      );
                      setProjectError({ ...projectError, categorization_error: false });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Form.Group>
        )}
      </div>

      {/* Sector */}
      <div className="form-group-container">
        <Form.Group grouped>
          <Form.Field required label={lang.sector} />
          <Form.Group grouped>
            {sectors.map((sector) => (
              <div key={sector.value}>
                <Form.Field
                  label={language === 'en' ? sector.label : sector.nepaliName}
                  className={projectError.sectors_error ? 'error' : ''}
                  control={Checkbox}
                  id={sector.label}
                  value={sector.value}
                  name={sector.value}
                  onChange={(event) => handleSectorCheckboxChange(sector, event)}
                  checked={isSectorChecked(sector.value)}
                />
                {isSectorChecked(sector.value) && sector.subSector.length > 0 && (
                  <Form.Group inline className="sub-sector-container">
                    {sector.subSector.map((sub) => {
                      if (sector.id === sub.parent_id && !sub.isOtherInputField) {
                        return (
                          <React.Fragment key={sub.value}>
                            <Form.Field
                              label={language === 'en' ? sub.label : sub.nepaliName}
                              control={Checkbox}
                              value={sub.value}
                              id={`${sub.value}-${sub.label}`}
                              name={sub.value}
                              onChange={(event) => {
                                handleSubSectorCheckboxChange(sector, sub, event);
                              }}
                              checked={isSubSectorChecked(sub.value)}
                            />
                            {sub.label === 'Others' && isSubSectorChecked(sub.value) && (
                              <Form.TextArea
                                rows={5}
                                className="others-text-field"
                                label="Please specify if others"
                                name={`othersText_${sub.value}`}
                                placeholder="Please specify"
                                value={generalInformation['othersText'][sub.value] || ''}
                                onChange={(e) => {
                                  dispatch(
                                    fetched({
                                      ...generalInformation,
                                      othersText: {
                                        ...generalInformation.othersText,
                                        [sub.value]: e.target.value,
                                      },
                                    })
                                  );
                                }}
                              />
                            )}
                          </React.Fragment>
                        );
                      }
                    })}
                  </Form.Group>
                )}
              </div>
            ))}
          </Form.Group>
        </Form.Group>

        <AddModal isModelOpen={modalOpen} onCloseModel={closeModal} />
      </div>

      {/* Project Status */}
      <div className="form-group-container">
        <Form.Group>
          <Form.Field width={8} required>
            <Form.Field
              required
              className={projectError.project_status_error ? 'error' : ''}
              label={project_onboarding.what_is_project_status}
            />
            <Form.Group inline>
              <Form.Field
                label={project_onboarding.new_future_project}
                control={Radio}
                name="project_status"
                value="new"
                checked={generalInformation.projectOnBoarding.project_status === 'new'}
                disabled
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        field: 'projectOnBoarding',
                        info: generalInformation,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
              <Form.Field
                label={project_onboarding.under_implementation}
                control={Radio}
                name="project_status"
                value="ongoing"
                checked={generalInformation.projectOnBoarding.project_status === 'ongoing'}
                disabled
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        field: 'projectOnBoarding',
                        info: generalInformation,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
              <Form.Field
                label={project_onboarding.under_study}
                control={Radio}
                name="project_status"
                value="study"
                checked={generalInformation.projectOnBoarding.project_status === 'study'}
                disabled
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        field: 'projectOnBoarding',
                        info: generalInformation,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
            </Form.Group>
          </Form.Field>
          {generalInformation.projectOnBoarding.project_status === 'new' ? (
            <Form.Field width={8}>
              <Form.Field
                required
                className={projectError.project_stage_error ? 'error' : ''}
                label={project_onboarding.what_is_the_project_stage}
              />
              <Form.Group inline>
                {/* <Form.Field
                  label={project_onboarding.identification}
                  control={Radio}
                  name="stage"
                  value="identification"
                  checked={generalInformation.projectOnBoarding.project_stage === 'identification'}
                  disabled
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          field: 'projectOnBoarding',
                          info: generalInformation,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                  }}
                /> */}
                <Form.Field
                  label={project_onboarding.appraisal}
                  control={Radio}
                  name="stage"
                  value="appraisal"
                  checked={generalInformation.projectOnBoarding.project_stage === 'appraisal'}
                  disabled
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          field: 'projectOnBoarding',
                          info: generalInformation,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                  }}
                />
              </Form.Group>
            </Form.Field>
          ) : (
            <></>
          )}
        </Form.Group>
      </div>

     {
      projectInfo.project_status !== 'study' && (
        <>
         {/* part of the program */}
      <div className="form-group-container">
        <Form.Group grouped>
          <Form.Field required label={project_onboarding.is_part_of_program} />

          <Form.Group inline>
            <Form.Field
              label={project_onboarding.yes}
              control={Radio}
              value="Yes"
              name="part_of_program"
              checked={generalInformation.projectOnBoarding.part_of_program === 'Yes'}
              disabled={['dataEntry', 'dataApprover', 'ministryAdmin'].includes(user.role)}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      field: 'projectOnBoarding',
                      info: generalInformation,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
            <Form.Field
              label={project_onboarding.no}
              control={Radio}
              value="No"
              name="part_of_program"
              checked={generalInformation.projectOnBoarding.part_of_program === 'No'}
              disabled={['dataEntry', 'dataApprover', 'ministryAdmin'].includes(user.role)}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      field: 'projectOnBoarding',
                      info: generalInformation,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>
        </Form.Group>

        {generalInformation.projectOnBoarding.part_of_program === 'Yes' && (
          <>
            <Form.Group>
              <Form.Field width={8}>
                <Form.Field label={project_onboarding.program_name_selection} required />
                <Form.Field>
                  <ReactSelect
                    name="program_id"
                    value={program}
                    options={programsList}
                    onChange={(selectedOption) => {
                      setProgram({
                        value: selectedOption.value,
                        label: selectedOption.label,
                        budgetCode: selectedOption.program_budget_code,
                      });
                      dispatch(
                        handleSelectChange(
                          {
                            field: 'projectOnBoarding',
                            info: generalInformation,
                            value: selectedOption.value,
                            name: 'program_id',
                          },
                          fetched
                        )
                      );
                    }}
                    isSearchable
                    className="react-select"
                  />
                </Form.Field>
              </Form.Field>
              <Form.Field
                // className="field-align-bottom"
                control={Input}
                label={project_onboarding.program_budget_code}
                disabled
                width={8}
                value={program?.budgetCode}
              />
            </Form.Group>
            <Form.Group className="field-helper-text">
              <Form.Field>
                <label>
                  {project_onboarding.select_program_name}
                  <button
                    type="button"
                    onClick={() => setModalOpen(!modalOpen)}
                    className="btn-text"
                  >
                    {project_onboarding.create_new_program}
                  </button>
                </label>
              </Form.Field>
            </Form.Group>
          </>
        )}
      </div>

      {/* sub-project of another project */}
      <div className="form-group-container">
        <Form.Group>
          <Form.Field width={8} required>
            <Form.Field
              required
              className={projectError.is_sub_project_error ? 'error' : ''}
              label={project_onboarding.is_sub_project}
            />
            <Form.Group inline>
              <Form.Field
                label={project_onboarding.yes}
                control={Radio}
                name="is_sub_project"
                value="Yes"
                disabled={['dataEntry', 'dataApprover', 'ministryAdmin'].includes(user.role)}
                checked={
                  generalInformation.projectOnBoarding.is_sub_project &&
                  generalInformation.projectOnBoarding.is_sub_project === 'Yes'
                }
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        field: 'projectOnBoarding',
                        info: generalInformation,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
              <Form.Field
                label={project_onboarding.no}
                control={Radio}
                name="is_sub_project"
                value="No"
                checked={
                  !generalInformation.projectOnBoarding.is_sub_project ||
                  generalInformation.projectOnBoarding.is_sub_project === 'No'
                }
                disabled={['dataEntry', 'dataApprover', 'ministryAdmin'].includes(user.role)}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        field: 'projectOnBoarding',
                        info: generalInformation,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
            </Form.Group>
          </Form.Field>
        </Form.Group>

        {generalInformation.projectOnBoarding.is_sub_project === 'Yes' && (
          <>
            <Form.Group>
              <Form.Field width={8}>
                <Form.Field label={project_onboarding.select_project_name} required />
                <Form.Field>
                  <ReactSelect
                    name="projects"
                    value={projectSelected.selected}
                    options={projectList}
                    onChange={(selectedOption) => {
                      setProject({ ...project, parent_id: selectedOption.value });
                      setSelectedProject({
                        selected: selectedOption,
                        projectId: selectedOption.value,
                        projectBudgetCode: selectedOption.project_budget_code,
                      });
                      dispatch(
                        handleSelectChange(
                          {
                            field: 'projectOnBoarding',
                            info: generalInformation,
                            value: selectedOption.value,
                            name: 'parent_id',
                          },
                          fetched
                        )
                      );
                    }}
                    isSearchable
                    className="react-select"
                  />
                </Form.Field>
              </Form.Field>
              {projectSelected.selected && projectSelected.selected.value ? (
                <Form.Field
                  control={Input}
                  label={project_onboarding.budget_code}
                  disabled
                  width={8}
                  value={projectSelected.projectBudgetCode}
                />
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="field-helper-text">
              <Form.Field>
                <label>
                  {project_onboarding.if_not_select_project_name}
                  <button
                    type="button"
                    onClick={() => setProjectModalOpen(!projectModalOpen)}
                    className="btn-text"
                  >
                    {project_onboarding.create_new_project}
                  </button>
                </label>
              </Form.Field>
            </Form.Group>
          </>
        )}
      </div>
        </>
      )
    }
    {/* Adding new input fields for projectStatus === study */}
    {
      projectInfo.project_status === 'study' && (
       <>
         <div className="form-group-container">
        <Form.Group>
          <CurrencyField
            costLabel={lang.estimated_cost_for_study}
            costName="originalCost"
            exchangeRateLabel={lang.usd_exchange_rate}
            exchangeRateName="originalCostExchangeRate"
            costInWordsLabel={lang.estimated_cost_for_study_word}
            costInWordsName="originalCostWord"
            formInfo={generalInformation}
            dispatch={dispatch}
            fetched={fetched}
            field="projectCostImplementation"
            error={projectError.projectCostImplementation_error}
            disabled={projectInfo?.moderation_status === 'completed'}
          />
        </Form.Group>
      </div>

       <div className="form-group-container mb-7">
          <FileUpload
            formName="general_information"
            label="upload_ministry_document"
            fileData={generalInformation.ministryFile}
            name="ministryFile"
            form={generalInformation}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </div>

        <div className='form-group-container'>
         <p className="form-info">{lang.study_date}</p>
         <Form.Group>
            <NepaliDatePicker
              lang={lang}
              label="study_completion_date_BS"
              name="studyCompletionDateBS"
              formName={generalInformation}
              fetched={fetched}
              changeField="studyCompletionDateAD"
            />
            <DatePicker
              lang={lang}
              label="study_completion_date_AD"
              name="studyCompletionDateAD"
              formName={generalInformation}
              fetched={fetched}
              changeField="studyCompletionDateBS"
            />
          </Form.Group>
        </div>

        <div className='form-group-container'>
             <p className="form-info">{lang.study_matter_name}</p>
                    <div>
                      {generalInformation.studyMatters.map((value, index) => {
                        return (
                          <div
                            className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                            key={`land${index}`}
                          >
                            <Form.Group>
                              <Form.Field
                                control={Input}
                                label={lang.study_matter_name}
                                width={12}
                                name="studyMatterName"
                                onChange={(event) => {
                                  dispatch(
                                    handleRepeaterChange(
                                      {
                                        event,
                                        info: generalInformation,
                                        field: 'studyMatters',
                                        index: index,
                                      },
                                      fetched
                                    )
                                  );
                                }}
                                value={value.studyMatterName || ''}
                              />
                            </Form.Group>
                            {index > 0 && (
                              <div
                                className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                                onClick={(event) => {
                                  dispatch(
                                    removeRepeaterField(
                                      {
                                        event,
                                        info: generalInformation,
                                        field: 'studyMatters',
                                        index: index,
                                      },
                                      fetched
                                    )
                                  );
                                }}
                              >
                                <i className="material-icons md-12 mr-2 material-delete">
                                  delete_outline
                                </i>
                                Delete
                              </div>
                            )}
                          </div>
                        );
                      })}
                      <Form.Group className="field-repeat-btn">
                        <Form.Field>
                          <button
                            className="btn-text"
                            onClick={(event) => {
                              dispatch(
                                addRepeaterField(
                                  {
                                    event,
                                    info: generalInformation,
                                    field: 'studyMatters',
                                  },
                                  fetched
                                )
                              );
                            }}
                          >
                            + Add more
                          </button>
                        </Form.Field>
                      </Form.Group>
                    </div>
         </div>
      </>
      )
    }

      <AddModal
        ministryId={ministry.ministryID}
        setModalOpen={setModalOpen}
        isModelOpen={modalOpen}
        onCloseModel={closeModal}
        setData={setProgram}
        fetchProgram={fetchProgram}
      />
      <ProjectAddModal
        ministryId={ministry.ministryID}
        setProjectModalOpen={setProjectModalOpen}
        isProjectModelOpen={projectModalOpen}
        onCloseModel={projectCloseModal}
        setData={setSelectedProject}
        listProjects={listProjects}
      />

      <div className="form-actions flex">
        <button
          type="submit"
          onClick={() => handleSaveAndContinue('continue')}
          className="btn-rect btn-rect--small btn-blue"
        >
          {lang.save_button}
        </button>
        <button
          type="submit"
          onClick={() => handleSaveAndContinue('exit')}
          className="btn-rect btn-rect--small btn-dark"
        >
          {' '}
          {lang.exit_button}
        </button>
      </div>
    </Form>
  );
};

export default GeneralForm;
