import currentYear from '../../../helpers/constant/CurrentYearGate';
const initialState = {
  financialProgress: '',
  financialProgressAmt: '',
  financialProgressFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  budget_allocated_to_the_project_so_far: '',
  budget_allocated_to_the_project_so_far_word: '',
  expenditure_on_the_project_so_far: '',
  expenditure_on_the_project_so_far_word: '',
  expenditureTillDate: [
    {
      fiscalYear: currentYear(),
      allocatedBudget: '',
      expenditureBudget: '',
      allocatedBudgetWord: '',
      expenditureBudgetWord: '',
      allocatedBudgetCurrency: 'npr',
      expenditureBudgetCurrency: 'npr',
      allocatedBudgetExchangeRate: '',
      expenditureBudgetExchangeRate: '',
    },
  ],
  expenditureTillDateFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  expenditureCurrentFYear: {
    fiscalYear: currentYear(),
    currentAllocatedBudget: '',
    currentExpenditureBudget: '',
    currentAllocatedBudgetWord: '',
    currentExpenditureBudgetWord: '',
    currentAllocatedBudgetCurrency: 'npr',
    currentExpenditureBudgetCurrency: 'npr',
    currentAllocatedBudgetExchangeRate: '',
    currentExpenditureBudgetExchangeRate: '',
  },
  expenditureCurrentFYearFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  expenditureComingFYear: {
    fiscalYear: currentYear(),
    comingEstimatedAnnualBudget: '',
    comingEstimatedAnnualBudgetWord: '',
    comingEstimatedAnnualBudgetCurrency: 'npr',
    comingEstimatedAnnualBudgetExchangeRate: '',
  },
  expenditureComingFYearFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  projectedBudget: [
    {
      budgetStatusFiscalYear: currentYear(),
      fiscalYear: currentYear(),
      projectedBudgetRequirement: '',
      projectedBudgetRequirementWord: '',
      projectedBudgetRequirementCurrency: 'npr',
      projectedBudgetRequirementExchangeRate: '',
    },
  ],
  projectedBudgetFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  physicalProgress: '',
  physicalProgressFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'PROGRESS_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_PROGRESS_FETCHED':
      return initialState;
      
    default:
      return state;
  }
}
